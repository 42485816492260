import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { snakeCase } from "lodash";
import StyledTableCell from "../components/StyledTableCell";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createNewAppointmentCategory,
  createNewAppointmentType,
  fetchAppointmentData,
  queryClient,
  updateAppointmentType,
  updateDataType,
} from "../utils/http";
import { useDispatch } from "react-redux";
import { uiActions } from "../store/ui-slice";
import ErrorBlock from "../components/UI/ErrorBlock";
import Loading from "../components/Loading";
import DataTypesNav from "../components/DataTypesNav";
import Header from "../components/Header";

export default function DataTypeAppointmentType() {
  const dispatch = useDispatch();

  const [newAppointment, setNewAppointment] = useState("");
  const [appointmentCategory, setAppointmentCategory] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [newAppointmentOpen, setNewAppointmentOpen] = useState(false);
  const [newCategoryOpen, setNewCategoryOpen] = useState(false);

  const [types, setTypes] = useState([]);
  const [categories, setCategories] = useState([]);

  const [editTypeOpen, setEditTypeOpen] = useState(false);
  const [editTypeName, setEditTypeName] = useState("");
  const [editTypeId, setEditTypeId] = useState("");
  const [editTypeCategoryId, setEditTypeCategoryId] = useState("");

  const [loaded, setLoaded] = useState(false);

  const {
    mutate: mutateNewAppointmentCategory,
    isPending: isPendingNewCategory,
    isError: isErrorNewCategory,
    error: errorNewAppointmentCategory,
  } = useMutation({
    mutationFn: createNewAppointmentCategory,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["appointment-types"] });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "New Appointment Category created.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  const {
    mutate: mutateNewAppointmentType,
    isPending: isPendingNewAppointmentType,
    isError: isErrorNewAppointmentType,
    error: errorNewAppointmentType,
  } = useMutation({
    mutationFn: createNewAppointmentType,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["appointment-types"] });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "New Appointment Type created.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  const {
    data,
    isPending: isPendingAppointments,
    isError: isErrorAppointments,
    errorAppointments,
  } = useQuery({
    queryKey: ["appointment-types"],
    queryFn: ({ signal, queryKey }) => fetchAppointmentData({ signal, ...queryKey }),
  });

  useEffect(() => {
    if (data) {
      setTypes(data.types.items);
      setCategories(data.categories.items);
      setLoaded(true);
    }
  }, [data]);

  const { mutate: mutateUpdateCategory } = useMutation({
    mutationFn: updateAppointmentType,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["appointment-types"] });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Appointment Category updated.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: ["appointment-types"] });
      dispatch(
        uiActions.showNotification({
          status: "error",
          message: "Error updating category type. Please try again later.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  const { mutate: mutateDataType, isPending: isPendingUpdateDataType } = useMutation({
    mutationFn: updateDataType,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["appointment-types"] });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Data Type updated.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: ["appointment-types"] });
      dispatch(
        uiActions.showNotification({
          status: "error",
          message: "Error updating Data Type. Please try again later.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  const handleCategoryChange = (selectedOption, id, display) => {
    let updatedType = {};
    updatedType.display = display;
    updatedType.categoryId = selectedOption.target.value;
    mutateUpdateCategory({ id: id, data: updatedType });
    selectedOption.defaultValue = selectedOption.target.value;
  };

  const handleNewAppointmentChange = (event) => {
    setNewAppointment(event.target.value);
  };

  const handleNewCategoryChange = (event) => {
    setNewCategory(event.target.value);
  };

  const handleNewAppointmentCategory = (event) => {
    setAppointmentCategory(event.target.value);
  };

  const handleEditTypeChange = (event) => {
    setEditTypeName(event.target.value);
  };

  const handleEditTypeChanged = (event) => {
    event.preventDefault();
    let dataType = {};
    dataType.name = snakeCase(editTypeName);
    dataType.display = editTypeName;
    dataType.categoryId = editTypeCategoryId;
    mutateDataType({ id: editTypeId, type: "appointments/types", data: dataType });
    handleEditTypeClose();
  };

  const handleNewAppointmentTypeAdd = (event) => {
    event.preventDefault();
    let newType = {};
    newType.name = snakeCase(newAppointment);
    newType.display = newAppointment;
    newType.categoryId = appointmentCategory;
    mutateNewAppointmentType(newType);
    handleNewAppointmentClose();
  };

  const handleNewCategoryAdd = (event) => {
    event.preventDefault();

    let newCat = {};
    newCat.name = snakeCase(newCategory);
    newCat.display = newCategory;
    mutateNewAppointmentCategory(newCat);
    handleNewCategoryClose();
  };

  const handleNewAppointmentClickOpen = () => {
    setNewAppointmentOpen(true);
  };

  const handleNewCategoryClickOpen = () => {
    setNewCategoryOpen(true);
  };

  const handleNewAppointmentClose = () => {
    setNewAppointmentOpen(false);
    setNewAppointment("");
    setAppointmentCategory("");
  };

  const handleNewCategoryClose = () => {
    setNewCategoryOpen(false);
    setNewCategory("");
  };

  const handleEditType = (id, display, categoryId) => {
    setEditTypeOpen(true);
    setEditTypeId(id);
    setEditTypeName(display);
    setEditTypeCategoryId(categoryId);
  };

  const handleEditTypeClose = () => {
    setEditTypeOpen(false);
    setEditTypeId("");
    setEditTypeName("");
    setEditTypeCategoryId("");
  };

  return (
    <>
      <Header
        title="Data Types"
        action={
          <Box>
            <Button
              sx={{ mr: 1, my: { xs: 1, lg: 0 } }}
              variant="outlined"
              disableElevation
              startIcon={<AddIcon />}
              onClick={handleNewAppointmentClickOpen}
            >
              Add New Appointment Type
            </Button>
            <Button
              variant="outlined"
              disableElevation
              startIcon={<AddIcon />}
              sx={{ mr: 1, my: { xs: 1, lg: 0 } }}
              onClick={handleNewCategoryClickOpen}
            >
              Add Appointment Category
            </Button>
          </Box>
        }
      />
      <Box display="flex" sx={{ flexDirection: { xs: "column", md: "row" } }}>
        <DataTypesNav />
        <Box flex={1} sx={{ overflowX: "auto" }}>
          {isErrorAppointments && (
            <ErrorBlock
              title="An error occured."
              message={
                errorAppointments.info?.message || "Failed to fetch appointment information. Please try again later."
              }
            />
          )}
          {isErrorNewCategory && (
            <ErrorBlock
              title="An error occured creating new Appointment Category"
              message={
                errorNewAppointmentCategory.info?.message ||
                "Failed to create new Appointment Category. Please check for duplicate already present."
              }
            />
          )}

          {isErrorNewAppointmentType && (
            <ErrorBlock
              title="An error occured creating new Appointment Type."
              message={
                errorNewAppointmentType.info?.message ||
                "Failed to create new Appointment Type. Please check for duplicate already present."
              }
            />
          )}

          <Dialog
            open={newAppointmentOpen}
            onClose={handleNewAppointmentClose}
            PaperProps={{
              component: "form",
              onSubmit: handleNewAppointmentTypeAdd,
            }}
            fullWidth
            maxWidth={"xs"}
          >
            <DialogTitle>
              <Typography variant="h4" component="p">
                Add Appointment Type
              </Typography>
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                required
                margin="dense"
                label="Appointment Type Name"
                type="text"
                fullWidth
                variant="outlined"
                value={newAppointment}
                onChange={handleNewAppointmentChange}
              />
              <FormControl sx={{ mt: 3 }} fullWidth>
                <InputLabel id="new-appointment-category">Appointment Category</InputLabel>
                <Select
                  labelId="new-appointment-category"
                  onChange={handleNewAppointmentCategory}
                  label="Appointment Category"
                  value={appointmentCategory}
                  required
                >
                  {categories.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.display}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 3 }}>
              <Button onClick={handleNewAppointmentClose}>Cancel</Button>
              <Button type="submit" variant="contained" disableElevation disabled={isPendingNewAppointmentType}>
                {isPendingNewAppointmentType ? "Saving..." : "Save"}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={newCategoryOpen}
            onClose={handleNewCategoryClose}
            PaperProps={{
              component: "form",
              onSubmit: handleNewCategoryAdd,
            }}
            fullWidth
            maxWidth={"xs"}
          >
            <DialogTitle>
              <Typography variant="h4" component="p">
                Add new Appointment Category
              </Typography>
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                required
                margin="dense"
                label="Category Name"
                type="text"
                fullWidth
                variant="outlined"
                value={newCategory}
                onChange={handleNewCategoryChange}
              />
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 3 }}>
              <Button onClick={handleNewCategoryClose}>Cancel</Button>
              <Button type="submit" variant="contained" disableElevation disabled={isPendingNewCategory}>
                {isPendingNewCategory ? "Saving..." : "Save"}
              </Button>
            </DialogActions>
          </Dialog>

          {isPendingAppointments ? (
            <Loading />
          ) : (
            <Paper
              sx={{
                width: "100%",
                border: "1px solid #f0f0f0",
                borderRadius: 1.5,
                boxShadow: "none",
              }}
            >
              <TableContainer>
                <Table size="small" sx={{ borderRadius: "6px 6px 0 0", overflow: "hidden" }}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Appointment Type</StyledTableCell>
                      <StyledTableCell>Appointment Category</StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loaded && types.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={3}>No Current Appointment Types</TableCell>
                      </TableRow>
                    )}
                    {loaded && types.length > 0 && (
                      <>
                        {types.map((type) => (
                          <TableRow key={type.id} hover>
                            <TableCell sx={{ minWidth: 200 }}>{type.display}</TableCell>
                            <TableCell sx={{ minWidth: 200 }}>
                              <FormControl sx={{ width: "100%" }}>
                                <Select
                                  onChange={(selectedOption) =>
                                    handleCategoryChange(selectedOption, type.id, type.display)
                                  }
                                  size="small"
                                  defaultValue={type.categoryId}
                                  value={type.categoryId}
                                  sx={{ backgroundColor: "#ffffff" }}
                                >
                                  {categories.map((category) => (
                                    <MenuItem key={category.id} value={category.id}>
                                      {category.display}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <IconButton
                                size="small"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  handleEditType(type.id, type.display, type.categoryId);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                        <Dialog
                          open={editTypeOpen}
                          onClose={handleEditTypeClose}
                          PaperProps={{
                            component: "form",
                            onSubmit: handleEditTypeChanged,
                          }}
                          fullWidth
                          maxWidth={"xs"}
                        >
                          <DialogTitle>
                            <Typography variant="h4" component="p">
                              Edit Appointment Type
                            </Typography>
                          </DialogTitle>
                          <DialogContent>
                            <TextField
                              autoFocus
                              required
                              margin="dense"
                              label="Appointment Type Name"
                              type="text"
                              fullWidth
                              variant="outlined"
                              value={editTypeName}
                              onChange={handleEditTypeChange}
                            />
                          </DialogContent>
                          <DialogActions sx={{ px: 3, pb: 3 }}>
                            <Button onClick={handleEditTypeClose}>Cancel</Button>
                            <Button
                              type="submit"
                              variant="contained"
                              disableElevation
                              disabled={isPendingUpdateDataType}
                            >
                              {isPendingUpdateDataType ? "Saving..." : "Save"}
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
        </Box>
      </Box>
    </>
  );
}
