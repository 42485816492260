import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import FrequencyToggle from "./FrequencyToggle";
import LocationFilter from "./LocationFilter";
import RevenueType from "./RevenueType";
import TypeToggle from "./TypeToggle";

const InsightsFilters = ({
  setSelectedFrequency,
  setSelectedType,
  setSelectedRevenue,
  setSelectedLocation,
  locationFilter = false,
  serviceFilter = false,
  revenueFilter = false,
  label = "",
}) => {
  const handleFrequencyChange = (newValue) => {
    setSelectedFrequency(newValue);
  };

  const handleTypeChange = (newValue) => {
    setSelectedType(newValue);
  };

  const handleRevenueChange = (newValue) => {
    setSelectedRevenue(newValue);
  };

  const handleLocationChange = (newValue) => {
    setSelectedLocation(newValue);
  };

  return (
    <>
      <Grid container spacing={2} display="flex" alignItems="center" sx={{ p: 2 }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h2" component="p">
            {label}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} display="flex" alignItems="center" justifyContent="flex-end">
          <FrequencyToggle change={handleFrequencyChange} />
          <TypeToggle change={handleTypeChange} />
        </Grid>
      </Grid>
      {(locationFilter || serviceFilter || revenueFilter) && (
        <Box
          sx={{
            backgroundColor: "primary.gray100",
            mb: 3,
            borderRadius: 3,
            padding: "8px 30px",
          }}
        >
          <Grid container spacing={2}>
            <Grid xs={12} md={6} item={true} sx={{ display: "flex", alignItems: "center" }}>
              <Typography component="p" variant="h4">
                Filters:
              </Typography>
              {locationFilter && <LocationFilter change={handleLocationChange} />}

              {serviceFilter && (
                <FormControl sx={{ m: 1, minWidth: 120, backgroundColor: "white" }} size="small">
                  <InputLabel id="service-label">Service</InputLabel>
                  <Select label="Service">
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                  </Select>
                </FormControl>
              )}

              {revenueFilter && <RevenueType change={handleRevenueChange} />}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default InsightsFilters;
