import { RouterProvider } from "react-router-dom";
import "@fontsource/inter";
import "@fontsource/inter/500.css";
import "./App.css";

import router from "./router";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./utils/http";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { coreAppClient } from "./utils/coreAppClient";
import { dataIngestionClient } from "./utils/dataIngestionClient";
import Loading from "./components/Loading";
import Login from "./pages/Login";

const UNAUTHENTICATED_ROUTES = new Set(['/email-unsubscribe']);
function App() {
  const { isLoading, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [isAccessTokenFetched, setIsAccessTokenFetched] = useState(false);
  const allowUnauthenticatedAccess = UNAUTHENTICATED_ROUTES.has(window.location.pathname);

  useEffect(() => {
    if (!isAuthenticated || isLoading) {
      return;
    }
    const getAccessToken = async () => {
      const accessToken = await getAccessTokenSilently();
      coreAppClient.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
      dataIngestionClient.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
      setIsAccessTokenFetched(true);
    };
    getAccessToken();
  }, [getAccessTokenSilently, setIsAccessTokenFetched, isAuthenticated, isLoading]);

  if (isLoading || (!isAccessTokenFetched && isAuthenticated)) {
    return <Loading />;
  }

  if (!isAuthenticated && !allowUnauthenticatedAccess) {
    return <Login />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
