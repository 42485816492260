import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { QUERY_KEY_PRACTICE } from "../../store/constants";
import { fetchPractice } from "../api/practices.api";

export default function useSystemOfRecord() {
  const params = useParams();
  const uuid = params.uuid;
  const [systemOfRecord, setSystemOfRecord] = useState("");

  const { data } = useQuery({
    queryKey: [QUERY_KEY_PRACTICE, uuid],
    queryFn: async ({ signal }) => {
      const [practice] = await Promise.all([fetchPractice({ uuid, signal })]);
      return { practice };
    },
  });

  useEffect(() => {
    if (data?.practice?.systemOfRecord) {
      setSystemOfRecord(data.practice.systemOfRecord);
    }
  }, [data, systemOfRecord, setSystemOfRecord]);
  return { systemOfRecord };
}
