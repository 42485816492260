import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import classes from "../../styles/MetricsTable.module.css";

const NoDataTable = ({ label }) => {
  return (
    <TableContainer className={classes.insightsTable}>
      <Table size="small">
        <TableHead>
          <TableRow className={classes.rowHeading}>
            <TableCell>{label}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={classes.topRow}>
            <TableCell>No available data to display</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default NoDataTable;
