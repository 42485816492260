import { React, createContext, useState } from "react";
import StyledTableCell from "./StyledTableCell";
import TableContainer from "@mui/material/TableContainer";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Typography, TableCell, TableHead, TableRow, Table, Paper, TableBody } from "@mui/material";
import PracticeRecallTypeEditDialog from "./PracticeRecallTypeEditDialog";

export const SelectedRecallTypeContext = createContext(null);

const PracticeRecallDiagnosisCodesTable = ({ recallTypes, practiceUuid }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRecallType, setSelectedRecallType] = useState(null);

  const handleDialogClose = () => {
    setSelectedRecallType(null);
    setDialogOpen(false);
  };

  const handleEdit = (recallType) => {
    setSelectedRecallType(recallType);
    setDialogOpen(true);
  };

  return (
    <>
      <SelectedRecallTypeContext.Provider value={selectedRecallType}>
        <PracticeRecallTypeEditDialog
          isOpen={dialogOpen}
          onClose={handleDialogClose}
          practiceUuid={practiceUuid}
        ></PracticeRecallTypeEditDialog>
        <TableContainer component={Paper}>
          <Table size="small" sx={{ borderRadius: "6px 6px 0 0", overflow: "hidden" }}>
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ minWidth: 150 }}>Diagnosis Type</StyledTableCell>
                <StyledTableCell sx={{ minWidth: 150 }}>Description</StyledTableCell>
                <StyledTableCell>Original ICD 10 Codes</StyledTableCell>
                <StyledTableCell>Excluded ICD 10 Codes</StyledTableCell>
                <StyledTableCell>Additional ICD 10 Codes</StyledTableCell>
                <StyledTableCell>Edit</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!recallTypes || !recallTypes.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6}>No Current Diagnosis Types</TableCell>
                </TableRow>
              ) : (
                <>
                  {recallTypes.map((type) => (
                    <TableRow key={type.id} hover>
                      <TableCell>{type.bonsaiDiagnosisCode.display}</TableCell>
                      <TableCell>{type.bonsaiDiagnosisCode.description}</TableCell>
                      <TableCell>
                        {type.bonsaiDiagnosisCode.icdCodes.map((code, i) => (
                          <Typography component="span" key={i}>
                            {code}
                            {i < type.bonsaiDiagnosisCode.icdCodes.length - 1 && <>," "</>}
                          </Typography>
                        ))}
                      </TableCell>
                      <TableCell>
                        {type.exclusionIcdCodes.map((code, i) => (
                          <Typography component="span" key={i}>
                            {code}
                            {i < type.exclusionIcdCodes.length - 1 && <>," "</>}
                          </Typography>
                        ))}
                      </TableCell>
                      <TableCell>
                        {type.inclusionIcdCodes.map((code, i) => (
                          <Typography component="span" key={i}>
                            {code}
                            {i < type.inclusionIcdCodes.length - 1 && <>," "</>}
                          </Typography>
                        ))}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          size="small"
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            handleEdit(type);
                          }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </SelectedRecallTypeContext.Provider>
    </>
  );
};

export default PracticeRecallDiagnosisCodesTable;
