import axios, { AxiosResponse } from "axios";
import { AxiosProgressEvent } from "axios";
import { dataIngestionClient } from "../../utils/dataIngestionClient";

const { REACT_APP_DATA_INGESTION_ENDPOINT} = process.env;
export const s3Client = axios.create({
  baseURL: `${REACT_APP_DATA_INGESTION_ENDPOINT}/api`,
});

export type UploadMediaInput = {
  url: string;
  file: File;
  contentType: string;
};

export type Practice = {
  id: string;
  name: string;
};

export type UploadSession = {
  id: number,
  state: "created" | "scheduled" | "processing" | "processing_failed" | "completed" | "done" | "mismatch",
  files: UploadSessionFile[]
};

export type UploadSessionFile = {
  id: number,
  state: "created" | "uploaded" | "processing" | "processing_failed" | "completed",
  transformProgress: "created" | "scheduled" | "downloading" | "transforming" | "uploading" | "importing" | "done"
  validRecords: number,
  invalidRecords: number
};

export const uploadSessionStart = (
  practiceId: string
): Promise<
  AxiosResponse<UploadSession>
> => {
  return dataIngestionClient.post(`api/upload-session/new`, {practiceId: practiceId});
}

export const uploadSessionNewFile = (
  sessionId: number,
  fileName: string
): Promise<
  AxiosResponse<{
    file: UploadSessionFile,
    uploadUrl: string
  }>
> => {
  return dataIngestionClient.post(`api/upload-session/${sessionId}/new-file`, {fileName: fileName});
}

export const uploadSessionStage = (
  sessionId: number
): Promise<AxiosResponse<unknown>> => {
  return dataIngestionClient.post(`api/upload-session/${sessionId}/import`);
}

export const uploadSessionStatus = (
  sessionId: number
): Promise<AxiosResponse<UploadSession>> => {
  return dataIngestionClient.get(`api/upload-session/${sessionId}/status`);
}

export const uploadSessionFileDelete = (
  fileId: number
): Promise<AxiosResponse<UploadSession>> => {
  return dataIngestionClient.delete(`api/upload-session/files/${fileId}`);
}

export const uploadMedia = (
  upload: UploadMediaInput,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
) => {
  const config = {
    headers: { "Content-Type": upload.contentType },
    onUploadProgress,
  };
  return s3Client.put(upload.url, upload.file, config);
};

export const getPractices = (): Promise<AxiosResponse<{ practices: Practice[] }>> => {
  return dataIngestionClient.get(`api/practices/list`);
};
