import { createBrowserRouter } from "react-router-dom";
import Practices from "../pages/Practices/Practices";
import AccountSettings from "../pages/AccountSettings";
import PracticeAdd, { practiceAddLoader } from "../pages/PracticesAdd";
import ProviderInput from "../pages/ProviderInput";
import MainLayout from "../Layout/MainLayout";
import HomePage from "../pages/HomePage";
import Error from "../pages/Error";
import ComingSoon from "../pages/ComingSoon";
import PracticeDetails from "../pages/PracticeDetails";
import LocationAdd, { manipulateLocationAction } from "../pages/LocationAdd";
import DataTypeProcedure from "../pages/DataTypeProcedure";
import DataTypeAppointmentType from "../pages/DataTypeAppointmentType";
import DataTypeAppointmentStatus from "../pages/DataTypeAppointmentStatus";
import PracticeProviders from "../pages/PracticeProviders";
import PracticeLocations from "../pages/PracticeLocations";
import PracticeMappingsAppointmentType from "../pages/PracticeMappingsAppointmentType";
import DataTypeRetail from "../pages/DataTypeRetail";
import PracticeMappingsProcedure from "../pages/PracticeMappingsProcedure";
import PracticeMappingsLocation from "../pages/PracticeMappingsLocation";
import PracticeMappingsRetail from "../pages/PracticeMappingsRetail";
import PracticeMappingsAppointmentStatus from "../pages/PracticeMappingsAppointmentStatus";
import DataTypeTender from "../pages/DataTypeTender";
import DataTypePolicy from "../pages/DataTypePolicy";
import PracticeMappingsPolicy from "../pages/PracticeMappingsPolicy";
import PracticeMappingsTender from "../pages/PracticeMappingsTender";
import DataUploader from "../pages/DataUploader";
import PracticeMappingsProvider from "../pages/PracticeMappingsProvider";
import Campaigns from "../pages/Campaigns/Campaigns";
import Results from "../pages/Results/Results";
import PracticePatientUnsubscribe from "../pages/Unsubscribes/PracticePatientUnsubscribe";
import CampaignSettings from "../pages/CampaignSettings/CampaignSettings";
import DataTypeDiagnosisCodes from "../pages/DataTypeDiagnosisCodes";
import PracticeDiagnosisCodes from "../pages/PracticeDiagnosisCodes";
import KeyDrivers from "../pages/Insights/KeyDrivers";
import Performance from "../pages/Insights/Performance";
import PracticeIntegrations from "../pages/PracticeIntegrations";

const router = createBrowserRouter([
  {
    path: "/email-unsubscribe",
    element: <PracticePatientUnsubscribe />,
    errorElement: <Error />,
  },
  {
    path: "/",
    element: <MainLayout />,
    errorElement: <Error />,
    children: [
      { index: true, element: <HomePage /> },
      {
        path: "practices",
        children: [
          {
            index: true,
            element: <Practices />,
            id: "practices",
          },
          {
            path: "page/:pageNumber",
            element: <Practices />,
          },
          {
            path: "add",
            element: <PracticeAdd />,
            loader: practiceAddLoader,
          },
          {
            path: ":uuid",
            children: [
              {
                path: "details",
                element: <PracticeDetails />,
              },
              {
                path: "providers",
                children: [
                  {
                    index: true,
                    element: <PracticeProviders />,
                  },
                  {
                    path: "add",
                    element: <ProviderInput />,
                  },
                  {
                    path: ":providerUuid/edit",
                    element: <ProviderInput />,
                  },
                ],
              },
              {
                path: "locations",
                children: [
                  { index: true, element: <PracticeLocations /> },
                  {
                    path: "add",
                    element: <LocationAdd />,
                    action: manipulateLocationAction,
                  },
                ],
              },
              {
                path: "campaign-settings",
                element: <CampaignSettings />,
              },
              {
                path: "practice-integrations",
                children: [
                  { index: true, element: <PracticeIntegrations /> },
                ],
              },
              {
                path: "mappings",
                children: [
                  {
                    path: "appointment-type",
                    element: <PracticeMappingsAppointmentType />,
                  },
                  {
                    path: "appointment-status",
                    element: <PracticeMappingsAppointmentStatus />,
                  },
                  {
                    path: "location",
                    element: <PracticeMappingsLocation />,
                  },
                  {
                    path: "provider",
                    element: <PracticeMappingsProvider />,
                  },
                  {
                    path: "policy",
                    element: <PracticeMappingsPolicy />,
                  },
                  {
                    path: "procedure",
                    element: <PracticeMappingsProcedure />,
                  },
                  {
                    path: "retail",
                    element: <PracticeMappingsRetail />,
                  },
                  {
                    path: "tender",
                    element: <PracticeMappingsTender />,
                  },
                  {
                    path: "diagnosis-codes",
                    element: <PracticeDiagnosisCodes />,
                  },
                ],
              },
              {
                path: "insights",
                children: [
                  { path: "performance", element: <Performance /> },
                  { path: "key-drivers", element: <KeyDrivers /> },
                ],
              },
              { path: "account-settings", element: <AccountSettings /> },
              { path: "campaigns", element: <Campaigns /> },
              { path: "results", element: <Results /> },
              { path: "campaign-composer", element: <ComingSoon /> },
              { path: "data-uploader", element: <DataUploader /> },
              { path: "financial", element: <ComingSoon /> },
              { path: "patient-finder", element: <ComingSoon /> },
              { path: "patients", element: <ComingSoon /> },
              { path: "providers", element: <ComingSoon /> },
              { path: "visits", element: <ComingSoon /> },
            ],
          },
        ],
      },
      {
        path: "/data-types",
        children: [
          {
            path: "appointment",
            children: [
              { path: "type", element: <DataTypeAppointmentType /> },
              { path: "status", element: <DataTypeAppointmentStatus /> },
            ],
          },
          { path: "policy", element: <DataTypePolicy /> },
          { path: "procedure", element: <DataTypeProcedure /> },
          { path: "retail", element: <DataTypeRetail /> },
          { path: "tender", element: <DataTypeTender /> },
          { path: "diagnosis-codes", element: <DataTypeDiagnosisCodes /> },
        ],
      },
    ],
  },
]);

export default router;
