import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TablePagination,
  IconButton,
  Paper,
} from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { PAGINATION_ROWS_PER_PAGE_OPTIONS, QUERY_KEY_PRACTICE_ATTRIBUTIONS } from "../../store/constants";
import { listPracticeCampaignAttributions } from "../../utils/api/practice-campaign-attribution.api";
import { useParams } from "react-router-dom";
import TableBody from "@mui/material/TableBody";
import CloseIcon from "@mui/icons-material/Close";
import Loading from "../Loading";

const AttributionListDrawer = ({ practiceCampaignUuid, campaignType, onClick }) => {
  const params = useParams();
  const practiceUuid = params.uuid;

  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [totalCount, setTotalCount] = useState(0);

  const { data: attributions, isPending } = useQuery({
    queryKey: [QUERY_KEY_PRACTICE_ATTRIBUTIONS, page, rowsPerPage, practiceUuid, practiceCampaignUuid],
    queryFn: async ({ signal }) => {
      const { items, total } = await listPracticeCampaignAttributions({
        practiceUuid,
        practiceCampaignUuid,
        signal,
        limit: rowsPerPage,
        offset: page * rowsPerPage,
      });
      setTotalCount(total);
      const formatter = new Intl.DateTimeFormat("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
      return items.map((attribution) => {
        return {
          patientId: attribution.externalPatientId,
          patientName: attribution.patientName,
          leadSource: attribution.leadSource.join(",") || "-",
          attributionType: attribution.attributionType,
          emailFirstSent: attribution.emailFirstSent ? formatter.format(new Date(attribution.emailFirstSent)) : "-",
          emailLastSent: attribution.emailLastSent ? formatter.format(new Date(attribution.emailLastSent)) : "-",
          emailLastOpened: attribution.emailLastOpened ? formatter.format(new Date(attribution.emailLastOpened)) : "-",
          appointmentId: attribution.externalAppointmentId,
          appointmentCreatedDate: formatter.format(new Date(attribution.appointmentCreatedDate)),
          appointmentType: attribution.practiceAppointmentType || "-",
        };
      });
    },
  });

  return (
    <Box>
      <Box
        sx={{ backgroundColor: "primary.main", display: "flex", alignItems: "center", justifyContent: "space-between" }}
      >
        <Typography component="p" noWrap sx={{ color: "white", p: 2, fontWeight: 500 }}>
          Attributed Appointments
        </Typography>
        <IconButton onClick={onClick} sx={{ mr: 1 }}>
          <CloseIcon sx={{ color: "primary.contrastText" }} />
        </IconButton>
      </Box>
      <Box p={2} pb={5}>
        {isPending ? (
          <Loading />
        ) : (
          <>
            <TableContainer sx={{ maxHeight: 500 }} component={Paper}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>Campaign</TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>Patient ID</TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>Patient Name</TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>Lead Source</TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>Attribution Type</TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>Email First Sent</TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>Email Last Sent</TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>Email Last Opened</TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>Appointment Created Date</TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>Appointment Id</TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>Practice Appointment Type</TableCell>
                  </TableRow>
                </TableHead>
                {attributions && attributions.length ? (
                  <TableBody>
                    {attributions.map((attribution, i) => (
                      <TableRow hover key={i}>
                        <TableCell>{campaignType}</TableCell>
                        <TableCell>{attribution.patientId}</TableCell>
                        <TableCell>{attribution.patientName}</TableCell>
                        <TableCell>{attribution.leadSource}</TableCell>
                        <TableCell>{attribution.attributionType}</TableCell>
                        <TableCell>{attribution.emailFirstSent}</TableCell>
                        <TableCell>{attribution.emailLastSent}</TableCell>
                        <TableCell>{attribution.emailLastOpened}</TableCell>
                        <TableCell>{attribution.appointmentCreatedDate}</TableCell>
                        <TableCell>{attribution.appointmentId}</TableCell>
                        <TableCell sx={{ textTransform: "capitalize" }}>{attribution.appointmentType}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={11}>No data results</TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={PAGINATION_ROWS_PER_PAGE_OPTIONS}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default AttributionListDrawer;
