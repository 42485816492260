import React from "react";
import { TableCell, TableHead, TableRow } from "@mui/material";
import classes from "../styles/MetricsTable.module.css";

const InsightsTableHeader = ({ label, trendingLabels = [], timeTable, yoyData = [], ytdData = [] }) => {
  return (
    <TableHead>
      <TableRow className={classes.rowHeading}>
        <TableCell>{label}</TableCell>
        <TableCell colSpan={trendingLabels.length} align="center">
          Trending {timeTable}
        </TableCell>
        <TableCell colSpan={yoyData.length + 1} align="center" className={classes.headerSeparator}>
          Year-over-year
        </TableCell>
        {timeTable === "years" && (
          <TableCell align="center" colSpan={ytdData.length + 1} className={classes.headerSeparator}>
            Year-over-year YTD
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

export default InsightsTableHeader;
