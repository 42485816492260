import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StyledTableCell from "./StyledTableCell";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import SelectInput from "./SelectInput";
import { useMutation } from "@tanstack/react-query";
import { queryClient, updatePracticeLocation } from "../utils/http";
import { QUERY_KEY_PRACTICE_LOCATIONS } from "../store/constants";
import { useDispatch } from "react-redux";
import { uiActions } from "../store/ui-slice";
import { useParams } from "react-router-dom";
import StatusBadge from "./StatusBadge/StatusBadge";
import classes from "../styles/table.module.scss";
import { ReactComponent as IconEdit } from "../assets/icon-edit.svg";
import { ReactComponent as IconSettings } from "../assets/icon-settings-white.svg";
import LocationSettingsEditModal from "./LocationSettingsEditModal";
import useCustomClaims from "../utils/hooks/useCustomClaims";

export default function Locations({ locations = [], values = [] }) {
  const dispatch = useDispatch();
  const params = useParams();

  const [editLocationId, setEditLocationId] = useState(false);
  const [editLocationOpen, setEditLocationOpen] = useState(false);
  const [editLocationName, setEditLocationName] = useState("");
  const [editLocationAddress1, setEditLocationAddress1] = useState("");
  const [editLocationAddress2, setEditLocationAddress2] = useState("");
  const [editLocationCity, setEditLocationCity] = useState("");
  const [editLocationState, setEditLocationState] = useState("");
  const [editLocationZip, setEditLocationZip] = useState("");
  const [editLocationStatus, setEditLocationStatus] = useState("");
  const [editLocationActualPhone, setEditLocationActualPhone] = useState("");
  const [editLocationTrackingPhone, setEditLocationTrackingPhone] = useState("");
  const [editLocationEmail, setEditLocationEmail] = useState("");

  const handleEditLocation = (location) => {
    setEditLocationOpen(true);
    setEditLocationId(location.uuid);
    setEditLocationName(location.name);
    setEditLocationAddress1(location.address1);
    setEditLocationAddress2(location.address2);
    setEditLocationCity(location.city);
    setEditLocationState(location.state);
    setEditLocationZip(location.zipCode);
    setEditLocationStatus(location.status);
    setEditLocationActualPhone(location.phoneNumber);
    setEditLocationTrackingPhone(location.trackingNumber);
    setEditLocationEmail(location.email);
  };

  const handleEditLocationClose = () => {
    setEditLocationOpen(false);
  };

  const handleEditLocationNameChange = (event) => {
    setEditLocationName(event.target.value);
  };

  const handleEditLocationAddress1Change = (event) => {
    setEditLocationAddress1(event.target.value);
  };

  const handleEditLocationAddress2Change = (event) => {
    setEditLocationAddress2(event.target.value);
  };

  const handleEditLocationCityChange = (event) => {
    setEditLocationCity(event.target.value);
  };

  const handleEditLocationStateChange = (event) => {
    setEditLocationState(event.target.value);
  };

  const handleEditLocationZipChange = (event) => {
    setEditLocationZip(event.target.value);
  };

  const handleEditLocationStatusChange = (event) => {
    setEditLocationStatus(event.target.value);
  };

  const handleEditLocationActualPhoneChange = (event) => {
    setEditLocationActualPhone(event.target.value);
  };

  const handleEditLocationTrackingPhoneChange = (event) => {
    setEditLocationTrackingPhone(event.target.value);
  };

  const handleEditLocationEmailChange = (event) => {
    setEditLocationEmail(event.target.value);
  };

  const handleEditLocationChanged = (event) => {
    event.preventDefault();
    let data = {};
    data.name = editLocationName;
    data.address1 = editLocationAddress1;
    data.address2 = editLocationAddress2;
    data.city = editLocationCity;
    data.state = editLocationState;
    data.zipCode = editLocationZip.toString();
    data.status = editLocationStatus;
    data.phoneNumber = editLocationActualPhone;
    data.trackingNumber = editLocationTrackingPhone;
    data.email = editLocationEmail;
    mutate({
      uuid: params.uuid,
      id: editLocationId,
      data: data,
    });

    handleEditLocationClose();
  };

  const { mutate, isPending } = useMutation({
    mutationFn: updatePracticeLocation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_PRACTICE_LOCATIONS] });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Location updated.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_PRACTICE_LOCATIONS] });
      dispatch(
        uiActions.showNotification({
          status: "error",
          message: "Error updating Location. Please try again later.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  const { isBonsaiAdmin } = useCustomClaims();
  const [isCampaignModalOpen, setIsCampaignModalOpen] = useState(false);
  const [settingsLocation, setSettingsLocation] = useState(null);
  const handleEditSettings = (location) => {
    setSettingsLocation(location);
    setIsCampaignModalOpen(true);
  };
  const handleSettingsClose = () => {
    setIsCampaignModalOpen(false);
    setSettingsLocation(null);
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
        <Table size="small" aria-label="Locations table" sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Address</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {locations.map((location) => (
              <TableRow key={location.uuid} className={classes.tableRow}>
                <TableCell component="th" scope="row">
                  {location.name}
                </TableCell>
                <TableCell>
                  {location.address1}
                  {location.address2 && `, ${location.address2}`}
                  <br />
                  {location.city}, {location.state} {location.zipCode}
                </TableCell>
                <TableCell>
                  <StatusBadge status={location.status} />
                </TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    variant="outlined"
                    color="primary"
                    className={classes.iconButton}
                    sx={{
                      "&.MuiButtonBase-root:hover": {
                        bgcolor: "transparent",
                      },
                    }}
                    onClick={() => {
                      handleEditLocation(location);
                    }}
                  >
                    <IconEdit />
                  </IconButton>
                  {isBonsaiAdmin && (
                    <IconButton
                      size="small"
                      variant="outlined"
                      color="primary"
                      className={classes.iconButton}
                      sx={{
                        "&.MuiButtonBase-root:hover": {
                          bgcolor: "transparent",
                        },
                        "path:not(:hover)": {
                          stroke: "rgb(162, 154, 178)",
                        },
                      }}
                      onClick={() => {
                        handleEditSettings(location);
                      }}
                    >
                      <IconSettings />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={editLocationOpen}
        onClose={handleEditLocationClose}
        PaperProps={{
          component: "form",
          onSubmit: handleEditLocationChanged,
        }}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle>
          <Typography variant="h4" component="p">
            Edit Location
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              <TextField
                autoFocus
                required
                margin="dense"
                label="Location Name"
                type="text"
                fullWidth
                variant="outlined"
                value={editLocationName}
                onChange={handleEditLocationNameChange}
                sx={{ mb: 3 }}
              />
              <TextField
                required
                margin="dense"
                label="Address 1"
                type="text"
                fullWidth
                variant="outlined"
                value={editLocationAddress1}
                onChange={handleEditLocationAddress1Change}
                sx={{ mb: 3 }}
              />
              <TextField
                margin="dense"
                label="Address 2"
                type="text"
                fullWidth
                variant="outlined"
                value={editLocationAddress2}
                onChange={handleEditLocationAddress2Change}
                sx={{ mb: 3 }}
              />
              <TextField
                required
                margin="dense"
                label="City"
                type="text"
                fullWidth
                variant="outlined"
                value={editLocationCity}
                onChange={handleEditLocationCityChange}
                sx={{ mb: 3 }}
              />
              <Grid container spacing={4}>
                <Grid item xs={12} lg={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <SelectInput
                      required
                      label="State"
                      name="state"
                      value={editLocationState}
                      onChange={handleEditLocationStateChange}
                      items={values.state}
                      sx={{ background: "#ffffff" }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <TextField
                    required
                    label="Zip Code"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={editLocationZip}
                    onChange={handleEditLocationZipChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                margin="dense"
                label="Location Email"
                type="text"
                fullWidth
                variant="outlined"
                value={editLocationEmail || ""}
                onChange={handleEditLocationEmailChange}
                sx={{ mb: 3 }}
              />
              <TextField
                required
                margin="dense"
                label="Location Phone"
                type="text"
                fullWidth
                variant="outlined"
                value={editLocationActualPhone || ""}
                onChange={handleEditLocationActualPhoneChange}
                sx={{ mb: 3 }}
              />
              <TextField
                margin="dense"
                label="Tracking Phone"
                type="text"
                fullWidth
                variant="outlined"
                value={editLocationTrackingPhone || ""}
                onChange={handleEditLocationTrackingPhoneChange}
                sx={{ mb: 3 }}
              />

              <FormControl sx={{ width: "100%" }}>
                <SelectInput
                  required
                  label="Status"
                  name="status"
                  value={editLocationStatus}
                  onChange={handleEditLocationStatusChange}
                  items={values.status}
                  sx={{ background: "#ffffff" }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button onClick={handleEditLocationClose}>Cancel</Button>
          <Button type="submit" variant="contained" disableElevation disabled={isPending}>
            {isPending ? "Saving..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
      {isCampaignModalOpen && settingsLocation !== null && (
        <LocationSettingsEditModal
          isOpen={isCampaignModalOpen}
          locationUuid={settingsLocation?.uuid}
          locationTrackingNumber={settingsLocation?.trackingNumber}
          onClose={handleSettingsClose}
        />
      )}
    </>
  );
}
