import { MONTHS } from "../../store/constants";

export const tableDataPrep = (metricData, metric) => {
  let data = metricData.data;
  let timetable = metricData.timetable;
  let reportData = {
    timeTable: "",
    trendingData: [],
    trendingLabels: [],
    yoyData: [],
    yoyLabels: [],
    yoyDifference: null,
    ytdData: [],
    ytdLabels: [],
    ytdDifference: null,
  };

  let trendingLabels = [];
  let currentData = [];
  let priorData = [];
  let yoyData = [];
  let yoyLabels = [];
  let ytdData = [];
  let ytdLabels = [];

  /** Monthly Data */
  if (timetable === "monthly") {
    let currentMonth = data[0].date.month;
    let currentYear = data[0].date.year;
    let fullData = [];
    let current = [];
    let prior = [];

    reportData.timeTable = "months";

    for (let i = 0; i < 24; i++) {
      if (data[i]) {
        fullData.unshift({
          value: data[i][metric],
          date: `${MONTHS[data[i].date.month - 1]} ${data[i].date.year}`,
        });
      } else {
        fullData.unshift({
          value: 0,
          date: `${MONTHS[currentMonth - 1]} ${currentYear}`,
        });
      }
      currentMonth--;
      if (currentMonth < 1) {
        currentMonth = 12;
        currentYear--;
      }
    }

    prior = fullData.slice(0, 12);
    current = fullData.slice(12);

    current.forEach((curr) => {
      currentData.push(curr.value);
      trendingLabels.push(curr.date);
    });

    prior.forEach((pr) => {
      priorData.push(pr);
    });

    reportData.trendingData = currentData;
    reportData.trendingLabels = trendingLabels;

    yoyData.push(prior[10].value);
    yoyData.push(current[10].value);
    reportData.yoyData = yoyData;

    yoyLabels.push(prior[10].date);
    yoyLabels.push(current[10].date);
    reportData.yoyLabels = yoyLabels;
    reportData.yoyDifference = (yoyData[1] - yoyData[0]) / yoyData[0];
  }

  /** Quarterly Data */
  if (timetable === "quarterly") {
    let currentQuarter = data[0].date.quarter;
    let currentYear = data[0].date.year;
    let fullData = [];
    let current = [];
    let prior = [];

    reportData.timeTable = "quarters";

    for (let i = 0; i < 8; i++) {
      if (data[i]) {
        fullData.unshift({
          value: data[i][metric],
          date: `Q${data[i].date.quarter} ${data[i].date.year}`,
        });
      } else {
        fullData.unshift({
          value: 0,
          date: `Q${currentQuarter} ${currentYear}`,
        });
      }
      currentQuarter--;
      if (currentQuarter < 1) {
        currentQuarter = 4;
        currentYear--;
      }
    }

    prior = fullData.slice(0, 4);
    current = fullData.slice(4);

    current.forEach((curr) => {
      currentData.push(curr.value);
      trendingLabels.push(curr.date);
    });

    prior.forEach((pr) => {
      priorData.push(pr);
    });

    reportData.trendingData = currentData;
    reportData.trendingLabels = trendingLabels;

    yoyData.push(prior[2].value);
    yoyData.push(current[2].value);
    reportData.yoyData = yoyData;

    yoyLabels.push(prior[2].date);
    yoyLabels.push(current[2].date);
    reportData.yoyLabels = yoyLabels;
    reportData.yoyDifference = (yoyData[1] - yoyData[0]) / yoyData[0];
  }

  /** Yearly Data */
  if (timetable === "yearly") {
    let currentYear = data[0].date.year;

    reportData.timeTable = "years";

    for (let i = 0; i < 4; i++) {
      if (data[i]) {
        currentData.unshift(data[i][metric]);
        trendingLabels.unshift(data[i].date.year);
      } else {
        currentData.unshift(0);
        trendingLabels.unshift(currentYear);
      }
      currentYear--;
    }

    reportData.trendingData = currentData;
    reportData.trendingLabels = trendingLabels;

    yoyLabels.unshift(trendingLabels[trendingLabels.length - 2]);
    yoyLabels.unshift(trendingLabels[trendingLabels.length - 3]);
    reportData.yoyLabels = yoyLabels;

    yoyData.unshift(currentData[currentData.length - 2]);
    yoyData.unshift(currentData[currentData.length - 3]);
    reportData.yoyData = yoyData;
    reportData.yoyDifference = (yoyData[1] - yoyData[0]) / yoyData[0];

    ytdLabels.unshift(trendingLabels[trendingLabels.length - 1]);
    ytdLabels.unshift(trendingLabels[trendingLabels.length - 2]);
    reportData.ytdLabels = ytdLabels;

    ytdData.unshift(currentData[currentData.length - 1]);
    ytdData.unshift(currentData[currentData.length - 2]);
    reportData.ytdData = ytdData;
    reportData.ytdDifference = (ytdData[1] - ytdData[0]) / ytdData[0];
  }

  return reportData;
};
