import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import StyledTableCell from "./StyledTableCell";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, status, npi, title, type) {
  return { name, status, npi, title, type };
}

const rows = [
  createData(
    "Parrish Sadeghi",
    "Active",
    "40193483022",
    "MD",
    "Medical Doctor"
  ),
  createData("Cossette Joy", "Active", "40193483747", "MD", "Medical Doctor"),
  createData(
    "Jennie Aufhauser",
    "Active",
    "40193483747",
    "PA-C",
    "Physician Assistant-Certified"
  ),
];

export default function Providers() {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="Providers table" size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>NPI Number</StyledTableCell>
              <StyledTableCell>Provider Title</StyledTableCell>
              <StyledTableCell>Provider Type</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell>{row.status}</StyledTableCell>
                <StyledTableCell>{row.npi}</StyledTableCell>
                <StyledTableCell>{row.title}</StyledTableCell>
                <StyledTableCell>{row.type}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Button
        href="/providers/add"
        variant="contained"
        disableElevation
        startIcon={<AddIcon />}
        sx={{ my: 3 }}
      >
        Add Provider
      </Button>
    </>
  );
}
