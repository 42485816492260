import React from "react";
import { TableSortLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const StyledCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.gray50,
    color: theme.palette.primary.gray500,
    padding: "0.75rem 1rem",
    borderBottom: "none",
  },
}));

const StyledOrderedTableCell = (props) => {
  return <StyledCell sx={{ ...props.sx }}>
    <TableSortLabel
      active={props.orderField === props.fieldName}
      direction={props.orderDirection}
      onClick={() => props.changeOrder(props.fieldName)}>
      {props.children}
    </TableSortLabel>
  </StyledCell>;
};

export default StyledOrderedTableCell;
