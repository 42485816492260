import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import TextInput from "../TextInput";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { updatePracticeCampaign } from "../../utils/api/practice-campaigns.api";
import { queryClient } from "../../utils/http";
import { QUERY_KEY_PRACTICE_CAMPAIGNS } from "../../store/constants";
import { uiActions } from "../../store/ui-slice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const PracticeCampaignSettingsModal = ({ isOpen, campaign, onClose, title }) => {
  const { uuid: practiceUuid } = useParams();
  const dispatch = useDispatch();

  const [value, setValue] = useState(campaign.maxContactsPerWeek || 50);
  const updateValue = ($event) => {
    setValue($event.target.value);
  };

  const { mutate: saveSettings } = useMutation({
    mutationFn: () => {
      const practiceCampaign = {
        uuid: campaign.uuid,
        maxContactsPerWeek: value,
      };
      return updatePracticeCampaign({ practiceUuid, practiceCampaign });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_PRACTICE_CAMPAIGNS, practiceUuid],
      });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Practice campaign saved",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
      onClose();
    },
    onError: () => {
      dispatch(
        uiActions.showNotification({
          status: "error",
          message: "Error saving practice campaign",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  const handleSaveClick = (event) => {
    event.preventDefault();
    saveSettings();
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        component: 'form',
        onSubmit: handleSaveClick,
      }}
      fullWidth
      maxWidth={"xs"}
    >
      <DialogTitle>
        <Typography
          variant="h4"
          component="p"
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12} mt={3}>
            <TextInput
              required
              label="Enrolled Patients Per Week"
              name="patientsPerWeek"
              type="number"
              value={value}
              onChange={($event) => updateValue($event)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit" variant="contained" disableElevation>
          Save
        </Button>
      </DialogActions>
    </Dialog>
    )
};

export default PracticeCampaignSettingsModal;