import { Button, DialogContent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";
import { QUERY_KEY_PRACTICE } from "../../store/constants";
import { fetchPractice } from "../../utils/api/practices.api";
import Loading from "../Loading";

const SkinScreeningEmailPreview = () => {
  const params = useParams();
  const uuid = params.uuid;
  /** Fetch Practice **/
  const { data: formData, isPending } = useQuery({
    queryKey: [QUERY_KEY_PRACTICE, uuid],
    queryFn: async ({ signal }) => {
      const [practice] = await Promise.all([fetchPractice({ uuid, signal })]);
      return { practice };
    },
  });

  if (isPending) {
    return <Loading />;
  }

  return (
    <>
      <Box sx={{ backgroundColor: "primary.gray200", p: 2 }}>
        <Typography variant="bodySemi">Action required: Schedule your skin screening appointment</Typography>
      </Box>

      <DialogContent p={3}>
        <Typography>
          Dear [Patient first Name],
          <br />
          <br />
          I've noticed you're overdue for your next skin screening appointment.
          <br />
          <br />
          Did you know?
          <br />
        </Typography>
        <Typography component="ul" sx={{ listStyleType: "disc", ml: 4, my: 2 }}>
          <li>1 in 5 Americans will develop skin cancer by age 70.</li>
          <li>Early detection boasts a 99% 5-year survival rate for skin cancer.</li>
          <li>Without early detection, that rate drops to 30%.</li>
        </Typography>
        <Typography>
          <br />
          Early detection is truly vital. Please schedule your appointment or call us directly at [phone number].
          <br />
          <br />
          <Button href="https://form.jotform.com/241775643117156" target="_blank" variant="contained" disableElevation>
            Book Now
          </Button>
          <br />
          <br />
          Looking forward to seeing you soon.
          <br />
          <br />
          Sincerely,
          <br />
          {formData?.practice?.name ? formData.practice.name : "[Practice Name]"}
        </Typography>
      </DialogContent>
    </>
  );
};

export default SkinScreeningEmailPreview;
