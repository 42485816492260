import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableContainer,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { QUERY_KEY_LOCATION_CAMPAIGNS } from "../store/constants";
import { useParams } from "react-router-dom";
import { fetchLocationCampaigns, updateLocationCampaign } from "../utils/api/location-campaigns.api";
import Loading from "./Loading";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import StyledTableCell from "./StyledTableCell";
import TableHead from "@mui/material/TableHead";
import classes from "../styles/table.module.scss";
import TableCell from "@mui/material/TableCell";
import { useEffect, useState } from "react";
import { queryClient } from "../utils/http";
import { useDispatch } from "react-redux";
import { uiActions } from "../store/ui-slice";
import SwapIcon from "@mui/icons-material/SwapHoriz";
import { transferTrackingNumber } from "../utils/api/practice-subaccount.api";
import { AxiosError } from "axios";

export default function LocationSettingsEditModal({ locationUuid, locationTrackingNumber, isOpen = false, onClose }) {
  const { uuid: practiceUuid } = useParams();
  const dispatch = useDispatch();

  /** Fetch Location Campaigns **/
  const { data: locationCampaigns, isPending } = useQuery({
    queryKey: [QUERY_KEY_LOCATION_CAMPAIGNS],
    queryFn: ({ signal }) =>
      fetchLocationCampaigns({
        practiceUuid,
        locationUuid,
        signal,
      }),
  });

  /** Initialize Campaign Data **/
  const [campaignMap, setCampaignMap] = useState({});
  useEffect(() => {
    if (!locationCampaigns?.length) {
      return;
    }
    setCampaignMap(
      locationCampaigns.reduce(
        (cMap, campaign) => ({
          ...cMap,
          [campaign.uuid]: campaign.sgMarketingCampaignId || "",
        }),
        {}
      )
    );
  }, [locationCampaigns]);

  /** Manage Campaign Text Input **/
  const updateCampaign = ($event, campaignUuid) => {
    setCampaignMap({
      ...campaignMap,
      [campaignUuid]: $event.target.value,
    });
  };

  /** Save Campaign **/
  const saveCampaign = (campaignUuid) => {
    return saveLocationCampaign({
      uuid: campaignUuid,
      sgMarketingCampaignId: campaignMap[campaignUuid],
      practiceUuid,
      locationUuid,
    });
  };

  const { mutate: saveLocationCampaign } = useMutation({
    mutationFn: updateLocationCampaign,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_LOCATION_CAMPAIGNS] });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Location Campaign updated.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  /** Transfer Tracking Number **/
  const { mutate: transferNumber } = useMutation({
    mutationFn: transferTrackingNumber,
    onSuccess: () => {
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Location tracking number transferred to practice subaccount.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
    onError: (error) => {
      const message = error && error instanceof AxiosError ? error.response.data.message : error.message;
      dispatch(
        uiActions.showNotification({
          status: "error",
          message: message,
        }),
        dispatch(uiActions.showSnackBar(true)),
      )
    }
  });

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h3" component="p">
          Location Settings
        </Typography>
      </DialogTitle>
      <DialogContent>
        {isPending && <Loading />}
        {!isPending && !locationCampaigns?.length && <Typography>No current location campaigns</Typography>}
        {!isPending && locationCampaigns?.length && (
          <Box  sx={{ my: 3, display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Typography variant="h4" component="p">
              Sendgrid Campaigns
            </Typography>
            <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
              <Table sx={{ minWidth: 400 }} size="small" aria-label="Campaigns table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Type</StyledTableCell>
                    <StyledTableCell>Sendgrid Campaign Id</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {locationCampaigns.map((campaign) => (
                    <TableRow key={campaign.uuid} className={classes.tableRow}>
                      <TableCell>{campaign.campaignType}</TableCell>
                      <TableCell>
                        <TextField
                          type="text"
                          value={campaignMap[campaign.uuid]}
                          size="small"
                          onChange={($event) => updateCampaign($event, campaign.uuid)}
                        ></TextField>
                      </TableCell>
                      <TableCell>
                        <Button onClick={() => saveCampaign(campaign.uuid)}>Save</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
        {!isPending && locationTrackingNumber && (
          <Box sx={{ mb: 3, display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Typography variant="h4" component="p">
              Tracking Numbers
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="body2">{locationTrackingNumber}</Typography>
              <Button
                disableElevation
                startIcon={<SwapIcon />}
                onClick={() => transferNumber({ practiceUuid, locationUuid })}
              >
                Transfer Tracking Number
              </Button>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
