export const SIDE_NAV_WIDTH = 280;
export const INFO_DRAWER_WIDTH = 440;

export const PAGINATION_ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100, 1000];
export const PAGINATION_ROWS_PER_PAGE_DEFAULT = 10;

export const TYPE_APPOINTMENT = "appointment-type";
export const TYPE_LOCATION = "location";
export const TYPE_PROVIDER = "provider";
export const TYPE_POLICY = "policy-type";
export const TYPE_PROCEDURE = "procedure-type";
export const TYPE_RETAIL = "retail-type";
export const TYPE_STATUS = "appointment-status";
export const TYPE_TENDER = "tender-type";
export const TYPE_RECALL = "recall-type";
export const TYPE_UPLOADS = "session-uploads";

export const QUERY_KEY_PRACTICE_APPOINTMENT_MAPPINGS = "practice-appointment-mappings";
export const QUERY_KEY_PRACTICE_LOCATION_MAPPINGS = "practice-location-mappings";
export const QUERY_KEY_PRACTICE_PROVIDER_MAPPINGS = "practice-provider-mappings";
export const QUERY_KEY_DATA_TYPE_POLICY_MAPPINGS = "policy-type-mappings";
export const QUERY_KEY_PRACTICE_PROCEDURE_MAPPINGS = "practice-procedure-mappings";
export const QUERY_KEY_PRACTICE_RETAIL_MAPPINGS = "practice-retail-mappings";
export const QUERY_KEY_PRACTICE_STATUS_MAPPINGS = "practice-status-mappings";
export const QUERY_KEY_PRACTICE_RECALL_TYPES = "practice-recall-types";
export const QUERY_KEY_DATA_TYPE_TENDER_MAPPINGS = "tender-type-mappings";

export const QUERY_KEY_DATA_TYPE_TENDER = "tender-data-type";
export const QUERY_KEY_DATA_TYPE_POLICY = "policy-data-type";

export const QUERY_KEY_PRACTICE = "practice";
export const QUERY_KEY_PRACTICE_LOCATIONS = "practice-locations";
export const QUERY_KEY_PRACTICE_USERS = "practice-users";
export const QUERY_KEY_PRACTICE_TIMEZONE = "practice-timezone";
export const QUERY_KEY_USER_FORM_POSSIBLE_VALUES = "user-form-possible-values";

export const QUERY_KEY_PRACTICE_INTEGRATIONS = "practice-integrations";
export const QUERY_KEY_INTEGRATION_FORM_POSSIBLE_VALUES = "integration-form-possible-values";

export const QUERY_KEY_PRACTICE_CAMPAIGNS = "practice-campaigns";
export const QUERY_KEY_BONSAI_CAMPAIGNS = "bonsai-campaigns";
export const QUERY_KEY_LOCATION_CAMPAIGNS = "location-campaigns";

export const QUERY_KEY_PRACTICE_CAMPAIGN_ATTRIBUTION = "practice-campaign-attribution";
export const QUERY_KEY_RECALL_CAMPAIGN_OPPORTUNITIES = "recall-campaign-opportunities";

export const QUERY_KEY_PRACTICE_PATIENT_UNSUBSCRIBE_OPTIONS = "contact-unsubscribe-options";

export const QUERY_KEY_PRACTICE_ATTRIBUTIONS = "practice-campaign-attributions";

export const QUERY_KEY_PRACTICE_FEATURE_TOGGLES = "practice-feature-toggles";
export const QUERY_KEY_FEATURE_TOGGLE_OPTIONS = "feature-toggle-options";

export const QUERY_KEY_REBOOK_RATE = "rebook-rate";
export const QUERY_KEY_REVENUE = "revenue";
export const QUERY_KEY_REVENUE_PER_VISIT = "revenue-per-visit";
export const QUERY_KEY_VISITS = "visits";

export const QUERY_KEY_PERFORMANCE_TABLE = "performance-table-metrics";
export const QUERY_KEY_KEY_DRIVERS_TABLE = "key-drivers-table-metrics";

export const QUERY_KEY_INSIGHTS_METRICS = "insights-metrics";

export const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const INTEGRATION_SOR_MODMED = "ModMed";
export const INTEGRATION_SOR_AESTHETIC_RECORD = "Aesthetic Record";

export const QUERY_KEY_SPAM_SETTINGS = 'spam-settings';
