import React, { useState } from "react";
import { Box } from "@mui/system";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const PopMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOptionClick = ($event, option) => {
    props.handler($event, option);
    handleClose();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton id={`${props.id}-button`} onClick={handleButtonClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id={`${props.id}-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": `${props.id}-button`,
        }}
      >
        {
          props.options.map((option) => (
            <MenuItem
              onClick={($event) => handleOptionClick($event, option)}
              key={`${option.text}-option`}
            >{option.text}</MenuItem>
          ))
        }
      </Menu>
    </Box>
  );
}

export default PopMenu;
