import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";

const RevenueType = ({ change, disabled }) => {
  const revenueTypes = [
    { value: "net", label: "Net Revenue" },
    { value: "gross", label: "Gross Revenue" },
  ];
  const [selectedRevenueType, setSelectedRevenueType] = useState("net");
  const handleRevenueChange = (event) => {
    setSelectedRevenueType(event.target.value);
    change(event.target.value);
  };
  return (
    <FormControl sx={{ m: 1, minWidth: 180, backgroundColor: "white" }} size="small">
      <InputLabel id="revenue-label">Primary Revenue Metric</InputLabel>
      <Select
        label="Primary Revenue Metric"
        onChange={handleRevenueChange}
        value={selectedRevenueType}
        disabled={disabled}
      >
        {revenueTypes.map((revenueType, i) => (
          <MenuItem key={i} value={revenueType.value}>
            {revenueType.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default RevenueType;
