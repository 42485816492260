import { coreAppClient } from "../coreAppClient";

const { REACT_APP_API_ENDPOINT } = process.env;
const buildBaseUrl = (practiceUuid) => `${REACT_APP_API_ENDPOINT}/practices/${practiceUuid}/users`;

export async function createPracticeUser({ user, practiceUuid }) {
  const requestUrl = buildBaseUrl(practiceUuid);

  const response = await fetch(requestUrl, {
    method: "POST",
    body: JSON.stringify(user),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("An error occurred while creating practice user");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return await response.json();
}

export async function deletePracticeUser ({ practiceUuid, uuid })  {
  try {
    const deleteResponse = await coreAppClient.delete(`practices/${practiceUuid}/users/${uuid}`);
    return deleteResponse.status === 200;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function editPracticeUser({ user, practiceUuid, userUuid }) {
  const requestUrl = `${buildBaseUrl(practiceUuid)}/${userUuid}`;

  const response = await fetch(requestUrl, {
    method: "PATCH",
    body: JSON.stringify(user),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("An error occurred while editing practice user");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return await response.json();
}

export async function fetchPossibleValues({ uuid, signal }) {
  const requestUrl = `${buildBaseUrl(uuid)}/possible-values`;
  const values = await fetch(requestUrl, { signal });

  if (!values.ok) {
    const error = new Error("An error occurred while fetching possible values");
    error.code = values.status;
    error.info = await values.json();
    throw error;
  }

  return await values.json();
}

export async function fetchPracticeUser({ practiceUuid, userUuid, signal }) {
  const requestUrl = `${buildBaseUrl(practiceUuid)}/${userUuid}`;
  const user = await fetch(requestUrl, { signal });

  if (!user.ok) {
    const error = new Error("An error occurred while fetching practice user");
    error.code = user.status;
    error.info = await user.json();
    throw error;
  }

  return await user.json();
}

export async function fetchPracticeUsers({ uuid, limit, offset, signal }) {
  const baseUrl = buildBaseUrl(uuid);
  const query = new URLSearchParams({ limit, offset });
  const usersData = await fetch(`${baseUrl}?${query.toString()}`, { signal });

  if (!usersData.ok) {
    const error = new Error("An error occurred while fetching practice users");
    error.code = usersData.status;
    error.info = await usersData.json();
    throw error;
  }

  return await usersData.json();
}
