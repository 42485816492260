import { coreAppClient } from "../coreAppClient";

const buildBaseUrl = (practiceUuid) => `/practices/${practiceUuid}/campaigns`;

export const fetchPracticeCampaigns = async ({ practiceUuid, signal }) => {
  const response = await coreAppClient.get(buildBaseUrl(practiceUuid), { signal });
  return response.data;
};

export const createPracticeCampaign = async ({ practiceUuid, practiceCampaign }) => {
  const response = await coreAppClient.post(buildBaseUrl(practiceUuid), practiceCampaign);
  return response.data;
};

export const updatePracticeCampaign = async ({ practiceUuid, practiceCampaign }) => {
  const response = await coreAppClient.patch(`${buildBaseUrl(practiceUuid)}/${practiceCampaign.uuid}`, practiceCampaign);
  return response.data;
};
