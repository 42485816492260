import { coreAppClient } from "../coreAppClient";

const buildBaseUrl = (practiceUuid) => `/spam-settings/${practiceUuid}`;

export const fetchPracticeSpamSettings = async ({ practiceUuid, signal }) => {
  const response = await coreAppClient.get(buildBaseUrl(practiceUuid), { signal });
  return response.data;
};

export const savePracticeSpamSettings = async ({ practiceUuid, settings }) => {
  const response = await coreAppClient.put(buildBaseUrl(practiceUuid), { settings });
  return response.data;
};