import { Outlet, useNavigation } from "react-router-dom";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import BonsaiHealth from "../themes/BonsaiHealth";
import TopNav from "../components/TopNav/TopNav";
import SideNav from "../components/SideNav/SideNav";
import { useSelector } from "react-redux";
import Notification from "../components/UI/Notification";
import LoaderBar from "../components/LoaderBar";
import { useIsFetching } from "@tanstack/react-query";

const MainLayout = () => {
  const { error } = useAuth0();
  const notification = useSelector((state) => state.ui.notification);
  const navigation = useNavigation();
  const fetching = useIsFetching();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  return (
    <ThemeProvider theme={BonsaiHealth}>
      <CssBaseline />
      {navigation.state === "loading" && <LoaderBar />}
      {fetching > 0 && <LoaderBar />}
      {notification && <Notification status={notification.status} message={notification.message} />}
      <Box sx={{ display: "flex" }}>
        <SideNav />
        <Box component="main" className="content">
          <TopNav />
          <Box padding={3} mb={10}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default MainLayout;
