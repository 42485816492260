import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Paper, Typography, ThemeProvider } from "@mui/material";
import imgLogo from "../assets/bonsai.svg";
import BonsaiHealth from "../themes/BonsaiHealth";

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <ThemeProvider theme={BonsaiHealth}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        sx={{ backgroundColor: "rgb(238, 242, 246)", minHeight: "500px" }}
      >
        <Paper
          square={false}
          elevation={1}
          sx={{ width: 300, margin: 2, padding: 5, display: "flex", flexDirection: "column", alignItems: "center" }}
        >
          <Box component="img" src={imgLogo} height={28} alt="Bonsai logo" sx={{ mb: 2 }} />
          <Typography component="p" variant="h4">
            Welcome back
          </Typography>
          <Button variant="contained" disableElevation fullWidth onClick={() => loginWithRedirect()} sx={{ mt: 4 }}>
            Sign In
          </Button>
        </Paper>
      </Box>
    </ThemeProvider>
  );
};

export default Login;
