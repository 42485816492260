import { ReactComponent as IconPerformance } from "../assets/icon-performance.svg";
import { ReactComponent as IconBankNote } from "../assets/icon-bank-note.svg";

export const insightsMenuItems = [
  {
    label: "Insights",
    items: [
      { title: "Performance", url: "insights/performance", icon: <IconPerformance /> },
      { title: "Key Drivers", url: "insights/key-drivers", icon: <IconBankNote /> },
    ],
  },
];
