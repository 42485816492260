import { coreAppClient } from "../coreAppClient";

const buildBaseUrl = (practiceUuid) => `/campaign/attribution/practice/${practiceUuid}`;

export const fetchPracticeCampaignAttribution = async ({ practiceUuid, signal }) => {
  const response = await coreAppClient.get(buildBaseUrl(practiceUuid), { signal });

  return response.data;
};

export const listPracticeCampaignAttributions = async ({ practiceUuid, practiceCampaignUuid, signal, limit, offset }) => {
  const query = new URLSearchParams({ limit, offset });
  const response = await coreAppClient.get(
    `${buildBaseUrl(practiceUuid)}/practice-campaign/${practiceCampaignUuid}?${query.toString()}`,
    { signal },
  );
  return response.data;
};