import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import DataTypesNav from "../components/DataTypesNav";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { icd10 } from "../data/icd10";
import Chip from "@mui/material/Chip";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createNewRecallType, fetchRecallTypes, queryClient, updateDataType } from "../utils/http";
import { useDispatch } from "react-redux";
import { uiActions } from "../store/ui-slice";
import ErrorBlock from "../components/UI/ErrorBlock";
import { snakeCase } from "lodash";
import { TYPE_RECALL } from "../store/constants";
import Loading from "../components/Loading";
import StyledTableCell from "../components/StyledTableCell";

const DataTypeDiagnosisCodes = () => {
  const dispatch = useDispatch();
  const [types, setTypes] = useState([]);
  const [recallTypeName, setRecallTypeName] = useState("");
  const [recallDescription, setRecallDescription] = useState("");
  const [recallStatus, setRecallStatus] = useState("");
  const [icdCodes, setIcdCodes] = useState([]);
  const [recallTypeOpen, setRecallTypeOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [editTypeId, setEditTypeId] = useState("");
  const [modalType, setModalType] = useState("");

  const handleRecallNameChange = (event) => {
    setRecallTypeName(event.target.value);
  };

  const handleRecallDescriptionChange = (event) => {
    setRecallDescription(event.target.value);
  };

  const handleRecallStatusChange = (event) => {
    setRecallStatus(event.target.value);
  };

  const handleNewRecallTypeOpen = () => {
    setModalType("new");
    setRecallTypeOpen(true);
  };

  const handleNewRecallClose = () => {
    setRecallTypeName("");
    setRecallDescription("");
    setIcdCodes([]);
    setEditTypeId("");
    setRecallTypeOpen(false);
    setRecallStatus("inactive");
  };

  const handleIcdCodeChange = (event, value) => setIcdCodes(value);

  const {
    data: recallData,
    isPending: isPendingRecall,
    isError: isErrorRecall,
    error: errorRecall,
  } = useQuery({
    queryKey: [TYPE_RECALL],
    queryFn: ({ signal, queryKey }) => fetchRecallTypes({ signal, ...queryKey }),
  });

  const {
    mutate: mutateNewRecallType,
    isPending: isPendingNewRecallType,
    isError: isErrorNewRecallType,
    error: errorNewRecallType,
  } = useMutation({
    mutationFn: createNewRecallType,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [TYPE_RECALL] });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "New Retail Type created.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  const { mutate: mutateDataType, isPending: isPendingUpdateDataType } = useMutation({
    mutationFn: updateDataType,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [TYPE_RECALL],
      });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Data Type updated.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: [TYPE_RECALL],
      });
      dispatch(
        uiActions.showNotification({
          status: "error",
          message: "Error updating Data Type. Please try again later.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  const handleEditTypeChanged = (event) => {
    let dataType = {};
    dataType.name = snakeCase(recallTypeName);
    dataType.display = recallTypeName;
    dataType.icdCodes = icdCodes;
    dataType.description = recallDescription;
    dataType.status = recallStatus;
    mutateDataType({
      id: editTypeId,
      type: "diagnosis-codes",
      data: dataType,
    });
    handleNewRecallClose();
  };

  const handleRecallTypeMutate = (event) => {
    event.preventDefault();
    let dataType = {};
    dataType.name = snakeCase(recallTypeName);
    dataType.display = recallTypeName;
    dataType.icdCodes = icdCodes;
    dataType.description = recallDescription;
    dataType.status = recallStatus;
    if (modalType === "new") {
      mutateNewRecallType(dataType);
    } else {
      handleEditTypeChanged(dataType);
    }
    handleNewRecallClose();
  };

  useEffect(() => {
    if (recallData) {
      setTypes(recallData.items);
      setLoaded(true);
    }
  }, [recallData]);

  const handleEditType = (type) => {
    setModalType("edit");
    setEditTypeId(type.id);
    setRecallTypeOpen(true);
    setRecallTypeName(type.display);
    setRecallDescription(type.description);
    setIcdCodes(type.icdCodes);
    setRecallStatus(type.status);
  };

  return (
    <>
      <Header
        title="Data Types"
        action={
          <Box>
            <Button
              sx={{ my: { xs: 1, lg: 0 } }}
              variant="outlined"
              disableElevation
              startIcon={<AddIcon />}
              onClick={handleNewRecallTypeOpen}
            >
              Add New Diagnosis Type
            </Button>
          </Box>
        }
      />
      <Box display="flex" sx={{ flexDirection: { xs: "column", md: "row" } }}>
        <DataTypesNav />
        <Box flex={1} sx={{ overflowX: "auto" }}>
          {isErrorRecall && (
            <ErrorBlock
              title="An error occured."
              message={errorRecall.info?.message || "Failed to fetch Recall data. Please try again later."}
            />
          )}
          {isErrorNewRecallType && (
            <ErrorBlock
              title="An error occured."
              message={
                errorNewRecallType.info?.message || "Failed to create new Diagnosis Type. Please try again later."
              }
            />
          )}

          <Dialog
            open={recallTypeOpen}
            onClose={handleNewRecallClose}
            PaperProps={{
              component: "form",
              onSubmit: handleRecallTypeMutate,
            }}
            fullWidth
            maxWidth={"xs"}
          >
            <DialogTitle>
              <Typography variant="h4" component="p">
                {modalType === "new" ? "Add" : "Edit"} Diagnosis Type
              </Typography>
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                required
                margin="dense"
                label="Diagnosis Type Name"
                type="text"
                fullWidth
                variant="outlined"
                value={recallTypeName}
                onChange={handleRecallNameChange}
                sx={{ mb: 3 }}
              />

              <TextField
                required
                margin="dense"
                label="Description"
                type="text"
                fullWidth
                variant="outlined"
                value={recallDescription}
                onChange={handleRecallDescriptionChange}
                sx={{ mb: 3 }}
              />

              <Autocomplete
                multiple
                id="tags-filled"
                options={icd10.map((option) => option.code)}
                defaultValue={[]}
                value={icdCodes}
                onChange={handleIcdCodeChange}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)
                }
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="ICD10 Codes" placeholder="" />
                )}
              />

              <FormControl sx={{ mt: 3 }} fullWidth>
                <InputLabel id="recall-type-status">Status</InputLabel>
                <Select
                  label="Status"
                  value={recallStatus}
                  margin="dense"
                  variant="outlined"
                  onChange={handleRecallStatusChange}
                >
                  <MenuItem key="inactive" value="inactive">
                    Inactive
                  </MenuItem>
                  <MenuItem key="active" value="active">
                    Active
                  </MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 3 }}>
              <Button onClick={handleNewRecallClose}>Cancel</Button>
              <Button
                type="submit"
                variant="contained"
                disableElevation
                disabled={isPendingNewRecallType || isPendingUpdateDataType}
              >
                {isPendingNewRecallType || isPendingUpdateDataType ? "Saving..." : "Save"}
              </Button>
            </DialogActions>
          </Dialog>
          {isPendingRecall ? (
            <Loading />
          ) : (
            <Paper
              sx={{
                width: "100%",
                border: "1px solid #f0f0f0",
                borderRadius: 1.5,
                boxShadow: "none",
              }}
            >
              <TableContainer>
                <Table size="small" sx={{ borderRadius: "6px 6px 0 0", overflow: "hidden" }}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Diagnosis Type</StyledTableCell>
                      <StyledTableCell>Description</StyledTableCell>
                      <StyledTableCell sx={{ minWidth: 300 }}>ICD 10 Codes</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loaded && types.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={4}>No Current Diagnosis Types</TableCell>
                      </TableRow>
                    )}
                    {loaded && types.length > 0 && (
                      <>
                        {types.map((type) => (
                          <TableRow key={type.id} hover>
                            <TableCell>{type.display}</TableCell>
                            <TableCell>{type.description}</TableCell>
                            <TableCell>
                              {type.icdCodes.map((code, i) => (
                                <Typography component="span" key={i}>
                                  {code}
                                  {i < type.icdCodes.length - 1 && <>, </>}
                                </Typography>
                              ))}
                            </TableCell>
                            <TableCell>{type.status}</TableCell>
                            <TableCell>
                              <IconButton
                                size="small"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  handleEditType(type);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
        </Box>
      </Box>
    </>
  );
};

export default DataTypeDiagnosisCodes;
