import * as React from 'react'
import { ChangeEvent, useMemo, useState } from 'react'
import classes from './DataUpload.module.scss'
import {
  uploadMedia,
  uploadSessionStart,
  uploadSessionNewFile,
  uploadSessionStage,
  uploadSessionStatus,
  uploadSessionFileDelete
} from './services.ts'
import { Loader } from './Loader.tsx'
import { Box, Button, FormHelperText } from '@mui/material'
import { useParams } from 'react-router-dom'

type FileTypes = 'file'
interface FileUpload {
  file: File | null
  uploadProgress?: string
  transformProgress?: string
  fileType: FileTypes
  uploadSessionFileId: number
  status: 'initial' | 'done' | 'error' | 'uploading' | 'uploaded'
}

function DataUpload () {
  const params = useParams()
  const [uploadSession, setUploadSession] = useState<number | null>(null)
  const [files, setFiles] = useState<FileUpload[]>([
    {
      file: null,
      uploadProgress: '',
      transformProgress: '',
      fileType: 'file',
      status: 'initial',
      uploadSessionFileId: -1
    }
  ])

  const [isTranforming, setIsTranforming] = useState(false)
  const [eventResult, setEventResult] = useState({
    error: false,
    message: ''
  })

  const practiceId = params.uuid as string
  const fileChangedHandler = async (
    e: ChangeEvent<HTMLInputElement>,
    fileType: FileTypes
  ) => {
    e.preventDefault()
    if (e?.target?.files?.length) {
      let uploadSessionId = uploadSession
      const file = e?.target?.files[0]

      if (uploadSessionId == null) {
        const { data } = await uploadSessionStart(practiceId)
        uploadSessionId = data.id
        setUploadSession(uploadSessionId)
      }

      const { data: newFileData } = await uploadSessionNewFile(
        uploadSessionId,
        file.name
      )
      setFiles(fs =>
        fs.map(f =>
          f.fileType === fileType
            ? { ...f, uploadSessionFileId: newFileData.file.id }
            : f
        )
      )

      try {
        await uploadMedia(
          { url: newFileData.uploadUrl, file: file, contentType: file.type },
          ({ progress }) => {
            setFiles(fs =>
              fs.map(f =>
                f.fileType === fileType
                  ? {
                      ...f,
                      uploadProgress: (Number(progress) * 100).toFixed(0),
                      file,
                      status: 'uploading'
                    }
                  : f
              )
            )
          }
        )
        setFiles(fs =>
          fs.map(f =>
            f.fileType === fileType
              ? { ...f, uploadProgress: '100', file, status: 'uploaded' }
              : f
          )
        )
        setEventResult({
          error: false,
          message: `File was successfully staged`
        })
      } catch (e: any) {
        setEventResult({ error: true, message: e.message })
      }
    }
  }

  const remove = async (fileType: FileTypes) => {
    const file = files.find(f => f.fileType === fileType)
    if (
      file &&
      file.uploadSessionFileId > 0 &&
      file.status !== 'done' &&
      file.status !== 'error'
    ) {
      try {
        await uploadSessionFileDelete(file.uploadSessionFileId)
      } catch (e: any) {
        setEventResult({ error: true, message: e.message })
        return
      }
    }
    setFiles(fs =>
      fs.map(f =>
        f.fileType === fileType
          ? {
              ...f,
              uploadProgress: '',
              file: null,
              uploadSessionFileId: -1,
              transformProgress: ''
            }
          : f
      )
    )
  }

  const transformFiles = async () => {
    if (uploadSession == null) {
      setEventResult({
        error: true,
        message: "Can't find started UploadSession"
      })
      return
    }
    setIsTranforming(true)
    try {
      await uploadSessionStage(uploadSession)
    } catch (e: any) {
      setEventResult({
        error: true,
        message: e.response?.data?.message || e.message
      })
      setIsTranforming(false)
    }

    const stateTicker = setInterval(async () => {
      const { data: state } = await uploadSessionStatus(uploadSession)
      if (
        state.state === 'completed' ||
        state.state === 'processing_failed' ||
        state.state === 'done' ||
        state.state === 'mismatch'
      ) {
        clearInterval(stateTicker)
        setEventResult({
          error: state.state !== 'completed',
          message: `Job result: ${state.state}`
        })
        setIsTranforming(false)
        setUploadSession(null)
        setFiles(fs =>
          fs.map(f => ({
            ...f,
            status: state.state === 'completed' ? 'done' : 'error'
          }))
        )
        return
      }
      state.files.forEach(newFileState => {
        setFiles(files =>
          files.map(file =>
            file.uploadSessionFileId === newFileState.id
              ? {
                  ...file,
                  transformProgress: newFileState.state
                }
              : file
          )
        )
      })
      setEventResult({ error: false, message: `Job state: ${state.state}` })
      if (state.state === 'done' || state.state === 'mismatch') {
        setIsTranforming(false)
      }
    }, 1000)
  }

  const canTransform = useMemo(() => {
    return !!files.find(f => f.status === 'uploaded')
  }, [files])

  return (
    <Box>
      <ul className={classes.files}>
        {files.map(f => (
          <li key={f.fileType}>
            {f.file ? (
              <>
                <div className={classes['file-upload-info']}>
                  {f.file.name} {!!f.uploadProgress && `(${f.uploadProgress}%)`}
                  <div
                    className={classes[`file-upload-progress ${f.status}`]}
                    style={{ width: `${f.uploadProgress}%` }}
                  ></div>
                </div>
                <Button
                  disabled={isTranforming || !practiceId}
                  color='primary'
                  className='Remove-button'
                  onClick={() => remove(f.fileType)}
                >
                  Remove
                </Button>
                {f.status === 'uploading' && <Loader />}
                <FormHelperText>{f.transformProgress}</FormHelperText>
              </>
            ) : (
              <Button
                variant='outlined'
                disabled={!practiceId || isTranforming}
                color='primary'
                className='upload-button'
              >
                Select {f.fileType}
                <input
                  type='file'
                  disabled={!practiceId || isTranforming}
                  accept='application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .csv'
                  onClick={e => {
                    ;(e.target as HTMLInputElement).value = ''
                  }}
                  onChange={e => fileChangedHandler(e, f.fileType)}
                  className={classes['upload-file']}
                />
              </Button>
            )}
          </li>
        ))}
      </ul>
      <div className='transform-container'>
        <Button
          variant='contained'
          disableElevation
          disabled={!canTransform || isTranforming || !practiceId}
          color='primary'
          onClick={transformFiles}
        >
          Upload
        </Button>
        {isTranforming && <Loader className='loader' />}
      </div>
      <FormHelperText error={eventResult.error}>
        {eventResult.message}
      </FormHelperText>
    </Box>
  )
}

export default DataUpload
