import React from "react";
import { Link, useParams } from "react-router-dom";
import AutomatedCampaigns from "../../components/AutomatedCampaigns/AutomatedCampaigns";
import Header from "../../components/Header";
import { ReactComponent as IconSettings } from "../../assets/icon-settings-white.svg";
import { Button } from "@mui/material";
const Campaigns = () => {
  const params = useParams();
  return (
    <>
      <Header
        title="Campaigns"
        subtitle="Turn on automated email campaigns to remind your patients to book an appointment."
        action={
          <Link to={`/practices/${params.uuid}/mappings`}>
            <Button variant="contained" disableElevation startIcon={<IconSettings />}>
              Update Settings
            </Button>
          </Link>
        }
      />
      <AutomatedCampaigns />
    </>
  );
};

export default Campaigns;
