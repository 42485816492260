import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { ResponsiveChartContainer } from "@mui/x-charts/ResponsiveChartContainer";
import { BarPlot } from "@mui/x-charts/BarChart";
import { LineHighlightPlot, LinePlot, MarkPlot } from "@mui/x-charts/LineChart";
import { ChartsXAxis } from "@mui/x-charts/ChartsXAxis";
import { ChartsAxisHighlight, ChartsGrid, ChartsLegend, ChartsTooltip, ChartsYAxis } from "@mui/x-charts";
import { Card } from "@mui/material";
import ChartHeader from "./ChartHeader";
import EmptyState from "./EmptyState";
import { chartDataPrep } from "../../utils/insights/chartDataPrep";

export default function BarChart({
  metricData,
  type,
  metric,
  priorColor,
  currentColor,
  heading,
  subHeading = "",
  icon,
  formatter,
  margin = { left: 50, top: 60 },
}) {
  const [axisData, setAxisData] = useState([]);
  const [seriesData, setSeriesData] = useState([]);

  useEffect(() => {
    if (metricData?.data?.length) {
      const result = chartDataPrep(formatter, metricData.data, metric, metricData.timetable, priorColor, currentColor);

      if (result) {
        setAxisData(result.axisData);
        setSeriesData(result.seriesData);
      }
    }
  }, [metricData, metric, priorColor, currentColor, formatter]);

  return (
    <>
      {type === "Charts" && (
        <Card variant="outlined" sx={{ borderRadius: "18px" }}>
          <ChartHeader heading={heading} subHeading={subHeading} icon={icon} />
          <Paper sx={{ width: "100%", height: 400, paddingLeft: 3, paddingTop: 3 }} elevation={0}>
            {axisData.length && seriesData.length ? (
              <ResponsiveChartContainer
                margin={margin}
                series={seriesData}
                xAxis={[
                  {
                    data: axisData,
                    scaleType: "band",
                  },
                ]}
                yAxis={[{ valueFormatter: formatter }]}
              >
                <ChartsGrid horizontal />
                <BarPlot borderRadius={2} />
                <LinePlot />
                <MarkPlot />
                <LineHighlightPlot />
                <ChartsTooltip trigger="axis" />
                <ChartsYAxis />
                <ChartsXAxis position="bottom" />
                <ChartsLegend />
                <ChartsAxisHighlight x="band" />
              </ResponsiveChartContainer>
            ) : (
              <EmptyState />
            )}
          </Paper>
        </Card>
      )}
    </>
  );
}
