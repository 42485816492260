import { coreAppClient } from '../coreAppClient';

const buildBaseUrl = (practiceUuid, locationUuid) => `/practices/${practiceUuid}/locations/${locationUuid}/campaigns`;

export const fetchLocationCampaigns = async ({ practiceUuid, locationUuid, signal }) => {
  const response = await coreAppClient.get(buildBaseUrl(practiceUuid, locationUuid), { signal });
  return response.data;
};

export const updateLocationCampaign = async ({ practiceUuid, locationUuid, uuid, sgMarketingCampaignId }) => {
  const response = await coreAppClient.patch(
    `${buildBaseUrl(practiceUuid, locationUuid)}/${uuid}`,
    { sgMarketingCampaignId },
  );
  return response.data;
};
