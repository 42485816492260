import {
  Button,
  Dialog,
  Drawer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import BonsaiSwitch from "../BonsaiSwitch/BonsaiSwitch";
import classes from "./AutomatedCampaigns.module.css";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchBonsaiCampaigns } from "../../utils/api/bonsai-campaigns.api";
import { ReactComponent as IconInfo } from "../../assets/icon-info.svg";
import { ReactComponent as IconEmail } from "../../assets/icon-envelope.svg";
import { ReactComponent as IconSMS } from "../../assets/icon-sms.svg";
import {
  QUERY_KEY_BONSAI_CAMPAIGNS,
  QUERY_KEY_PRACTICE,
  QUERY_KEY_PRACTICE_CAMPAIGNS,
  QUERY_KEY_RECALL_CAMPAIGN_OPPORTUNITIES,
} from "../../store/constants";
import {
  fetchPracticeCampaigns,
  createPracticeCampaign,
  updatePracticeCampaign,
} from "../../utils/api/practice-campaigns.api";
import { uiActions } from "../../store/ui-slice";
import { queryClient } from "../../utils/http";
import NeuromodulatorDrawer from "../Drawers/NeuromodulatorDrawer";
import FillerDrawer from "../Drawers/FillerDrawer";
import SkinScreeningDrawer from "../Drawers/SkinScreeningDrawer";
import NeuromodulatorEmailPreview from "./NeuromodulatorEmailPreview";
import NeuromodulatorSmsPreview from "./NeuromodulatorSmsPreview";
import FillerEmailPreview from "./FillerEmailPreview";
import FillerSmsPreview from "./FillerSmsPreview";
import SkinScreeningEmailPreview from "./SkinScreeningEmailPreview";
import { fetchPractice } from "../../utils/api/practices.api";
import { fetchPracticeCampaignOpportunities } from "../../utils/api/practice-campaign-opportunities.api";
import StyledTableRow from "../StyledTableRow";
import StyledTableCell from "../StyledTableCell";
import SkinScreeningSmsPreview from "./SkinScreeningSmsPreview";

const AutomatedCampaigns = () => {
  const { uuid: practiceUuid } = useParams();
  const dispatch = useDispatch();

  /** Neuromodulator Email Preview */
  const [emailNeuromodulatorPreviewOpen, setEmailNeuromodulatorPreviewOpen] = useState(false);
  const handleNeuromodulatorPreviewEmailOpen = () => {
    setEmailNeuromodulatorPreviewOpen(true);
  };
  const handleNeuromodulatorPreviewEmailClose = () => {
    setEmailNeuromodulatorPreviewOpen(false);
  };

  /** Filler Email Preview */
  const [emailFillerPreviewOpen, setEmailFillerPreviewOpen] = useState(false);
  const handleFillerPreviewEmailOpen = () => {
    setEmailFillerPreviewOpen(true);
  };
  const handleFillerPreviewEmailClose = () => {
    setEmailFillerPreviewOpen(false);
  };

  /** Skin Screening Email Preview */
  const [emailSkinScreeningPreviewOpen, setEmailSkinScreeningPreviewOpen] = useState(false);
  const handleSkinScreeningPreviewEmailOpen = () => {
    setEmailSkinScreeningPreviewOpen(true);
  };
  const handleSkinScreeningPreviewEmailClose = () => {
    setEmailSkinScreeningPreviewOpen(false);
  };

  /** Neuromodulator SMS Preview */
  const [smsNeuromodulatorPreviewOpen, setSmsNeuromodulatorPreviewOpen] = useState(false);
  const handleNeuromodulatorPreviewSmsOpen = () => {
    setSmsNeuromodulatorPreviewOpen(true);
  };
  const handleNeuromodulatorPreviewSmsClose = () => {
    setSmsNeuromodulatorPreviewOpen(false);
  };

  /** Filler SMS Preview */
  const [smsFillerPreviewOpen, setSmsFillerPreviewOpen] = useState(false);
  const handleFillerPreviewSmsOpen = () => {
    setSmsFillerPreviewOpen(true);
  };
  const handleFillerPreviewSmsClose = () => {
    setSmsFillerPreviewOpen(false);
  };

  /** Skin Screening SMS Preview */
  const [smsSkinScreeningPreviewOpen, setSmsSkinScreeningPreviewOpen] = useState(false);
  const handleSkinScreeningPreviewSmsOpen = () => {
    setSmsSkinScreeningPreviewOpen(true);
  };
  const handleSkinScreeningPreviewSmsClose = () => {
    setSmsSkinScreeningPreviewOpen(false);
  };

  /** Fetch Bonsai Campaign Options **/
  const { data: bonsaiCampaigns, isPending: isBonsaiCampaignsLoading } = useQuery({
    queryKey: [QUERY_KEY_BONSAI_CAMPAIGNS],
    queryFn: ({ signal }) => fetchBonsaiCampaigns({ signal }),
  });

  /** Fetch Practice Campaigns **/
  const { data: practiceCampaigns, isPending: isPracticeCampaignsLoading } = useQuery({
    queryKey: [QUERY_KEY_PRACTICE_CAMPAIGNS],
    queryFn: ({ signal }) => fetchPracticeCampaigns({ practiceUuid, signal }),
  });

  /** Fetch Practice Details */
  const params = useParams();
  const uuid = params.uuid;

  const { data: practiceData, isPending: isPracticeDataLoading } = useQuery({
    queryKey: [QUERY_KEY_PRACTICE, practiceUuid],
    queryFn: async ({ signal }) => {
      const [practice] = await Promise.all([fetchPractice({ uuid, signal })]);
      return { practice };
    },
  });

  /** Fetch Practice Campaign Opportunities **/
  const { data: practiceCampaignOpportunities, isPending: isPracticeCampaignOpportunitiesLoading } = useQuery({
    queryKey: [QUERY_KEY_RECALL_CAMPAIGN_OPPORTUNITIES, practiceUuid],
    queryFn: ({ signal }) => fetchPracticeCampaignOpportunities({ practiceUuid, signal }),
  });

  /** Map bonsai campaigns to practice campaign status **/
  const [practiceCampaignMap, setPracticeCampaignMap] = useState();
  useEffect(() => {
    if (
      isBonsaiCampaignsLoading ||
      isPracticeDataLoading ||
      isPracticeCampaignOpportunitiesLoading ||
      isPracticeCampaignsLoading ||
      !bonsaiCampaigns?.length
    ) {
      return;
    }
    const baseMap = bonsaiCampaigns.reduce(
      (bonsaiCampaignIdMap, bonsaiCampaign) => ({
        ...bonsaiCampaignIdMap,
        [bonsaiCampaign.uuid]: { uuid: null, isEnabled: false, bonsaiCampaignUuid: bonsaiCampaign.uuid },
      }),
      {}
    );
    practiceCampaigns.forEach((campaign) => {
      if (baseMap[campaign.bonsaiCampaignUuid]) {
        baseMap[campaign.bonsaiCampaignUuid] = campaign;
      }
    });

    setPracticeCampaignMap(baseMap);
  }, [
    isBonsaiCampaignsLoading,
    bonsaiCampaigns,
    practiceCampaigns,
    isPracticeCampaignsLoading,
    isPracticeDataLoading,
    practiceData,
    isPracticeCampaignOpportunitiesLoading,
    setPracticeCampaignMap,
  ]);

  /** Enable / Disable Practice Campaigns **/
  const { mutate: saveCampaign } = useMutation({
    mutationFn: ({ campaign, isEnabled }) => {
      const practiceCampaign = {
        ...campaign,
        isEnabled,
      };
      if (!campaign.uuid) {
        return createPracticeCampaign({ practiceUuid, practiceCampaign });
      } else {
        return updatePracticeCampaign({ practiceUuid, practiceCampaign });
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_PRACTICE_CAMPAIGNS],
      });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Practice campaign saved",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
    onError: () => {
      dispatch(
        uiActions.showNotification({
          status: "error",
          message: "Error saving practice campaign",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  const CAMPAIGN_COPY_MAP = {
    NEUROMODULATORS: {
      id: "neuromodulator",
      title: "Neuromodulator",
      heading: "Appointment Booking Reminder for Neuromodulator",
      description:
        "Enable automatic reminders for clients overdue for Neuromodulator appointments. These reminders emphasize the need for regular Neuromodulator treatments, encouraging clients to conveniently schedule their appointments.",
      drawer: "neuromodulator",
      emailPreview: handleNeuromodulatorPreviewEmailOpen,
      smsPreview: handleNeuromodulatorPreviewSmsOpen,
    },
    SKIN_SCREENING: {
      id: "skin-screening",
      title: "Skin Screening",
      heading: "Appointment Booking Reminder for Skin Screening",
      description:
        "Enable automatic reminders for patients overdue for their annual skin screening. These reminders highlight the benefits of regular screenings, prompting patients to conveniently schedule their appointments.",
      drawer: "skinScreening",
      emailPreview: handleSkinScreeningPreviewEmailOpen,
      smsPreview: handleSkinScreeningPreviewSmsOpen,
    },
    FILLERS: {
      id: "filler",
      title: "Filler",
      heading: "Appointment Booking Reminder for Filler",
      description:
        "Enable automatic reminders for clients overdue for Filler appointments. These reminders emphasize the need for regular Filler treatments, encouraging clients to conveniently schedule their appointments.",
      drawer: "filler",
      emailPreview: handleFillerPreviewEmailOpen,
      smsPreview: handleFillerPreviewSmsOpen,
    },
  };

  const [state, setState] = React.useState({
    neuromodulator: false,
    filler: false,
    skinScreening: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <>
      <Box sx={{ mb: 6 }}>
        {practiceCampaignMap && practiceData && practiceCampaignOpportunities && (
          <TableContainer component={Paper} sx={{ boxShadow: "none", borderRadius: "8px 8px 0 0" }}>
            <Table sx={{ minWidth: 1200 }}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Campaign</StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell sx={{ textAlign: "center" }}>Patients Overdue</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: "center" }}>Average Revenue / Visit</StyledTableCell>
                  <StyledTableCell
                    sx={{
                      textAlign: "center",
                      color: "#6F35F2 !important",
                      fontWeight: 600,
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#D9D5FF",
                        borderRadius: "8px 8px 0 0",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        zIndex: 0,
                      }}
                    ></Box>
                    <Typography
                      component="span"
                      sx={{ zIndex: 1, position: "relative", fontSize: "0.875rem", fontWeight: 600 }}
                    >
                      Estimated Revenue
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell sx={{ textAlign: "center" }}>Templates</StyledTableCell>
                  <StyledTableCell
                    sx={{
                      textAlign: "center",
                      color: "#067647 !important",
                      fontWeight: 600,
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#ABEFC6",
                        borderRadius: "8px 8px 0 0",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        zIndex: 0,
                      }}
                    ></Box>
                    <Typography
                      component="span"
                      sx={{ zIndex: 1, position: "relative", fontSize: "0.875rem", fontWeight: 600 }}
                    >
                      Activate
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {bonsaiCampaigns.map((campaign) => {
                  if (
                    campaign.type === "SKIN_SCREENING" &&
                    practiceData.practice.systemOfRecord === "aesthetic_record"
                  ) {
                    return null;
                  }
                  let currentOpportunity = "";
                  let appointmentEstimatedRevenue = 0;
                  practiceCampaignOpportunities?.items.forEach((opportunity) => {
                    if (campaign.type === opportunity.campaignType) {
                      currentOpportunity = opportunity;
                      appointmentEstimatedRevenue = opportunity.appointmentEstimatedRevenue || 0;
                    }
                  });
                  return (
                    <TableRow className={classes.dataRow} key={campaign.uuid}>
                      <TableCell>{CAMPAIGN_COPY_MAP[campaign.type].title}</TableCell>
                      <TableCell align="center">
                        <Button
                          disableElevation
                          sx={{ width: 20, height: 20, minWidth: 20, padding: 0, borderRadius: 10 }}
                          onClick={toggleDrawer(CAMPAIGN_COPY_MAP[campaign.type].drawer, true)}
                        >
                          <IconInfo />
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        {currentOpportunity.patientsCount
                          ? Intl.NumberFormat("en-US").format(currentOpportunity.patientsCount)
                          : 0}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {currentOpportunity ? (
                          <>
                            $
                            {appointmentEstimatedRevenue
                              .toFixed(0)
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                          </>
                        ) : (
                          "$0"
                        )}
                      </TableCell>
                      <TableCell align="center" className={classes.revCell}>
                        {currentOpportunity ? (
                          <>
                            $
                            {(currentOpportunity.patientsCount * appointmentEstimatedRevenue)
                              .toFixed(0)
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                          </>
                        ) : (
                          "$0"
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <Button className={classes.campaignButton}>
                          <IconEmail onClick={CAMPAIGN_COPY_MAP[campaign.type].emailPreview} />
                        </Button>
                        <Button className={classes.campaignButton}>
                          <IconSMS onClick={CAMPAIGN_COPY_MAP[campaign.type].smsPreview} />
                        </Button>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={practiceCampaignMap[campaign.uuid].isEnabled ? classes.activeCell : null}
                      >
                        <BonsaiSwitch
                          color="success"
                          checked={practiceCampaignMap[campaign.uuid].isEnabled}
                          onChange={($event) =>
                            saveCampaign({
                              campaign: practiceCampaignMap[campaign.uuid],
                              isEnabled: $event.target.checked,
                            })
                          }
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <Drawer anchor="right" open={state["neuromodulator"]} onClose={toggleDrawer("neuromodulator", false)}>
        <NeuromodulatorDrawer onClick={toggleDrawer("neuromodulator", false)} />
      </Drawer>
      <Drawer anchor="right" open={state["filler"]} onClose={toggleDrawer("filler", false)}>
        <FillerDrawer onClick={toggleDrawer("filler", false)} />
      </Drawer>
      <Drawer anchor="right" open={state["skinScreening"]} onClose={toggleDrawer("skinScreening", false)}>
        <SkinScreeningDrawer onClick={toggleDrawer("skinScreening", false)} />
      </Drawer>
      <Dialog open={emailNeuromodulatorPreviewOpen} onClose={handleNeuromodulatorPreviewEmailClose}>
        <NeuromodulatorEmailPreview />
      </Dialog>
      <Dialog open={emailFillerPreviewOpen} onClose={handleFillerPreviewEmailClose}>
        <FillerEmailPreview />
      </Dialog>
      <Dialog open={emailSkinScreeningPreviewOpen} onClose={handleSkinScreeningPreviewEmailClose}>
        <SkinScreeningEmailPreview />
      </Dialog>
      <Dialog open={smsNeuromodulatorPreviewOpen} onClose={handleNeuromodulatorPreviewSmsClose}>
        <NeuromodulatorSmsPreview />
      </Dialog>
      <Dialog open={smsFillerPreviewOpen} onClose={handleFillerPreviewSmsClose}>
        <FillerSmsPreview />
      </Dialog>
      <Dialog open={smsSkinScreeningPreviewOpen} onClose={handleSkinScreeningPreviewSmsClose}>
        <SkinScreeningSmsPreview />
      </Dialog>
    </>
  );
};

export default AutomatedCampaigns;
