import { coreAppClient } from "../coreAppClient";

const buildBaseUrl = (practiceUuid) => `/practices/${practiceUuid}/locations`;

export async function fetchPracticeLocations({ uuid, signal }) {
  try {
    const apiEndpoint = buildBaseUrl(uuid);
    const locationsResponse = await coreAppClient.get(`${apiEndpoint}`, { signal });
    return locationsResponse.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchLocationPossibleValues({ uuid, signal }){
  try {
    const apiEndpoint = buildBaseUrl(uuid);
    const valuesResponse = await coreAppClient.get(`${apiEndpoint}/possible-values`, { signal });
    return valuesResponse.data;
  } catch (error) {
    return Promise.reject(error);
  }
}