import * as React from "react";
import Stack from "@mui/material/Stack";
import { LineChart } from "@mui/x-charts/LineChart";

const emptySeries = {
  series: [],
  margin: { top: 10, right: 10, left: 10, bottom: 10 },
};

export default function EmptyState() {
  return (
    <Stack sx={{ width: "100%", height: "100%" }}>
      <LineChart {...emptySeries} />
    </Stack>
  );
}
