import { Box, Button, Container, Grid, Stack } from "@mui/material";
import { useState } from "react";
import React from "react";
import Header from "../components/Header";
import TextInput from "../components/TextInput";
import { json, useLoaderData, useNavigation } from "react-router-dom";

import SelectInput from "../components/SelectInput";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { uiActions } from "../store/ui-slice";
import { useMutation } from "@tanstack/react-query";
import { createPractice } from "../utils/api/practices.api";
import { queryClient } from "../utils/http";
import { QUERY_KEY_PRACTICE } from "../store/constants";
import useTimezones from "../utils/hooks/useTimezones";

const { REACT_APP_API_ENDPOINT } = process.env;

const PracticeAdd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";
  const values = useLoaderData();

  const [selectData, setSelectData] = useState({
    businessLines: [],
    status: "",
    systemOfRecord: "",
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  const handleSelectDataChange = (e) => {
    const value = e.target.value;
    setSelectData({
      ...selectData,
      [e.target.name]: value,
    });
  };

  const returnHandler = () => {
    navigate("/practices");
  };

  /** Timezone **/
  const { timezoneOptions } = useTimezones();

  /** Create Practice **/
  const { mutate: savePractice } = useMutation({
    mutationFn: (event) => {
      event.preventDefault();
      const data = new FormData(event.target);
      const practiceData = {
        name: data.get("name"),
        website: data.get("website"),
        status: data.get("status"),
        systemOfRecord: data.get("systemOfRecord"),
        businessLines: data.get("businessLines").split(","),
        timezone: data.get("timezone") || selectData.timezone,
      };
      return createPractice({ practice: practiceData });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_PRACTICE],
      });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Practice created",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
      returnHandler();
    },
    onError: () => {
      dispatch(
        uiActions.showNotification({
          status: "error",
          message: "Error creating practice",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });
  return (
    <>
      <Header title="Add New Practice" />
      <Container sx={{ py: 3, m: 0 }}>
        <form onSubmit={savePractice}>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              <TextInput required label="Practice Name" name="name" />
              <TextInput required label="Website" name="website" />

              <SelectInput
                required
                label="Status"
                name="status"
                value={selectData.status}
                items={values.status}
                onChange={handleSelectDataChange}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <SelectInput
                required
                label="System of Record"
                name="systemOfRecord"
                value={selectData.systemOfRecord}
                items={values.system_of_record}
                onChange={handleSelectDataChange}
              />
              <SelectInput
                required
                label="Business line(s)"
                name="businessLines"
                value={selectData.businessLines}
                items={values.business_lines}
                onChange={handleSelectDataChange}
                multiple
              />
              {!!timezoneOptions.length && (
                <SelectInput
                  required
                  label="Timezone"
                  name="timezone"
                  value={selectData.timezone}
                  items={timezoneOptions}
                  onChange={handleSelectDataChange}
                />
              )}
            </Grid>
          </Grid>
          <Box>
            <Stack direction="row" spacing={2} py={3}>
              <Button variant="outlined" onClick={returnHandler}>
                Cancel
              </Button>

              <Button type="submit" variant="contained" disableElevation disabled={isSubmitting}>
                {isSubmitting ? "Saving..." : "Save"}
              </Button>
            </Stack>
          </Box>
        </form>
      </Container>
    </>
  );
};

export default PracticeAdd;

export async function practiceAddLoader() {
  const response = await fetch(`${REACT_APP_API_ENDPOINT}/practices/possible-values`);

  if (!response.ok) {
    throw json({ message: "Could not fetch practices" }, { status: 500 });
  } else {
    return response;
  }
}
