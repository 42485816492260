import { ReactComponent as IconCampaigns } from "../assets/icon-campaigns.svg";
import { ReactComponent as IconCampaignComposer } from "../assets/icon-campaign-composer.svg";
import { ReactComponent as IconUploader } from "../assets/icon-uploader.svg";
import { ReactComponent as IconPracticeDetails } from "../assets/icon-practice-details.svg";
import { ReactComponent as IconProviders } from "../assets/icon-providers.svg";
import { ReactComponent as IconLocations } from "../assets/icon-locations.svg";
import { ReactComponent as IconMappings } from "../assets/icon-mappings.svg";
import { ReactComponent as IconResults } from "../assets/icon-results.svg";
import { ReactComponent as IconGear } from "../assets/icon-gear.svg";

export const menuItems = [
  {
    label: "Automations",
    items: [
      {
        title: "Campaigns",
        url: "campaigns",
        icon: <IconCampaigns />,
      },
      {
        title: "Results",
        url: "results",
        icon: <IconResults />,
      },
    ],
  },
  {
    label: "Data",
    items: [{ title: "Uploader", url: "data-uploader", icon: <IconUploader /> }],
  },
  {
    label: "Settings",
    items: [
      {
        title: "Practice Details",
        url: "details",
        icon: <IconPracticeDetails />,
      },
      {
        title: "Providers",
        url: "providers",
        icon: <IconProviders />,
      },
      {
        title: "Locations",
        url: "locations",
        icon: <IconLocations />,
      },
      {
        title: "Campaign Settings",
        url: "campaign-settings",
        icon: <IconCampaignComposer />,
      },
      {
        title: "Integrations",
        url: "practice-integrations",
        icon: <IconGear />,
      },
      {
        title: "Mappings",
        url: "mappings",
        icon: <IconMappings />,
        children: [
          {
            title: "Appointment Type",
            url: "appointment-type",
          },
          {
            title: "Appointment Status",
            url: "appointment-status",
          },
          {
            title: "Location",
            url: "location",
          },
          {
            title: "Provider",
            url: "provider",
          },
          {
            title: "Procedure",
            url: "procedure",
          },
          {
            title: "Policy",
            url: "policy",
          },
          {
            title: "Retail",
            url: "retail",
          },
          {
            title: "Tender",
            url: "tender",
          },
          {
            title: "Diagnosis Codes",
            url: "diagnosis-codes",
          },
        ],
      },
    ],
  },
];
