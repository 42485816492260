import React from "react";
import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";

const StyledRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const StyledTableRow = (props) => {
    return <StyledRow sx={{ ...props.sx }}>{props.children}</StyledRow>;
};

export default StyledTableRow;
