import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import TableCell from "@mui/material/TableCell";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createNewProcedureCategory,
  createNewProcedureType,
  fetchProcedureData,
  queryClient,
  updateDataType,
  updateProcedureType,
} from "../utils/http";
import { snakeCase } from "lodash";
import { useDispatch } from "react-redux";
import { uiActions } from "../store/ui-slice";
import Loading from "../components/Loading";
import Header from "../components/Header";
import ErrorBlock from "../components/UI/ErrorBlock";
import StyledTableCell from "../components/StyledTableCell";
import DataTypesNav from "../components/DataTypesNav";

export default function DataTypeProcedure() {
  const dispatch = useDispatch();

  const [newProcedureType, setNewProcedureType] = useState("");
  const [newProcedureCategory, setNewProcedureCategory] = useState("");

  const [types, setTypes] = useState([]);
  const [categories, setCategories] = useState([]);

  const [newCategoryOpen, setNewCategoryOpen] = useState(false);
  const [newProcedureOpen, setNewProcedureOpen] = useState(false);

  const [editTypeOpen, setEditTypeOpen] = useState(false);
  const [editTypeName, setEditTypeName] = useState("");
  const [editTypeId, setEditTypeId] = useState("");
  const [editTypeCategoryId, setEditTypeCategoryId] = useState("");

  const [loaded, setLoaded] = useState(false);

  const {
    data: proceduresData,
    isPending: isPendingProcedures,
    isError: isErrorProcedures,
    error: errorProcedures,
  } = useQuery({
    queryKey: ["procedure-types"],
    queryFn: ({ signal, queryKey }) => fetchProcedureData({ signal, ...queryKey }),
  });

  const {
    mutate: mutateNewProcedureType,
    isPending: isPendingNewProcedureType,
    isError: isErrorNewProcedureType,
    error: errorNewProcedureType,
  } = useMutation({
    mutationFn: createNewProcedureType,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["procedure-types"] });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "New Procedure Type created.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  const {
    mutate: mutateNewProcedureCategory,
    isPending: isPendingNewCategory,
    isError: isErrorNewCategory,
    error: errorNewProcedureCategory,
  } = useMutation({
    mutationFn: createNewProcedureCategory,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["procedure-types"] });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "New Procedure Category created.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  const { mutate: mutateUpdateCategory } = useMutation({
    mutationFn: updateProcedureType,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["procedure-types"] });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Procedure Category updated.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: ["procedure-types"] });
      dispatch(
        uiActions.showNotification({
          status: "error",
          message: "Error updating category type. Please try again later.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  useEffect(() => {
    if (proceduresData) {
      setTypes(proceduresData.types.items);
      setCategories(proceduresData.categories.items);
      setLoaded(true);
    }
  }, [proceduresData]);

  const handleNewProcedureClose = () => {
    setNewProcedureOpen(false);
    setNewProcedureType("");
    setNewProcedureCategory("");
  };

  const handleNewProcedureClickOpen = () => {
    setNewProcedureOpen(true);
  };

  const handleNewProcedureChange = (event) => {
    setNewProcedureType(event.target.value);
  };

  const handleNewProcedureCategory = (event) => {
    setNewProcedureCategory(event.target.value);
  };
  const handleNewProcedureTypeAdd = (event) => {
    event.preventDefault();
    let newType = {};
    newType.name = snakeCase(newProcedureType);
    newType.display = newProcedureType;
    newType.categoryId = newProcedureCategory;
    mutateNewProcedureType(newType);
    handleNewProcedureClose();
  };

  const handleCategoryChange = (selectedOption, id, display) => {
    let updatedType = {};
    updatedType.display = display;
    updatedType.categoryId = selectedOption.target.value;
    mutateUpdateCategory({ id: id, data: updatedType });
    selectedOption.defaultValue = selectedOption.target.value;
  };

  const handleNewCategoryClose = () => {
    setNewCategoryOpen(false);
    setNewProcedureCategory("");
  };

  const handleNewCategoryChange = (event) => {
    setNewProcedureCategory(event.target.value);
  };

  const handleNewCategoryClickOpen = () => {
    setNewCategoryOpen(true);
  };

  const handleNewCategoryAdd = (event) => {
    event.preventDefault();

    let newCat = {};
    newCat.name = snakeCase(newProcedureCategory);
    newCat.display = newProcedureCategory;
    mutateNewProcedureCategory(newCat);
    handleNewCategoryClose();
  };

  const { mutate: mutateDataType, isPending: isPendingUpdateDataType } = useMutation({
    mutationFn: updateDataType,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["procedure-types"] });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Data Type updated.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: ["procedure-types"] });
      dispatch(
        uiActions.showNotification({
          status: "error",
          message: "Error updating Data Type. Please try again later.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  const handleEditTypeChange = (event) => {
    setEditTypeName(event.target.value);
  };

  const handleEditTypeChanged = (event) => {
    event.preventDefault();
    let dataType = {};
    dataType.name = snakeCase(editTypeName);
    dataType.display = editTypeName;
    dataType.categoryId = editTypeCategoryId;
    mutateDataType({ id: editTypeId, type: "procedures/types", data: dataType });
    handleEditTypeClose();
  };

  const handleEditType = (id, display, categoryId) => {
    setEditTypeOpen(true);
    setEditTypeId(id);
    setEditTypeName(display);
    setEditTypeCategoryId(categoryId);
  };

  const handleEditTypeClose = () => {
    setEditTypeOpen(false);
    setEditTypeId("");
    setEditTypeName("");
    setEditTypeCategoryId("");
  };

  return (
    <>
      <Header
        title="Data Types"
        action={
          <Box>
            <Button
              sx={{ mr: 1 }}
              variant="outlined"
              disableElevation
              startIcon={<AddIcon />}
              onClick={handleNewProcedureClickOpen}
            >
              Add New Procedure Type
            </Button>
            <Button
              variant="outlined"
              disableElevation
              startIcon={<AddIcon />}
              sx={{ mr: 1, my: { xs: 1, lg: 0 } }}
              onClick={handleNewCategoryClickOpen}
            >
              Add Procedure Category
            </Button>
          </Box>
        }
      />
      <Box display="flex" sx={{ flexDirection: { xs: "column", md: "row" } }}>
        <DataTypesNav />
        <Box flex={1} sx={{ overflowX: "auto" }}>
          {isErrorProcedures && (
            <ErrorBlock
              title="An error occured."
              message={errorProcedures.info?.message || "Failed to fetch procedures. Please try again later."}
            />
          )}

          {isErrorNewProcedureType && (
            <ErrorBlock
              title="An error occured creating new Procedure Type"
              message={
                errorNewProcedureType.info?.message ||
                "Failed to create new Procedure Type. Please check for duplicate already present."
              }
            />
          )}
          {isErrorNewCategory && (
            <ErrorBlock
              title="An error occured creating new Procedure Category"
              message={
                errorNewProcedureCategory.info?.message ||
                "Failed to create new Procedure Category. Please check for duplicate already present."
              }
            />
          )}

          <Dialog
            open={newProcedureOpen}
            onClose={handleNewProcedureClose}
            PaperProps={{
              component: "form",
              onSubmit: handleNewProcedureTypeAdd,
            }}
            fullWidth
            maxWidth={"xs"}
          >
            <DialogTitle>
              <Typography variant="h4" component="p">
                Add Procedure Type
              </Typography>
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                required
                margin="dense"
                label="Procedure Type Name"
                type="text"
                fullWidth
                variant="outlined"
                value={newProcedureType}
                onChange={handleNewProcedureChange}
              />
              <FormControl sx={{ mt: 3 }} fullWidth>
                <InputLabel id="new-procedure-category">Procedure Category</InputLabel>
                <Select
                  labelId="new-procedure-category"
                  onChange={handleNewProcedureCategory}
                  label="Procedure Category"
                  value={newProcedureCategory}
                  required
                >
                  {categories.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.display}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleNewProcedureClose}>Cancel</Button>
              <Button type="submit" variant="contained" disableElevation disabled={isPendingNewProcedureType}>
                {isPendingNewProcedureType ? "Saving..." : "Save"}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={newCategoryOpen}
            onClose={handleNewCategoryClose}
            PaperProps={{
              component: "form",
              onSubmit: handleNewCategoryAdd,
            }}
            fullWidth
            maxWidth={"xs"}
          >
            <DialogTitle>
              <Typography variant="h4" component="p">
                Add new Procedure Category
              </Typography>
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                required
                margin="dense"
                label="Category Name"
                type="text"
                fullWidth
                variant="outlined"
                value={newProcedureCategory}
                onChange={handleNewCategoryChange}
              />
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 3 }}>
              <Button onClick={handleNewCategoryClose}>Cancel</Button>
              <Button type="submit" variant="contained" disableElevation disabled={isPendingNewCategory}>
                {isPendingNewCategory ? "Saving..." : "Save"}
              </Button>
            </DialogActions>
          </Dialog>

          {isPendingProcedures ? (
            <Loading />
          ) : (
            <Paper
              sx={{
                width: "100%",
                border: "1px solid #f0f0f0",
                borderRadius: 1.5,
                boxShadow: "none",
              }}
            >
              <TableContainer>
                <Table size="small" sx={{ borderRadius: "6px 6px 0 0", overflow: "hidden" }}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Procedure Type</StyledTableCell>
                      <StyledTableCell>Procedure Category</StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loaded && types.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={3}>No Current Procedures</TableCell>
                      </TableRow>
                    )}
                    {loaded && types.length > 0 && (
                      <>
                        {types.map((type) => (
                          <TableRow key={type.id} hover>
                            <TableCell sx={{ minWidth: 200 }}>{type.display}</TableCell>
                            <TableCell sx={{ minWidth: 200 }}>
                              <FormControl sx={{ width: "100%" }}>
                                <Select
                                  onChange={(selectedOption) =>
                                    handleCategoryChange(selectedOption, type.id, type.display)
                                  }
                                  size="small"
                                  defaultValue={type.categoryId}
                                  value={type.categoryId}
                                  sx={{ backgroundColor: "#ffffff" }}
                                >
                                  {categories.map((category) => (
                                    <MenuItem key={category.id} value={category.id}>
                                      {category.display}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <IconButton
                                size="small"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  handleEditType(type.id, type.display, type.categoryId);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                        <Dialog
                          open={editTypeOpen}
                          onClose={handleEditTypeClose}
                          PaperProps={{
                            component: "form",
                            onSubmit: handleEditTypeChanged,
                          }}
                          fullWidth
                          maxWidth={"xs"}
                        >
                          <DialogTitle>
                            <Typography variant="h4" component="p">
                              Edit Procedure Type
                            </Typography>
                          </DialogTitle>
                          <DialogContent>
                            <TextField
                              autoFocus
                              required
                              margin="dense"
                              label="Procedure Type Name"
                              type="text"
                              fullWidth
                              variant="outlined"
                              value={editTypeName}
                              onChange={handleEditTypeChange}
                            />
                          </DialogContent>
                          <DialogActions sx={{ px: 3, pb: 3 }}>
                            <Button onClick={handleEditTypeClose}>Cancel</Button>
                            <Button
                              type="submit"
                              variant="contained"
                              disableElevation
                              disabled={isPendingUpdateDataType}
                            >
                              {isPendingUpdateDataType ? "Saving..." : "Save"}
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
        </Box>
      </Box>
    </>
  );
}
