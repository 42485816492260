import { Box, Typography } from "@mui/material";
import React from "react";

const ChartHeader = ({ heading, subHeading, icon }) => {
  return (
    <Box p={3} sx={{ display: "flex", borderBottom: "1px solid #E7E3EF" }}>
      {icon && icon}
      <Box pl={1.5}>
        <Typography variant="bodyBiotifMedium" color="primary.gray950">{heading && heading}</Typography>
        <Typography variant="formLabel" color="primary.gray600" component="p">
          {subHeading && subHeading}
        </Typography>
      </Box>
    </Box>
  );
};

export default ChartHeader;
