import { Typography, Box } from "@mui/material";

const Header = ({ title, subtitle = "", action = "" }) => {
  return (
    <>
      <Box
        sx={{
          pb: 3,
          display: "flex",
          justifyContent: { xs: "flex-start", md: "space-between" },
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "flex-start", md: "normal" },
        }}
      >
        <Box>
          <Typography variant="h2" sx={{ mb: 1 }}>
            {title}
          </Typography>
          {subtitle && (
            <Typography color="primary.gray600" sx={{ mb: 2.5 }}>
              {subtitle}
            </Typography>
          )}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", flexDirection: { xs: "column", md: "row" } }}>{action}</Box>
      </Box>
    </>
  );
};

export default Header;
