import React from "react";
import { Box, CircularProgress } from "@mui/material";

const Loading = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
      width="100%"
      sx={{ p: 10 }}
    >
      <CircularProgress color="primary" />
    </Box>
  );
};

export default Loading;
