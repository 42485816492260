import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useFetcher, useNavigate, useParams } from "react-router-dom";
import TextInput from "../components/TextInput";
import SelectInput from "../components/SelectInput";
import { uiActions } from "../store/ui-slice";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY_PRACTICE, QUERY_KEY_PRACTICE_LOCATIONS } from "../store/constants";
import { fetchPractice } from "../utils/api/practices.api";
import { fetchLocationPossibleValues } from "../utils/api/practice-locations";
import Loading from "../components/Loading";
const { REACT_APP_API_ENDPOINT } = process.env;

const LocationAdd = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const uuid = params.uuid;

  const { data, state } = fetcher;

  const { data: formData, isPending } = useQuery({
    queryKey: [QUERY_KEY_PRACTICE, QUERY_KEY_PRACTICE_LOCATIONS],
    queryFn: async ({ signal }) => {
      const [practice, values] = await Promise.all([
        fetchPractice({ uuid, signal }),
        fetchLocationPossibleValues({ uuid, signal }),
      ]);
      return { practice, values };
    },
  });

  useEffect(() => {
    if (state === "idle" && data && data.message && data.message === "success") {
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Location added.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
      navigate(`/practices/${uuid}/locations`);
    }
  }, [data, state, dispatch, navigate, uuid]);

  const returnHandler = () => {
    navigate(`/practices/${uuid}/locations`);
  };

  const [selectData, setData] = useState({
    state: "",
    status: "",
  });

  const handleDataChange = (e) => {
    const value = e.target.value;
    setData({
      ...selectData,
      [e.target.name]: value,
    });
  };

  if (isPending) {
    return <Loading />;
  }

  return (
    <>
      <Header title="Add New Location" />
      <Container sx={{ py: 3, m: 0 }}>
        {formData.practice && (
          <Typography variant="h3" component="p" sx={{ mb: 3 }}>
            {formData.practice.name}
          </Typography>
        )}
        <fetcher.Form method="POST">
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              <TextInput required label="Location Name" name="name" />
              <TextInput required label="Address 1" name="address1" />
              <TextInput label="Address 2" name="address2" />
              <TextInput required label="Location Phone" name="actual_phone" type="tel" />
              <TextInput label="Tracking Phone" name="tracking_phone" type="tel" />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextInput required label="City" name="city" />
              <Grid container spacing={4}>
                <Grid item xs={12} lg={4}>
                  <SelectInput
                    required
                    label="State"
                    name="state"
                    value={selectData.state}
                    onChange={handleDataChange}
                    items={formData.values.state}
                  />
                </Grid>
                <Grid item xs={12} lg={8}>
                  <TextInput required label="Zip Code" name="zipCode" />
                </Grid>
              </Grid>
              <SelectInput
                required
                label="Status"
                name="status"
                value={selectData.status}
                onChange={handleDataChange}
                items={formData.values.status}
              />
              <TextInput label="Location Email" name="email" type="email" />
            </Grid>
          </Grid>
          <Box>
            <Stack direction="row" spacing={2} py={3}>
              <Button variant="outlined" onClick={returnHandler}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" disableElevation>
                Save
              </Button>
            </Stack>
          </Box>
        </fetcher.Form>
      </Container>
    </>
  );
};

export default LocationAdd;

export async function manipulateLocationAction({ request, params }) {
  const method = request.method;
  const data = await request.formData();

  const locationData = {
    name: data.get("name"),
    address1: data.get("address1"),
    address2: data.get("address2"),
    city: data.get("city"),
    state: data.get("state"),
    zipCode: data.get("zipCode"),
    status: data.get("status"),
    email: data.get("email"),
    phoneNumber: data.get("actual_phone"),
    trackingNumber: data.get("tracking_phone") || null,
  };

  const response = await fetch(`${REACT_APP_API_ENDPOINT}/practices/${params.uuid}/locations`, {
    method: method,
    body: JSON.stringify(locationData),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (response.status === 422) {
    return response;
  }

  if (!response.ok) {
    return { message: "error" };
  }

  return { message: "success" };
}
