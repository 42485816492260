import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { useRouteError } from "react-router-dom";

const Error = () => {
  const error = useRouteError();
  let title = "An error occured!";
  let message = "Something went wrong";
  if (error.status === 500) {
    message = error.data.message;
  }

  if ((error.status = 404)) {
    title = "Not found!";
    message = "Could not find resource or page.";
  }
  return (
    <Container
      sx={{
        p: 5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box>
        <Typography variant="h3" component="p">
          {title}
        </Typography>
        <Typography component="p">{message}</Typography>
      </Box>
    </Container>
  );
};

export default Error;
