import React, { useEffect, useState } from "react";
import { Table, TableBody, TableContainer } from "@mui/material";
import classes from "../../styles/MetricsTable.module.css";

import { tableDataPrep } from "../../utils/insights/tableDataPrep";
import InsightsTableRow from "./InsightsTableRow";
import { currencyFormatter, numberFormatter, percentageFormatter } from "../../data/valueFormatter";
import NoDataTable from "./NoDataTable";
import InsightsTableHeader from "../InsightsTableHeader";
import InsightsTableColumnHeaders from "./InsightsTableColumnHeaders";
import { capitalizeString } from "../../data/stringFormatter";

const PerformanceTable = ({ label, type, metricData, revenueType }) => {
  const [trendingLabels, setTrendingLabels] = useState([]);
  const [yoyLabels, setYoyLabels] = useState([]);
  const [yoyData, setYoyData] = useState([]);
  const [ytdLabels, setYtdLabels] = useState([]);
  const [ytdData, setYtdData] = useState([]);
  const [timeTable, setTimeTable] = useState("");

  const [grossRevenueData, setGrossRevenueData] = useState([]);
  const [netRevenueData, setNetRevenueData] = useState([]);
  const [revenuePerVisitData, setRevenuePerVisitData] = useState([]);
  const [annualRevenuePerPatientData, setAnnualRevenuePerPatientData] = useState([]);
  const [adjustmentsDiscountsData, setAdjustmentsDiscountsData] = useState([]);
  const [collectionsData, setCollectionsData] = useState([]);
  const [newPatientsData, setNewPatientsData] = useState([]);
  const [returningPatientsData, setReturningPatientsData] = useState([]);
  const [totalPatientsData, setTotalPatientsData] = useState([]);
  const [visitsData, setVisitsData] = useState([]);
  const [annualVisitsPerPatientData, setAnnualVisitsPerPatientData] = useState([]);
  const [newPatientRetentionData, setNewPatientRetentionData] = useState([]);
  const [allPatientRetentionData, setAllPatientRetentionData] = useState([]);

  useEffect(() => {
    if (metricData?.data?.length) {
      let grossRevenueTableData = tableDataPrep(metricData, "grossRevenue");
      setGrossRevenueData(grossRevenueTableData);
      setTrendingLabels(grossRevenueTableData.trendingLabels);
      setTimeTable(grossRevenueTableData.timeTable);
      setYoyLabels(grossRevenueTableData.yoyLabels);
      setYoyData(grossRevenueTableData.yoyData);
      setYtdData(grossRevenueTableData.ytdData);
      setYtdLabels(grossRevenueTableData.ytdLabels);

      setAdjustmentsDiscountsData(tableDataPrep(metricData, "adjustmentsDiscounts"));
      setNetRevenueData(tableDataPrep(metricData, "netRevenue"));
      setCollectionsData(tableDataPrep(metricData, "collections"));
      setNewPatientsData(tableDataPrep(metricData, "newPatients"));
      setReturningPatientsData(tableDataPrep(metricData, "returningPatients"));
      setTotalPatientsData(tableDataPrep(metricData, "totalPatients"));
      setRevenuePerVisitData(tableDataPrep(metricData, `${revenueType}RevenuePerVisit`));
      setAnnualRevenuePerPatientData(
        tableDataPrep(metricData, `annual${capitalizeString(revenueType)}RevenuePerPatient`)
      );
      setVisitsData(tableDataPrep(metricData, "visits"));
      setAnnualVisitsPerPatientData(tableDataPrep(metricData, "annualVisitsPerPatient"));
      setNewPatientRetentionData(tableDataPrep(metricData, "retentionRateNew12"));
      setAllPatientRetentionData(tableDataPrep(metricData, "retentionRate12"));
    }
  }, [metricData, revenueType]);

  return (
    <>
      {type === "Table" && (
        <>
          {trendingLabels.length === 0 && <NoDataTable label={label} />}
          {trendingLabels.length > 0 && (
            <TableContainer className={classes.insightsTable}>
              <Table size="small">
                <InsightsTableHeader
                  label={label}
                  trendingLabels={trendingLabels}
                  timeTable={timeTable}
                  yoyData={yoyData}
                  ytdData={ytdData}
                />
                <TableBody>
                  <InsightsTableColumnHeaders
                    trendingLabels={trendingLabels}
                    yoyLabels={yoyLabels}
                    ytdLabels={ytdLabels}
                    timeTable={timeTable}
                  />
                  {grossRevenueData && (
                    <InsightsTableRow
                      data={grossRevenueData}
                      formatter={currencyFormatter}
                      topRowLabel="Revenue"
                      label="Gross Revenue"
                    />
                  )}
                  {adjustmentsDiscountsData && (
                    <InsightsTableRow
                      data={adjustmentsDiscountsData}
                      formatter={currencyFormatter}
                      label="Adjustments/Discounts"
                      negativeIsGood
                    />
                  )}

                  {netRevenueData && (
                    <InsightsTableRow data={netRevenueData} formatter={currencyFormatter} label="Net Revenue" />
                  )}

                  {collectionsData && (
                    <InsightsTableRow data={collectionsData} formatter={currencyFormatter} label="Collections" />
                  )}

                  {revenuePerVisitData && (
                    <InsightsTableRow
                      data={revenuePerVisitData}
                      formatter={currencyFormatter}
                      topRowLabel="Revenue Ratios"
                      label="Revenue Per Visit"
                    />
                  )}

                  {annualRevenuePerPatientData && (
                    <InsightsTableRow
                      data={annualRevenuePerPatientData}
                      formatter={currencyFormatter}
                      label="Annual Revenue Per Patient"
                    />
                  )}

                  {newPatientsData && (
                    <InsightsTableRow
                      data={newPatientsData}
                      formatter={numberFormatter}
                      topRowLabel="Patients"
                      label="New Patients"
                    />
                  )}

                  {returningPatientsData && (
                    <InsightsTableRow
                      data={returningPatientsData}
                      formatter={numberFormatter}
                      label="Returning Patients"
                    />
                  )}

                  {totalPatientsData && (
                    <InsightsTableRow data={totalPatientsData} formatter={numberFormatter} label="Total Patients" />
                  )}

                  {visitsData && (
                    <InsightsTableRow
                      data={visitsData}
                      formatter={numberFormatter}
                      topRowLabel="Visits"
                      label="Visits"
                    />
                  )}

                  {annualVisitsPerPatientData && (
                    <InsightsTableRow
                      data={annualVisitsPerPatientData}
                      formatter={numberFormatter}
                      topRowLabel="Frequency"
                      label="Annual Visits Per Patient"
                    />
                  )}

                  {newPatientRetentionData && (
                    <InsightsTableRow
                      data={newPatientRetentionData}
                      formatter={percentageFormatter}
                      topRowLabel="Retention"
                      label="New Patient Retention"
                    />
                  )}

                  {allPatientRetentionData && (
                    <InsightsTableRow
                      data={allPatientRetentionData}
                      formatter={percentageFormatter}
                      label="All Patient Retention"
                    />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
    </>
  );
};

export default PerformanceTable;
