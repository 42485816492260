import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import AddIcon from "@mui/icons-material/Add";
import { useMutation, useQuery } from "@tanstack/react-query";
import { PAGINATION_ROWS_PER_PAGE_DEFAULT, PAGINATION_ROWS_PER_PAGE_OPTIONS, TYPE_UPLOADS } from "../store/constants";
import { fetchUploadsList, updateSessionUpload } from "../utils/api/data-uploads.api";
import ErrorBlock from "../components/UI/ErrorBlock";
import Loading from "../components/Loading";
import { Paper, Table, TableRow } from "@mui/material";
import StyledTableCell from "../components/StyledTableCell";
import moment from "moment";
import { queryClient } from "../utils/http";
import DataUpload from "../components/DataUpload/DataUpload.tsx";
import { useDispatch } from "react-redux";
import { uiActions } from "../store/ui-slice";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";

const DataUploader = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [uploadOpen, setUploadOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGINATION_ROWS_PER_PAGE_DEFAULT);
  const [uploads, setUploads] = useState([]);

  const { data, isPending, isError, error } = useQuery({
    queryKey: [TYPE_UPLOADS, params.uuid, rowsPerPage, page],
    queryFn: ({ signal }) =>
      fetchUploadsList({
        signal,
        uuid: params.uuid,
        limit: rowsPerPage,
        offset: page * rowsPerPage,
      }),
  });

  useEffect(() => {
    if (data) {
      setUploads(data.data);
    }
  }, [data]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUploadClickOpen = () => {
    setUploadOpen(true);
  };

  const handleNewUploadClose = () => {
    setUploadOpen(false);
    queryClient.invalidateQueries({ queryKey: [TYPE_UPLOADS] });
  };

  const {
    mutate,
    isError: isErrorMutate,
    error: errorMutate,
  } = useMutation({
    mutationFn: updateSessionUpload,
    onSuccess: () => {
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Upload Session updated.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [TYPE_UPLOADS] });
      }, 200);
    },
  });

  const handleAction = (rowId, action) => {
    mutate({ id: rowId, action });
  };

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" }, backgroundColor: open ? "#F3F6F9" : "#ffffff" }}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {row.files[0]?.fileName && (
                <Tooltip title={row.files[0]?.fileName} placement="left" arrow>
                  <ArticleOutlinedIcon sx={{ fontSize: "1.25rem", mr: 0.5 }} />
                </Tooltip>
              )}
              {row.files[0]?.dataType ? (
                row.files[0].dataType
              ) : (
                <Typography component={"p"}>
                  <Typography component={"em"} variant="body2">
                    Not available
                  </Typography>
                </Typography>
              )}
            </Box>
          </TableCell>
          <TableCell>
            {row.state === "mismatch"
              ? "Awaiting Confirmation"
              : row.files[0]?.stats.fileRowsCount &&
                row.state === "done" &&
                row.files[0]?.stats.fileRowsCount - row.files[0]?.stats.invalidRowsCount === 0
              ? "failed"
              : row.files[0]?.stats.invalidRowsCount > 0 && row.state === "done"
              ? "Partially completed"
              : row.state}
          </TableCell>
          <TableCell>{moment(row.createdAt).format("MM/DD/YYYY hh:mm:ss A")}</TableCell>
          <TableCell>
            {row.files[0]?.latestDate && moment(row.files[0].latestDate).format("MM/DD/YYYY hh:mm:ss A")}
          </TableCell>
          <TableCell>
            {row.state === "mismatch" && (
              <>
                <Button
                  variant="contained"
                  disableElevation
                  size="small"
                  onClick={() => handleAction(row.id, "approve")}
                >
                  Approve
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ ml: 2 }}
                  onClick={() => handleAction(row.id, "disapprove")}
                >
                  Disapprove
                </Button>
              </>
            )}
            {row.state === "pending" && (
              <>
                <Button
                  variant="contained"
                  disableElevation
                  size="small"
                  onClick={() => handleAction(row.id, "import")}
                >
                  Process
                </Button>
                <Button variant="outlined" size="small" sx={{ ml: 2 }} onClick={() => handleAction(row.id, "cancel")}>
                  Cancel
                </Button>
              </>
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                {row.files[0]?.fileName && (
                  <Box px={1}>
                    <Typography variant="h6" component="span" sx={{ fontWeight: "bold" }}>
                      File name:{" "}
                    </Typography>
                    <Typography variant="h6" component="span">
                      {row.files[0]?.fileName}
                    </Typography>
                  </Box>
                )}
                {
                  row.validationErrors && row.validationErrors.length > 0 && row.state === "mismatch" && (
                    <Box padding={1}>
                      <Typography variant="h6" component="span" sx={{ fontWeight: "bold" }}>
                        Validation Errors:{" "}
                      </Typography>
                      {
                        row.validationErrors.map((error) => (
                          <Typography
                            key={`${error.validationError}`}
                            component={"em"}
                            variant="h6"
                            sx={{ "&:not(:last-child):not(:first-child)::after": { content: '", "' }, color: 'red' }}
                          >
                            {error.validationError.replace('_', ' ')}
                          </Typography>
                        ))
                      }
                    </Box>
                  )
                }
                <Box padding={1}>
                  <Typography variant="h6" component="span" sx={{ fontWeight: "bold" }}>
                    Errors:{" "}
                  </Typography>
                  {row.state === "pending" && (
                    <Typography component={"em"} variant="h6" sx={{ ml: 1 }}>
                      &bull;Pending user action
                    </Typography>
                  )}
                  {row.errors &&
                    row.errors.map((error, i) => (
                      <Typography component={"em"} variant="h6" sx={{ ml: 1 }} key={i}>
                        &bull;{error.message}
                      </Typography>
                    ))}
                  {row.files[0]?.errors &&
                    row.files[0].errors.map((error, i) => (
                      <Typography component={"em"} variant="h6" key={i} sx={{ ml: 1 }}>
                        &bull;{error.message}
                      </Typography>
                    ))}
                </Box>
                {row.files[0]?.stats && (
                  <Table size="small" aria-label="Data details" sx={{ borderRadius: "4px", overflow: "hidden" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Total Rows</TableCell>
                        <TableCell align="center">Duplicates</TableCell>
                        <TableCell align="center">Invalids</TableCell>
                        <TableCell>Notes</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ width: 120, borderBottom: "unset" }}>
                          <Typography variant="h6">{row.files[0]?.stats?.fileRowsCount?.toLocaleString()}</Typography>
                        </TableCell>
                        <TableCell align="center" sx={{ width: 120, borderBottom: "unset" }}>
                          <Typography variant="h6">
                            {row.files[0]?.stats?.duplicatedRowsCount?.toLocaleString()}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" sx={{ width: 120, borderBottom: "unset" }}>
                          <Typography variant="h6">
                            {row.files[0]?.stats?.invalidRowsCount?.toLocaleString()}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: "unset" }}>
                          {row.files[0]?.stats?.invalidColumns?.map((column, i) => (
                            <Typography variant="h6" component="span" key={i} sx={{ pr: 1 }}>
                              &bull;{column}
                            </Typography>
                          ))}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                )}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <>
      <Header
        title="Data Uploader"
        action={
          <Button variant="outlined" disableElevation startIcon={<AddIcon />} onClick={handleUploadClickOpen}>
            Upload
          </Button>
        }
      />
      <Box>
        {isError && (
          <ErrorBlock
            title="An error occured."
            message={error.info?.message || "Failed to fetch uploads. Please try again later"}
          />
        )}
        {isErrorMutate && (
          <ErrorBlock
            title="An error occured."
            message={errorMutate.info?.message || "Failed to update Upload Session. Please try again later"}
          />
        )}
        {isPending && <Loading />}
        {data && data.data?.length === 0 && <Typography>No current uploads</Typography>}
        {data && uploads.length > 0 && (
          <Paper
            sx={{
              width: "100%",
              border: "1px solid #f0f0f0",
              borderRadius: 1.5,
              boxShadow: "none",
            }}
          >
            <TableContainer>
              <Table size="small" sx={{ borderRadius: "6px 6px 0 0", overflow: "hidden" }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell sx={{ width: 66 }} />
                    <StyledTableCell>File Category</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell sx={{ width: 200 }}>Upload Date</StyledTableCell>
                    <StyledTableCell sx={{ width: 200 }}>Latest Date</StyledTableCell>
                    <StyledTableCell>Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {uploads.map((upload) => (
                    <Row key={upload.id} row={upload} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={PAGINATION_ROWS_PER_PAGE_OPTIONS}
              component="div"
              count={data.total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        )}
        <Dialog open={uploadOpen} fullWidth maxWidth={"sm"}>
          <DialogTitle>
            <Typography variant="h4" component="p">
              Upload New Data
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DataUpload />
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 3 }}>
            <Button disableElevation variant="contained" onClick={handleNewUploadClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default DataUploader;
