import { FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";

import React from "react";

const SelectInput = (props) => {
  const { name, label, value, items = [], required = false, onChange, multiple = false } = props;
  return (
    <Stack sx={{ mb: 3 }}>
      <FormControl>
        <InputLabel id={`input-${name}`}>
          {label}
          {required && ` *`}
        </InputLabel>
        <Select
          labelId={`input-${name}`}
          name={name}
          value={value}
          label={label}
          onChange={onChange}
          multiple={multiple}
          required={required}
          sx={{ background: "rgb(248, 250, 252)", ...props.sx }}
        >
          {items.map((item, i) => (
            <MenuItem key={i} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default SelectInput;
