const { REACT_APP_API_ENDPOINT } = process.env;
const BASE_URL = `${REACT_APP_API_ENDPOINT}/practices/{practice_uuid}/diagnosis-codes`;

export async function fetchPracticeRecallTypes({ practiceUuid, signal }) {
  const response = await fetch(getBaseUrl(practiceUuid), { signal });

  if (!response.ok) {
    const error = new Error("An error occurred while fetching practice diagnosis types");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return await response.json();
}

export async function updatePracticeRecallType({ practiceUuid, id, data }) {
  const response = await fetch(`${getBaseUrl(practiceUuid)}/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("An error occured while updating practice diagnosis type");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return await response.json();
}

function getBaseUrl(practiceUuid) {
  return BASE_URL.replace("{practice_uuid}", practiceUuid);
}
