import { Box, LinearProgress } from "@mui/material";
import React from "react";

const LoaderBar = () => {
  return (
    <Box
      sx={{
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 2000,
      }}
    >
      <LinearProgress />
    </Box>
  );
};

export default LoaderBar;
