import { coreAppClient } from '../coreAppClient';

const BASE_URL = '/feature-toggle';

export const fetchFeatureToggleOptions = async ({ signal }) => {
  const response = await coreAppClient.get(`${BASE_URL}/names`, { signal });
  return response.data;
};

export const fetchFeatureToggles = async ({ signal, practiceUuid }) => {
  const response = await coreAppClient.get(`${BASE_URL}/practices/${practiceUuid}`, { signal });
  return response.data;
};

export const updateFeatureToggle = async ({ practiceUuid, name, value }) => {
  await coreAppClient.patch(
    `${BASE_URL}/practices/${practiceUuid}/${name}`,
    { value },
  );
};