import { Typography } from "@mui/material";
import React from "react";
import classes from "./StatusBadge.module.scss";

const StatusBadge = (props) => {
  const badgeClasses = `${classes.statusBadge} ${props.status === "inactive" ? classes.inactive : ""} 
  ${props.status === "active" ? classes.active : ""} 
  ${props.status === "error" ? classes.error : ""} 
  ${props.status === "pending" ? classes.pending : ""}`;

  return (
    <Typography variant="body2" component="span" className={badgeClasses} sx={{ fontWeight: 500, ...props.sx }}>
      {props.label ? props.label : props.status}
    </Typography>
  );
};

export default StatusBadge;
