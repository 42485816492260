import React from "react";

export default function useMappingsOrder() {
  const [orderField, setOrderField] = React.useState('count')
  const [orderDirection, setOrderDirection] = React.useState('desc')

  const changeOrder = (field) => {
    if (field === orderField) {
      setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setOrderField(field)
      setOrderDirection('asc')
    }
  }

  return {
    orderField, orderDirection, changeOrder
  }
}