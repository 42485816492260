import React from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const StyledCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.gray50,
    color: theme.palette.primary.gray500,
    padding: "0.75rem 1rem",
    borderBottom: "none",
  },
}));

const StyledTableCell = (props) => {
  return <StyledCell sx={{ ...props.sx }}>{props.children}</StyledCell>;
};

export default StyledTableCell;
