import { Drawer, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import AutomationResults from "../../components/AutomationResults/AutomationResults";
import { ReactComponent as IconInfo } from "../../assets/icon-info.svg";
import ResultsDrawer from "../../components/Drawers/ResultsDrawer";

const Results = () => {
  const [infoOpen, setInfoOpen] = useState(false);

  const handleInfoClose = () => {
    setInfoOpen(false);
  };

  const handleInfoOpen = () => {
    setInfoOpen(true);
  };

  return (
    <>
      <Box
        sx={{
          pb: 3,
          display: "flex",
          justifyContent: { xs: "flex-start", md: "space-between" },
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "flex-start", md: "normal" },
        }}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h2" sx={{ mb: 1 }}>
            Results
          </Typography>
          <IconButton onClick={handleInfoOpen}>
            <IconInfo />
          </IconButton>
        </Box>
      </Box>

      <AutomationResults />
      <Drawer anchor="right" open={infoOpen} onClose={handleInfoClose}>
        <ResultsDrawer onClick={handleInfoClose} />
      </Drawer>
    </>
  );
};

export default Results;
