import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

export default function useCustomClaims() {
  const [isBonsaiAdmin, setIsBonsaiAdmin] = useState(false);
  const [isMultiPracticeUser, setIsMultiPracticeUser] = useState(false);
  const { user } = useAuth0();

  useEffect(() => {
    if (!user || !user.bonsaiHealthAppPracticeUuids || !user.bonsaiHealthAppRoles) {
      return;
    }

    const isAdmin = user.bonsaiHealthAppRoles.some((role) => role === 'bonsai-admin');
    setIsBonsaiAdmin(isAdmin);
    setIsMultiPracticeUser(isAdmin || user.bonsaiHealthAppPracticeUuids.length > 1);
  }, [user]);

  return { isBonsaiAdmin, isMultiPracticeUser };
};