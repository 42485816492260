import {
  Box,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  PAGINATION_ROWS_PER_PAGE_DEFAULT,
  PAGINATION_ROWS_PER_PAGE_OPTIONS,
  QUERY_KEY_DATA_TYPE_TENDER_MAPPINGS,
  TYPE_TENDER,
} from "../store/constants";
import { createNewMapping, fetchPracticeTenderMappings, queryClient, updatePracticeMapping } from "../utils/http";
import { uiActions } from "../store/ui-slice";
import ErrorBlock from "../components/UI/ErrorBlock";
import Loading from "../components/Loading";
import StyledTableCell from "../components/StyledTableCell";
import useMappingsOrder from "../utils/hooks/useMappingsOrder";
import useDebouncedFilter from "../utils/hooks/useDebouncedFilter";
import StyledOrderedTableCell from "../components/StyledOrderedTableCell";
import SearchBox from "../components/SearchBox";

const PracticeMappingsTender = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const { orderField, orderDirection, changeOrder } = useMappingsOrder();
  const { filter, setFilter, debouncedFilter } = useDebouncedFilter("", 300);
  const [practiceTenderTypes, setPracticeTenderTypes] = useState([]);
  const [bonsaiTenderTypes, setBonsaiTenderTypes] = useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(PAGINATION_ROWS_PER_PAGE_DEFAULT);

  const { data, isPending, isError, error } = useQuery({
    queryKey: [
      QUERY_KEY_DATA_TYPE_TENDER_MAPPINGS,
      params.uuid,
      rowsPerPage,
      page,
      orderField,
      orderDirection,
      debouncedFilter,
    ],
    queryFn: ({ signal }) =>
      fetchPracticeTenderMappings({
        signal,
        uuid: params.uuid,
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        orderField: orderField,
        order: orderDirection,
        filter: debouncedFilter.length > 0 ? debouncedFilter : null,
      }),
  });

  const {
    mutate,
    isError: isErrorTenderMapping,
    error: errorTenderMapping,
  } = useMutation({
    mutationFn: updatePracticeMapping,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_DATA_TYPE_TENDER_MAPPINGS],
      });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Tender Type Mapping updated.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  useEffect(() => {
    if (data) {
      setPracticeTenderTypes(data.practiceData.data);
      setBonsaiTenderTypes(data.bonsaiData.items);
    }
  }, [data]);

  const handleBonsaiTenderChange = (selectedOption, id) => {
    let data = {};
    let values = [];
    selectedOption.target.value.forEach((element) => values.push(element));
    data.substitutes = values;

    mutate({ uuid: params.uuid, id: id, data: data });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const {
    mutate: mutateCreateMapping,
    isError: isErrorCreateMapping,
    error: errorCreateMapping,
  } = useMutation({
    mutationFn: createNewMapping,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_DATA_TYPE_TENDER_MAPPINGS],
      });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Tender Type Mapping created.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  const handleMappingCreate = (selectedOption, substitute) => {
    let data = {};
    let values = [];
    values.push(selectedOption.target.value);
    data.original = substitute;
    data.substitutes = values;

    mutateCreateMapping({ uuid: params.uuid, type: TYPE_TENDER, data: data });
  };

  const handleClearFilter = () => {
    setFilter("");
  };

  return (
    <Box display="flex">
      <Box flex={1} sx={{ overflowX: "auto" }}>
        {isErrorCreateMapping && (
          <ErrorBlock
            title="An error occured."
            message={errorCreateMapping.info?.message || "Failed to create tender mapping. Please try again later."}
          />
        )}
        {isError && (
          <ErrorBlock
            title="An error occured."
            message={error.info?.message || "Failed to fetch practice tender mappings. Please try again later."}
          />
        )}
        {isErrorTenderMapping && (
          <ErrorBlock
            title="An error occured."
            message={errorTenderMapping.info?.message || "Failed to update tender mapping. Please try again later."}
          />
        )}
        {(data?.practiceData.data.length ?? 0) === 0 && isPending && debouncedFilter === "" && <Loading />}
        {(data?.practiceData.data.length ?? 0) === 0 && !isPending && debouncedFilter === "" && (
          <Typography>No current procedure mappings</Typography>
        )}
        {(data?.practiceData.data.length ?? 0) === 0 && !isPending && debouncedFilter !== "" && (
          <Typography>No result</Typography>
        )}
        {((data?.practiceData.data.length ?? 0) > 0 || debouncedFilter !== "") && (
          <>
            <SearchBox
              value={filter}
              onChange={(event) => setFilter(event.target.value)}
              handleClearFilter={handleClearFilter}
            />
            <Paper
              sx={{
                width: "100%",
                border: "1px solid #f0f0f0",
                borderRadius: 1.5,
                boxShadow: "none",
              }}
            >
              <TableContainer>
                <Table size="small" stickyHeader sx={{ borderRadius: "6px 6px 0 0", overflow: "hidden" }}>
                  <TableHead>
                    <TableRow>
                      <StyledOrderedTableCell
                        fieldName="rawValue"
                        orderField={orderField}
                        orderDirection={orderDirection}
                        changeOrder={changeOrder}
                      >
                        Practice Tender Type
                      </StyledOrderedTableCell>
                      <StyledOrderedTableCell
                        sx={{ textAlign: "center" }}
                        fieldName="count"
                        orderField={orderField}
                        orderDirection={orderDirection}
                        changeOrder={changeOrder}
                      >
                        Count
                      </StyledOrderedTableCell>
                      <StyledTableCell>Bonsai Tender Type</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {practiceTenderTypes.map((practiceTenderType, i) => (
                      <TableRow key={i} hover>
                        <TableCell sx={{ minWidth: 200 }}>
                          {practiceTenderType.rawValue || "** Not Assigned **"}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>{practiceTenderType.count}</TableCell>
                        <TableCell sx={{ minWidth: 200 }}>
                          <FormControl sx={{ width: "100%" }}>
                            {practiceTenderType.substitute ? (
                              <Select
                                size="small"
                                labelId={`bonsai-tender-${i}`}
                                onChange={(selectedOption) =>
                                  handleBonsaiTenderChange(selectedOption, practiceTenderType.substitute.substituteId)
                                }
                                value={practiceTenderType.substitute.substitutes}
                                defaultValue={practiceTenderType.substitute.substitutes}
                                multiple
                                sx={{ backgroundColor: "#ffffff" }}
                              >
                                {bonsaiTenderTypes.map((bonsaiTenderType) => (
                                  <MenuItem key={bonsaiTenderType.id} value={bonsaiTenderType.id}>
                                    {bonsaiTenderType.display}
                                  </MenuItem>
                                ))}
                              </Select>
                            ) : (
                              <Select
                                disabled={!practiceTenderType.rawValue}
                                size="small"
                                labelId={`bonsai-tender-${i}`}
                                onChange={(selectedOption) =>
                                  handleMappingCreate(selectedOption, practiceTenderType.rawValue)
                                }
                                value={practiceTenderType.rawValue || ""}
                                defaultValue={practiceTenderType.rawValue || ""}
                                sx={{ backgroundColor: "#ffffff" }}
                              >
                                {bonsaiTenderTypes.map((bonsaiTenderType) => (
                                  <MenuItem key={bonsaiTenderType.id} value={bonsaiTenderType.id}>
                                    {bonsaiTenderType.display}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={PAGINATION_ROWS_PER_PAGE_OPTIONS}
                component="div"
                count={data?.practiceData?.total ?? 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </>
        )}
      </Box>
    </Box>
  );
};

export default PracticeMappingsTender;
