import { replace } from "lodash";
import { coreAppClient } from "../coreAppClient";

const BASE_URL = '/practices/{practiceUuid}/email-unsubscribes/{email}';
export async function getUnsubscribeOptions({practiceUuid, email, signal}) {
  try {
    const url = replace(replace(BASE_URL, '{email}', encodeURIComponent(email)), '{practiceUuid}', practiceUuid);
    const emailUnsubscribeOptions = await coreAppClient.get(url, { signal });
    return emailUnsubscribeOptions.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateUnsubscribeOptions({practiceUuid, email, unsubGroups, isUnsubscribedFromSms}) {
  try {
    const url = replace(replace(BASE_URL, '{email}', encodeURIComponent(email)), '{practiceUuid}', practiceUuid);
    await coreAppClient.post(url, { emailUnsubscribeGroups: unsubGroups, smsUnsubscribed: isUnsubscribedFromSms });
  } catch (error) {
    return Promise.reject(error);
  }
}
