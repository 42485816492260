import { coreAppClient } from "../coreAppClient";

const BASE_URL = "/practices";

export async function createPractice({ practice }) {
  try {
    const createResponse = await coreAppClient.post(BASE_URL, practice);
    return createResponse.status === 201;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchPractice({ uuid, signal }) {
  try {
    const practice = await coreAppClient.get(`${BASE_URL}/${uuid}`, { signal });
    return practice.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchPractices({ page, signal, search }) {
  try {
    const query = new URLSearchParams({ page });
    if (typeof search === 'string' && search.length !== 0) {
      query.append('search', search)
    }
    const practices = await coreAppClient.get(`${BASE_URL}?${query.toString()}`, { signal });
    return practices.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updatePractice({ uuid, practiceData }) {
  try {
    const response = await coreAppClient.patch(`${BASE_URL}/${uuid}`, practiceData);
    return response.status === 201;
  } catch (error) {
    return Promise.reject(error);
  }
}
