import React, { useState } from "react";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import StyledTableCell from "./StyledTableCell";
import StyledTableRow from "./StyledTableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete"
import { useMutation } from "@tanstack/react-query";
import { deletePracticeUser } from "../utils/api/practice-users.api";
import { queryClient } from "../utils/http";
import { QUERY_KEY_PRACTICE_USERS } from "../store/constants";
import { uiActions } from "../store/ui-slice";
import { useDispatch } from "react-redux";

const PracticeUsersTable = ({ users, practiceUuid }) => {
    /** Confirmation Modal Management **/
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
    const handleDeleteClick = (user) => {
        setUserToDelete(user);
        setIsDeleteConfirmationOpen(true);
    };

    /** Delete User **/
    const dispatch = useDispatch();
    const [userToDelete, setUserToDelete] = useState(null);

    const { mutate: deleteUser, isPending } = useMutation({
        mutationFn: deletePracticeUser,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEY_PRACTICE_USERS] });
            dispatch(
              uiActions.showNotification({
                  status: "success",
                  message: "User deleted",
              }),
              dispatch(uiActions.showSnackBar(true))
            );
            setIsDeleteConfirmationOpen(false);
            setUserToDelete(null);
        },
        onError: () => {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEY_PRACTICE_USERS] });
            dispatch(
              uiActions.showNotification({
                  status: "error",
                  message: "Error deleting user",
              }),
              dispatch(uiActions.showSnackBar(true))
            );
            setIsDeleteConfirmationOpen(false);
            setUserToDelete(null);
        },
    })

    const handleConfirmDeleteClick = async () => {
        await deleteUser({ practiceUuid, uuid: userToDelete.uuid });
    };

    return (
      <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="Providers table" size="small">
              <TableHead>
                  <TableRow>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell>Role</StyledTableCell>
                      <StyledTableCell>Email</StyledTableCell>
                      <StyledTableCell>Provider Type</StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                  {users.map((row) => (
                    <StyledTableRow key={row.uuid}>
                        <StyledTableCell component="th" scope="row">
                            {row.firstName} {row.lastName}
                        </StyledTableCell>
                        <StyledTableCell>{row.practiceRole}</StyledTableCell>
                        <StyledTableCell>{row.email}</StyledTableCell>
                        <StyledTableCell>{row.provider.type}</StyledTableCell>
                        <StyledTableCell>
                            <Link to={`/practices/${practiceUuid}/providers/${row.uuid}/edit`}>
                                <IconButton
                                  size="small"
                                  variant="outlined"
                                  color="primary"
                                >
                                    <EditIcon fontSize="small"/>
                                </IconButton>
                            </Link>
                            <IconButton
                              size="small"
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                  handleDeleteClick(row)
                              }}
                            >
                                <DeleteIcon fontSize="small"/>
                            </IconButton>
                        </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
          </Table>
          <Dialog open={isDeleteConfirmationOpen} fullWidth maxWidth={"xs"}>
              <DialogTitle>
                  <Typography variant="h4" component="p">
                      Confirm Delete Practice User
                  </Typography>
              </DialogTitle>
              <DialogContent>
                  <Typography variant="p">Are you sure you want to delete this user?</Typography>
              </DialogContent>
              <DialogActions sx={{ px: 3, pb: 3 }}>
                  <Button
                    disableElevation
                    color="error"
                    variant="contained"
                    onClick={() => handleConfirmDeleteClick()}
                    disabled={isPending}
                  >
                      Delete
                  </Button>
                  <Button
                    disableElevation
                    variant="outlined"
                    onClick={() => setIsDeleteConfirmationOpen(false)}
                  >
                      Cancel
                  </Button>
              </DialogActions>
          </Dialog>
      </TableContainer>
    );
}
export default PracticeUsersTable;
