import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Box, Button, TablePagination, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PracticeUsersTable from "../components/PracticeUsersTable";
import ErrorBlock from "../components/UI/ErrorBlock";
import Loading from "../components/Loading";
import { useQuery } from "@tanstack/react-query";
import {
  PAGINATION_ROWS_PER_PAGE_DEFAULT,
  PAGINATION_ROWS_PER_PAGE_OPTIONS,
  QUERY_KEY_PRACTICE_USERS,
} from "../store/constants";
import { fetchPracticeUsers } from "../utils/api/practice-users.api";

const PracticeProviders = () => {
  const params = useParams();

  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [rowsPerPage, setRowsPerPage] = useState(PAGINATION_ROWS_PER_PAGE_DEFAULT);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { data, isPending, isError, error } = useQuery({
    queryKey: [QUERY_KEY_PRACTICE_USERS, params.uuid, rowsPerPage, page],
    queryFn: ({ signal }) =>
      fetchPracticeUsers({
        signal,
        uuid: params.uuid,
        limit: rowsPerPage,
        offset: page * rowsPerPage,
      }),
  });

  return (
    <>
      <Box display="flex">
        {isError && (
          <ErrorBlock
            title="An error occured."
            message={error.info?.message || "Failed to fetch practice users. Please try again later."}
          />
        )}
        {isPending ? (
          <Loading />
        ) : (
          <Box flex={1}>
            <Box sx={{ mb: 3 }}>
              <Link to={`/practices/${params.uuid}/providers/add`}>
                <Button variant="contained" disableElevation startIcon={<AddIcon />}>
                  Add Provider
                </Button>
              </Link>
            </Box>
            {!data.items || !data.items.length ? (
              <Typography>No current providers</Typography>
            ) : (
              <>
                <PracticeUsersTable users={data.items} practiceUuid={params.uuid} />
                <TablePagination
                  rowsPerPageOptions={PAGINATION_ROWS_PER_PAGE_OPTIONS}
                  component="div"
                  count={data.total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default PracticeProviders;
