import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { QUERY_KEY_PRACTICE_LOCATIONS } from "../../store/constants";
import { fetchPracticeLocations } from "../../utils/http";

const LocationFilter = ({ change }) => {
  const params = useParams();
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("all");

  const { data } = useQuery({
    queryKey: [QUERY_KEY_PRACTICE_LOCATIONS, params.uuid],
    queryFn: async ({ signal }) => {
      const [locations] = await Promise.all([fetchPracticeLocations({ uuid: params.uuid, signal })]);
      return { locations };
    },
  });

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
    change(event.target.value);
  };

  useEffect(() => {
    if (data?.locations?.locations?.length) {
      setLocations(data.locations.locations);
      if (data.locations.locations.length === 1) {
        setSelectedLocation(data.locations.locations[0].uuid);
      }
    }
  }, [data]);

  return (
    <FormControl sx={{ m: 1, minWidth: 180, backgroundColor: "white" }} size="small">
      <InputLabel id="location-label">Location</InputLabel>
      <Select label="Location" onChange={handleLocationChange} value={selectedLocation} disabled={locations.length < 2}>
        <MenuItem value="all">All Locations</MenuItem>
        {locations.map((location, i) => (
          <MenuItem key={i} value={location.uuid}>
            {location.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LocationFilter;
