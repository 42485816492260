import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  navIsExpanded: true,
  notification: null,
  showSnack: false,
};

const uiSlice = createSlice({
  name: "ui",
  initialState: initialState,
  reducers: {
    toggleNav(state) {
      state.navIsExpanded = !state.navIsExpanded;
    },
    showNotification(state, action) {
      state.notification = {
        status: action.payload.status,
        message: action.payload.message,
      };
    },
    showSnackBar(state, action) {
      state.showSnack = action.payload;
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice;
