import { MONTHS } from "../../store/constants";

let chartData = { axisData: [], seriesData: [] };

export const chartDataPrep = (format, data, metric, timetable, priorColor, currentColor) => {
  /** MONTHLY DATA PROCDSS */
  if (timetable === "monthly") {
    let currentMonth = data[0].date.month;
    let currentYear = data[0].date.year;
    let fullData = [];
    let currentData = [];
    let priorData = [];
    let current = [];
    let prior = [];
    let axisData = [];
    for (let i = 0; i < 24; i++) {
      if (data[i]) {
        fullData.unshift({
          value: data[i][metric],
          date: `${MONTHS[data[i].date.month - 1]} ${data[i].date.year}`,
        });
      } else {
        fullData.unshift({
          value: 0,
          date: `${MONTHS[currentMonth - 1]} ${currentYear}`,
        });
      }
      currentMonth--;
      if (currentMonth < 1) {
        currentMonth = 12;
        currentYear--;
      }
    }
    prior = fullData.slice(0, 12);
    current = fullData.slice(12);
    current.forEach((curr) => {
      currentData.push(curr.value);
      axisData.push(curr.date);
    });
    prior.forEach((pr) => {
      priorData.push(pr.value);
    });
    chartData.seriesData = [
      {
        type: "bar",
        label: "Prior Year      ",
        color: priorColor,
        valueFormatter: format,
        data: priorData,
      },
      {
        type: "bar",
        label: "Current Year      ",
        color: currentColor,
        valueFormatter: format,
        data: currentData,
      },
    ];
    chartData.axisData = axisData;
  }

  /** QUARTERLY DATA */
  if (timetable === "quarterly") {
    let currentQuarter = data[0].date.quarter;
    let currentYear = data[0].date.year;
    let fullData = [];
    let currentData = [];
    let priorData = [];
    let current = [];
    let prior = [];
    let axisData = [];

    for (let i = 0; i < 8; i++) {
      if (data[i]) {
        fullData.unshift({
          value: data[i][metric],
          date: `Q${data[i].date.quarter} ${data[i].date.year}`,
        });
      } else {
        fullData.unshift({
          value: "0.00",
          date: `Q${currentQuarter} ${currentYear}`,
        });
      }
      currentQuarter--;
      if (currentQuarter < 1) {
        currentQuarter = 4;
        currentYear--;
      }
    }
    prior = fullData.slice(0, 4);
    current = fullData.slice(4);

    current.forEach((curr) => {
      currentData.push(curr.value);
      axisData.push(curr.date);
    });

    prior.forEach((pr) => {
      priorData.push(pr.value);
    });

    chartData.seriesData = [
      {
        type: "bar",
        label: "Prior Year      ",
        color: priorColor,
        valueFormatter: format,
        data: priorData,
      },
      {
        type: "bar",
        label: "Current Year      ",
        color: currentColor,
        valueFormatter: format,
        data: currentData,
      },
    ];

    chartData.axisData = axisData;
  }

  /** YEARLY DATA */
  if (timetable === "yearly") {
    let currentYear = data[0].date.year;
    let currentData = [];
    let axisData = [];

    for (let i = 0; i < 4; i++) {
      if (data[i]) {
        currentData.unshift(data[i][metric]);
        axisData.unshift(`${data[i].date.year}`);
      } else {
        currentData.unshift("0.00");
        axisData.unshift(`${currentYear}`);
      }
      currentYear--;
    }

    chartData.seriesData = [
      {
        type: "bar",
        label: "Year      ",
        color: currentColor,
        valueFormatter: format,
        data: currentData,
      },
    ];

    chartData.axisData = axisData;
  }

  return chartData;
};
