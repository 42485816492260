import { Stack, TextField } from "@mui/material";
import React from "react";

const TextInput = (props) => {
  const { name, label, value, required = false, onChange, disabled, type = 'string' } = props;
  return (
    <Stack sx={{ mb: 3 }}>
      <TextField
        label={label}
        variant="outlined"
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        disabled={disabled}
        type={type}
        sx={{ background: "rgb(248, 250, 252)" }}
      />
    </Stack>
  );
};

export default TextInput;
