import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Typography, Drawer } from "@mui/material";
import { Box } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { QUERY_KEY_PRACTICE_CAMPAIGN_ATTRIBUTION } from "../../store/constants";
import { fetchPracticeCampaignAttribution } from "../../utils/api/practice-campaign-attribution.api";
import Loading from "../Loading";
import StyledTableCell from "../StyledTableCell";
import AttributionListDrawer from "../Drawers/AttributionListDrawer";
import useSystemOfRecord from "../../utils/hooks/useSystemOfRecord";
import classes from "./AumotationResults.module.css";

const AutomationResults = () => {
  const { uuid: practiceUuid } = useParams();
  const [campaignData, setCampaignData] = useState([]);
  const [totalPatients, setTotalPatients] = useState(0);
  const [totalFormLeads, setTotalFormLeads] = useState(0);
  const [totalPhoneLeads, setTotalPhoneLeads] = useState(0);
  const [totalLeads, setTotalLeads] = useState(0);
  const [totalTrackedAppointments, setTotalTrackedAppointments] = useState(0);
  const [totalInferredAppointments, setTotalInferredAppointments] = useState(0);
  const [totalBookings, setTotalBookings] = useState(0);
  const [totalEstimateRevenue, setTotalEstimateRevenue] = useState("");
  const { systemOfRecord } = useSystemOfRecord();

  const CAMPAIGN_COPY_MAP = {
    NEUROMODULATORS: {
      title: "Botox",
    },
    SKIN_SCREENING: {
      title: "Skin Screening",
    },
    FILLERS: {
      title: "Filler",
    },
  };

  /** Data formatting **/
  const formatNumber = (value) => (value ? Intl.NumberFormat("en-US").format(value) : 0);
  const formatMoney = (value) => (value ? `$${value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}` : "$0");

  /** Fetch Practice Campaign Attribution **/
  const { data: practiceCampaignAttribution, isPending: isPracticeCampaignAttributionLoading } = useQuery({
    queryKey: [QUERY_KEY_PRACTICE_CAMPAIGN_ATTRIBUTION, practiceUuid],
    queryFn: ({ signal }) => fetchPracticeCampaignAttribution({ practiceUuid, signal }),
  });

  useEffect(() => {
    if (!practiceCampaignAttribution) {
      return;
    }
    setCampaignData(
      practiceCampaignAttribution.items.map((practiceCampaign) => ({
        practiceCampaignId: practiceCampaign.practiceCampaignId,
        campaignType: practiceCampaign.campaignType,
        patientsCount: formatNumber(practiceCampaign.patientsCount),
        appointmentEstimatedRevenue: formatMoney(practiceCampaign.appointmentEstimatedRevenue),
        formLeadCount: formatNumber(practiceCampaign.formLeadCount),
        phoneLeadCount: formatNumber(practiceCampaign.phoneLeadCount),
        totalLeadCount: formatNumber(practiceCampaign.formLeadCount + practiceCampaign.phoneLeadCount),
        trackedAppointments: formatNumber(practiceCampaign.trackedBookingsCount),
        inferredAppointments: formatNumber(practiceCampaign.inferredBookingsCount),
        bookingsCount: formatNumber(practiceCampaign.bookingsCount),
        estimatedRevenue: formatMoney(practiceCampaign.bookingsCount * practiceCampaign.appointmentEstimatedRevenue),
      }))
    );
    setTotalPatients(
      formatNumber(practiceCampaignAttribution.items.reduce((total, row) => total + row.patientsCount, 0))
    );
    setTotalFormLeads(
      formatNumber(practiceCampaignAttribution.items.reduce((total, row) => total + row.formLeadCount, 0))
    );
    setTotalPhoneLeads(
      formatNumber(practiceCampaignAttribution.items.reduce((total, row) => total + row.phoneLeadCount, 0))
    );
    setTotalLeads(
      formatNumber(
        practiceCampaignAttribution.items.reduce((total, row) => total + row.phoneLeadCount + row.formLeadCount, 0)
      )
    );
    setTotalBookings(
      formatNumber(practiceCampaignAttribution.items.reduce((total, row) => total + row.bookingsCount, 0))
    );
    setTotalTrackedAppointments(
      formatNumber(practiceCampaignAttribution.items.reduce((total, row) => total + row.trackedBookingsCount, 0))
    );
    setTotalInferredAppointments(
      formatNumber(practiceCampaignAttribution.items.reduce((total, row) => total + row.inferredBookingsCount, 0))
    );
    setTotalEstimateRevenue(
      formatMoney(
        practiceCampaignAttribution.items.reduce(
          (total, row) => total + row.bookingsCount * row.appointmentEstimatedRevenue,
          0
        )
      )
    );
  }, [practiceCampaignAttribution]);

  /** Attribution List Drawer **/
  const [attributionData, setAttributionData] = useState(null);

  if (isPracticeCampaignAttributionLoading) {
    return <Loading />;
  }

  return (
    <Box sx={{ mb: 6 }}>
      <TableContainer>
        <Table size="small" sx={{ borderRadius: "6px 6px 0 0", overflow: "hidden" }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Campaign</StyledTableCell>
              <StyledTableCell sx={{ textAlign: "center" }}>Patients Contacted</StyledTableCell>
              <StyledTableCell sx={{ textAlign: "center" }}>Form Leads</StyledTableCell>
              <StyledTableCell sx={{ textAlign: "center" }}>Phone Leads</StyledTableCell>
              <StyledTableCell sx={{ textAlign: "center" }}>Total Tracked Leads</StyledTableCell>
              <StyledTableCell sx={{ textAlign: "center" }}>Tracked Appointments</StyledTableCell>
              <StyledTableCell sx={{ textAlign: "center" }}>Inferred Appointments</StyledTableCell>
              <StyledTableCell sx={{ textAlign: "center" }}>Total Appointments</StyledTableCell>
              <StyledTableCell sx={{ textAlign: "center" }}>Average Revenue Per Visit (Historical)</StyledTableCell>
              <StyledTableCell sx={{ textAlign: "center" }}>Estimated Revenue</StyledTableCell>
            </TableRow>
          </TableHead>
          {campaignData && campaignData.length > 0 ? (
            <TableBody>
              {campaignData.map((campaign) => (
                <TableRow
                  key={campaign.practiceCampaignId}
                  className={
                    campaign.campaignType === "SKIN_SCREENING" && systemOfRecord === "aesthetic_record"
                      ? classes.hidden
                      : null
                  }
                >
                  <TableCell sx={{ fontWeight: 500 }}>{CAMPAIGN_COPY_MAP[campaign.campaignType].title}</TableCell>
                  <TableCell sx={{ textAlign: "right" }}>{campaign.patientsCount}</TableCell>
                  <TableCell sx={{ textAlign: "right" }}>{campaign.formLeadCount}</TableCell>
                  <TableCell sx={{ textAlign: "right" }}>{campaign.phoneLeadCount}</TableCell>
                  <TableCell sx={{ textAlign: "right" }}>{campaign.totalLeadCount}</TableCell>
                  <TableCell sx={{ textAlign: "right" }}>{campaign.trackedAppointments}</TableCell>
                  <TableCell sx={{ textAlign: "right" }}>{campaign.inferredAppointments}</TableCell>
                  <TableCell sx={{ textAlign: "right" }}>
                    {!!campaign.bookingsCount && (
                      <Typography
                        color="#0070E0"
                        typography="h5"
                        sx={{ "&:hover": { textDecoration: "underline", cursor: "pointer" } }}
                        onClick={() => setAttributionData(campaign)}
                      >
                        {campaign.bookingsCount}
                      </Typography>
                    )}
                    {!campaign.bookingsCount && 0}{" "}
                  </TableCell>
                  <TableCell sx={{ textAlign: "right" }}>{campaign.appointmentEstimatedRevenue}</TableCell>
                  <TableCell sx={{ textAlign: "right" }}>{campaign.estimatedRevenue}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell sx={{ fontWeight: 600, borderTop: "1px solid #A29AB2" }}>Total</TableCell>
                <TableCell sx={{ fontWeight: 500, textAlign: "right" }}>{totalPatients}</TableCell>
                <TableCell sx={{ fontWeight: 500, textAlign: "right" }}>{totalFormLeads}</TableCell>
                <TableCell sx={{ fontWeight: 500, textAlign: "right" }}>{totalPhoneLeads}</TableCell>
                <TableCell sx={{ fontWeight: 500, textAlign: "right" }}>{totalLeads}</TableCell>
                <TableCell sx={{ fontWeight: 500, textAlign: "right" }}>{totalTrackedAppointments}</TableCell>
                <TableCell sx={{ fontWeight: 500, textAlign: "right" }}>{totalInferredAppointments}</TableCell>
                <TableCell sx={{ fontWeight: 500, textAlign: "right" }}>{totalBookings}</TableCell>
                <TableCell sx={{ fontWeight: 500, textAlign: "right" }}>-</TableCell>
                <TableCell sx={{ fontWeight: 500, textAlign: "right" }}>{totalEstimateRevenue}</TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={10}>
                  <Typography>No data as of yet.</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Drawer anchor="top" open={!!attributionData} onClose={() => setAttributionData(null)}>
        <AttributionListDrawer
          practiceCampaignUuid={attributionData?.practiceCampaignId || ""}
          campaignType={CAMPAIGN_COPY_MAP[attributionData?.campaignType]?.title || ""}
          onClick={() => setAttributionData(null)}
        />
      </Drawer>
    </Box>
  );
};

export default AutomationResults;
