import React from "react";
import EmailCampaigns from "../../components/EmailCampaigns/EmailCampaigns";

const CampaignSettings = () => {
  return (
    <>
      <EmailCampaigns />
    </>
  );
};

export default CampaignSettings;
