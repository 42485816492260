import React from "react";
import classes from "../../styles/MetricsTable.module.css";
import { TableCell, TableRow } from "@mui/material";
import { ReactComponent as IconGreenUp } from "../../assets/icon-triangle-green-up.svg";
import { ReactComponent as IconRedDown } from "../../assets/icon-triangle-red-down.svg";
import { ReactComponent as IconGreenDown } from "../../assets/icon-triangle-green-down.svg";
import { ReactComponent as IconRedUp } from "../../assets/icon-triangle-red-up.svg";
import { percentageFormatter } from "../../data/valueFormatter";

const InsightsTableRow = ({ data, formatter, topRowLabel = "", label, negativeIsGood = false }) => {
  return (
    <>
      {topRowLabel && (
        <>
          <TableRow className={classes.sectionRow}>
            <TableCell colSpan={data.trendingLabels.length + 1} className={classes.sectionLabel}>
              {topRowLabel}
            </TableCell>
            <TableCell colSpan={3} className={[classes.sectionLabel, classes.bodySeparator]}>
              &nbsp;
            </TableCell>
            {data.timeTable === "years" && (
              <TableCell colSpan={3} className={[classes.sectionLabel, classes.bodySeparator]}>
                &nbsp;
              </TableCell>
            )}
          </TableRow>
        </>
      )}

      <TableRow className={classes.valueRow}>
        <TableCell className={classes.category}>{label}</TableCell>
        {data.trendingData.map((value, i) => (
          <TableCell key={i} align="right">
            {formatter(value)}
          </TableCell>
        ))}
        {data.yoyData.map((val, i) => (
          <TableCell className={i === 0 ? classes.bodySeparator : null} key={i} align="right">
            {formatter(val)}
          </TableCell>
        ))}
        <TableCell sx={{ whiteSpace: "nowrap" }} className={classes.indicatingCell} align="right">
          {percentageFormatter(data.yoyDifference)}
          {data.yoyDifference < 0 && !negativeIsGood && <IconRedDown />}
          {data.yoyDifference >= 0 && !negativeIsGood && <IconGreenUp />}
          {data.yoyDifference < 0 && negativeIsGood && <IconGreenDown />}
          {data.yoyDifference >= 0 && negativeIsGood && <IconRedUp />}
        </TableCell>
        {data.timeTable === "years" && (
          <>
            {data.ytdData.map((val, i) => (
              <TableCell className={i === 0 ? classes.bodySeparator : null} key={i} align="right">
                {formatter(val)}
              </TableCell>
            ))}
            <TableCell sx={{ whiteSpace: "nowrap" }} className={classes.indicatingCell} align="right">
              {percentageFormatter(data.ytdDifference)}
              {data.ytdDifference < 0 && !negativeIsGood && <IconRedDown />}
              {data.ytdDifference >= 0 && !negativeIsGood && <IconGreenUp />}
              {data.ytdDifference < 0 && negativeIsGood && <IconGreenDown />}
              {data.ytdDifference >= 0 && negativeIsGood && <IconRedUp />}
            </TableCell>
          </>
        )}
      </TableRow>
    </>
  );
};

export default InsightsTableRow;
