import { configureStore } from "@reduxjs/toolkit";

import uiSlice from "./ui-slice";
import practiceSlice from "./practice-slice";

const store = configureStore({
  reducer: {
    ui: uiSlice.reducer,
    practice: practiceSlice.reducer,
  },
});

export default store;
