import React from "react";
import { Link, useParams } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import Locations from "../components/Locations";
import { useQuery } from "@tanstack/react-query";
import ErrorBlock from "../components/UI/ErrorBlock";
import Loading from "../components/Loading";
import AddIcon from "@mui/icons-material/Add";
import { QUERY_KEY_PRACTICE_LOCATIONS } from "../store/constants";
import { fetchLocationPossibleValues, fetchPracticeLocations } from "../utils/api/practice-locations";

const PracticeLocations = () => {
  const params = useParams();
  const { data, isPending, isError, error } = useQuery({
    queryKey: [QUERY_KEY_PRACTICE_LOCATIONS, params.uuid],
    queryFn: async ({ signal }) => {
      const [locations, values] = await Promise.all([
        fetchPracticeLocations({ uuid: params.uuid, signal }),
        fetchLocationPossibleValues({ uuid: params.uuid, signal }),
      ]);
      return { locations, values };
    },
  });

  return (
    <Box>
      {isError && (
        <ErrorBlock
          title="An error occured."
          message={error.info?.message || "Failed to fetch practice locations. Please try again later"}
        />
      )}
      {isPending ? (
        <Loading />
      ) : (
        <>
          <Box sx={{ mb: 3 }}>
            {data.locations.length ? (
              <Locations locations={data.locations} values={data.values} />
            ) : (
              <Typography>No current locations</Typography>
            )}
          </Box>
          <Link to={`/practices/${params.uuid}/locations/add`}>
            <Button variant="contained" disableElevation startIcon={<AddIcon />}>
              Add Location
            </Button>
          </Link>
        </>
      )}
    </Box>
  );
};

export default PracticeLocations;
