import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { snakeCase } from "lodash";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createNewAppointmentStatus, fetchAppointmentStatuses, queryClient, updateDataType } from "../utils/http";
import ErrorBlock from "../components/UI/ErrorBlock";
import Loading from "../components/Loading";
import { useDispatch } from "react-redux";
import { uiActions } from "../store/ui-slice";
import StyledTableCell from "../components/StyledTableCell";
import Header from "../components/Header";
import DataTypesNav from "../components/DataTypesNav";

export default function DataTypeAppointmentStatus() {
  const dispatch = useDispatch();
  const [statuses, setStatuses] = useState([]);
  const [newStatus, setNewStatus] = useState("");
  const [newStatusDescription, setNewStatusDescription] = useState("");
  const [newStatusOpen, setNewStatusOpen] = useState(false);

  const [editTypeOpen, setEditTypeOpen] = useState(false);
  const [editTypeName, setEditTypeName] = useState("");
  const [editTypeId, setEditTypeId] = useState("");
  const [editTypeDescription, setEditTypeDescription] = useState("");

  const [loaded, setLoaded] = useState(false);

  const {
    data,
    isPending: isPendingStatusesFetch,
    isError: isErrorStatusesFetch,
    error: errorStatusesFetch,
  } = useQuery({
    queryKey: ["appointment-statuses"],
    queryFn: ({ signal, queryKey }) => fetchAppointmentStatuses({ signal, ...queryKey }),
    staleTime: 3000,
  });

  const {
    mutate,
    isPending: isPendingNewStatus,
    isError: isErrorNewStatus,
    error: errorNewStatus,
  } = useMutation({
    mutationFn: createNewAppointmentStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["appointment-statuses"] });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "New Appointment Status created.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  const handleNewStatusChange = (event) => {
    setNewStatus(event.target.value);
  };

  const handleNewStatusDescriptionChange = (event) => {
    setNewStatusDescription(event.target.value);
  };

  const handleNewStatusAdd = (event) => {
    event.preventDefault();
    let newStat = {};
    newStat.name = snakeCase(newStatus);
    newStat.display = newStatus;
    newStat.description = newStatusDescription;
    mutate(newStat);
    handleNewStatusClose();
  };

  const handleNewStatusClickOpen = () => {
    setNewStatusOpen(true);
  };

  const handleNewStatusClose = () => {
    setNewStatusOpen(false);
    setNewStatus("");
  };

  useEffect(() => {
    if (data) {
      setStatuses(data.items);
      setLoaded(true);
    }
  }, [data]);

  const handleEditTypeClose = () => {
    setEditTypeOpen(false);
    setEditTypeId("");
    setEditTypeName("");
    setEditTypeDescription("");
  };

  const handleEditTypeChange = (event) => {
    setEditTypeName(event.target.value);
  };

  const handleEditTypeDescriptionChange = (event) => {
    setEditTypeDescription(event.target.value);
  };

  const handleEditTypeChanged = (event) => {
    event.preventDefault();
    let dataType = {};
    dataType.name = snakeCase(editTypeName);
    dataType.display = editTypeName;
    dataType.description = editTypeDescription;
    mutateDataType({
      id: editTypeId,
      type: "appointments/statuses",
      data: dataType,
    });
    handleEditTypeClose();
  };

  const handleEditType = (id, display, description) => {
    setEditTypeOpen(true);
    setEditTypeId(id);
    setEditTypeName(display);
    setEditTypeDescription(description);
  };

  const { mutate: mutateDataType, isPending: isPendingUpdateDataType } = useMutation({
    mutationFn: updateDataType,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["appointment-statuses"] });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Data Type updated.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: ["appointment-statuses"] });
      dispatch(
        uiActions.showNotification({
          status: "error",
          message: "Error updating Data Type. Please try again later.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  return (
    <>
      <Header
        title="Data Types"
        action={
          <Box>
            <Button
              sx={{ my: { xs: 1, lg: 0 } }}
              variant="outlined"
              disableElevation
              startIcon={<AddIcon />}
              onClick={handleNewStatusClickOpen}
            >
              Add New Appointment Status
            </Button>
          </Box>
        }
      />
      <Box display="flex" sx={{ flexDirection: { xs: "column", md: "row" } }}>
        <DataTypesNav />
        <Box flex={1} sx={{ overflowX: "auto" }}>
          {isErrorStatusesFetch && (
            <ErrorBlock
              title="An error occured."
              message={
                errorStatusesFetch.info?.message || "Failed to fetch appointment statuses. Please try again later."
              }
            />
          )}
          {isErrorNewStatus && (
            <ErrorBlock
              title="An error occured."
              message={
                errorNewStatus.info?.message || "Failed to create new Appointment Status. Please check for duplicates."
              }
            />
          )}
          <Dialog
            open={newStatusOpen}
            onClose={handleNewStatusClose}
            PaperProps={{
              component: "form",
              onSubmit: handleNewStatusAdd,
            }}
            fullWidth
            maxWidth={"xs"}
          >
            <DialogTitle>
              <Typography variant="h4" component="p">
                Add Appointment Status
              </Typography>
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                required
                margin="dense"
                label="Appointment Status"
                type="text"
                fullWidth
                variant="outlined"
                value={newStatus}
                onChange={handleNewStatusChange}
                sx={{ mb: 3 }}
              />
              <TextField
                required
                value={newStatusDescription}
                fullWidth
                label="Description"
                multiline
                rows={4}
                onChange={handleNewStatusDescriptionChange}
              />
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 3 }}>
              <Button onClick={handleNewStatusClose}>Cancel</Button>
              <Button type="submit" variant="contained" disableElevation disabled={isPendingNewStatus}>
                {isPendingNewStatus ? "Saving..." : "Save"}
              </Button>
            </DialogActions>
          </Dialog>
          {isPendingStatusesFetch ? (
            <Loading />
          ) : (
            <Paper
              sx={{
                width: "100%",
                border: "1px solid #f0f0f0",
                borderRadius: 1.5,
                boxShadow: "none",
              }}
            >
              <TableContainer>
                <Table size="small" sx={{ borderRadius: "6px 6px 0 0", overflow: "hidden" }}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Appointment Status</StyledTableCell>
                      <StyledTableCell>Description</StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loaded && statuses.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={3}>No Current Appointment Statuses</TableCell>
                      </TableRow>
                    )}
                    {loaded && statuses.length > 0 && (
                      <>
                        {statuses.map((status) => (
                          <TableRow key={status.id} hover>
                            <TableCell sx={{ minWidth: 200 }}>{status.display}</TableCell>
                            <TableCell sx={{ minWidth: 200 }}>{status.description}</TableCell>
                            <TableCell>
                              <IconButton
                                size="small"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  handleEditType(status.id, status.display, status.description);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                        <Dialog
                          open={editTypeOpen}
                          onClose={handleEditTypeClose}
                          PaperProps={{
                            component: "form",
                            onSubmit: handleEditTypeChanged,
                          }}
                          fullWidth
                          maxWidth={"xs"}
                        >
                          <DialogTitle>
                            <Typography variant="h4" component="p">
                              Edit Appointment Status
                            </Typography>
                          </DialogTitle>
                          <DialogContent>
                            <TextField
                              autoFocus
                              required
                              margin="dense"
                              label="Appointment Status Name"
                              type="text"
                              fullWidth
                              variant="outlined"
                              value={editTypeName}
                              onChange={handleEditTypeChange}
                              sx={{ mb: 3 }}
                            />

                            <TextField
                              required
                              margin="dense"
                              label="Appointment Status Description"
                              type="text"
                              fullWidth
                              variant="outlined"
                              value={editTypeDescription}
                              multiline
                              rows={4}
                              onChange={handleEditTypeDescriptionChange}
                            />
                          </DialogContent>
                          <DialogActions sx={{ px: 3, pb: 3 }}>
                            <Button onClick={handleEditTypeClose}>Cancel</Button>
                            <Button
                              type="submit"
                              variant="contained"
                              disableElevation
                              disabled={isPendingUpdateDataType}
                            >
                              {isPendingUpdateDataType ? "Saving..." : "Save"}
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
        </Box>
      </Box>
    </>
  );
}
