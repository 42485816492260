import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Header from "../../components/Header";
import { Box, Button, Pagination, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import StyledTableCell from "../../components/StyledTableCell";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY_PRACTICE } from "../../store/constants";
import { fetchPractices } from "../../utils/api/practices.api";
import useCustomClaims from "../../utils/hooks/useCustomClaims";
import StatusBadge from "../../components/StatusBadge/StatusBadge";
import classes from "../../styles/table.module.scss";
import { ReactComponent as IconArrowOutwards } from "../../assets/icon-arrow-outwards.svg";
import useDebouncedFilter from "../../utils/hooks/useDebouncedFilter";
import SearchBox from "../../components/SearchBox";

const Practices = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [pageDetails, setPageDetails] = useState("");
  const { filter, setFilter, debouncedFilter } = useDebouncedFilter("", 300);
  const { isBonsaiAdmin } = useCustomClaims();

  const handleClearFilter = () => {
    setFilter("");
  };

  const navigate = useNavigate();

  const handlePagination = (event, value) => {
    if (value !== page) {
      setSearchParams("total", practices.meta.totalItems);
      setPage(value);
      navigate(`/practices?page=${value}`);
    }
  };

  const { data: practices, isPending } = useQuery({
    queryKey: [QUERY_KEY_PRACTICE, page, debouncedFilter],
    queryFn: async ({ signal }) => {
      return await fetchPractices({ signal, page, search: debouncedFilter });
    },
  });

  const goToPractice = (id) => {
    navigate(`/practices/${id}/campaigns`);
  };

  useEffect(() => {
    setPage(parseInt(searchParams.get("page")) || 1);
  }, [searchParams]);

  useEffect(() => {
    if (isPending || !practices) {
      return;
    }
    let currentTimesPer = practices.meta.currentPage * practices.meta.itemsPerPage;
    let from = currentTimesPer - practices.meta.itemsPerPage + 1;
    let to = currentTimesPer;
    if (to > practices.meta.totalItems) {
      to = practices.meta.totalItems;
    }
    setPageDetails(`(${from} - ${to}) of ${practices.meta.totalItems}`);
  }, [practices, isPending]);

  return (
    <>
      <Header
        title="Practices"
        action={
          !isBonsaiAdmin ? (
            ""
          ) : (
            <Link to="/practices/add">
              <Button variant="contained" disableElevation startIcon={<AddIcon />}>
                Add Practice
              </Button>
            </Link>
          )
        }
      />
      {(!isPending && practices && practices.data.length) || debouncedFilter.length !== 0 ? (
        <>
          <SearchBox
            value={filter}
            onChange={(event) => setFilter(event.target.value)}
            handleClearFilter={handleClearFilter}
          />
          <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
            <Table sx={{ minWidth: 700 }} aria-label="Practices table" size="small">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>SOR</StyledTableCell>
                  <StyledTableCell>Locations</StyledTableCell>
                  <StyledTableCell>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {practices?.data?.map((practice, i) => (
                  <TableRow key={i} className={classes.tableRow} onClick={() => goToPractice(practice.uuid)}>
                    <TableCell component="th" scope="row" sx={{ fontWeight: 500 }}>
                      {practice.name}
                    </TableCell>
                    <TableCell>
                      <StatusBadge status={practice.status} />
                    </TableCell>
                    <TableCell>{practice.systemOfRecord}</TableCell>
                    <TableCell>{practice.numberOfLocations}</TableCell>
                    <TableCell onClick={(e) => e.stopPropagation()}>
                      <Link to={`/practices/${practice.uuid}/campaigns`}>
                        <Typography
                          variant="body2"
                          component="span"
                          className={classes.viewLink}
                          sx={{ display: "inline-flex", alignItems: "center" }}
                        >
                          View <IconArrowOutwards />
                        </Typography>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            p={1}
            my={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderRadius={1.5}
            sx={{ backgroundColor: "primary.lighter" }}
          >
            <Pagination
              count={practices?.meta?.totalPages ?? 0}
              color="primary"
              onChange={handlePagination}
              size="small"
              shape="rounded"
              page={page}
            />
            <Typography variant="body2">{pageDetails}</Typography>
          </Box>
        </>
      ) : (
        <Box padding={3}>
          <Typography>No practices</Typography>
        </Box>
      )}
    </>
  );
};

export default Practices;
