import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import MuiDrawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import imgNavOpen from "../../assets/nav-open.svg";
import imgNavClose from "../../assets/nav-close.svg";
import imgLogo from "../../assets/bonsai.svg";
import { Button, Collapse, Typography } from "@mui/material";
import { menuItems } from "../../data/menuItems";
import { insightsMenuItems } from "../../data/insightsMenuItems";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../store/ui-slice";
import classes from "./SideNav.module.scss";
import { ReactComponent as IconPractices } from "../../assets/icon-practices.svg";

import { QUERY_KEY_PRACTICE_FEATURE_TOGGLES, SIDE_NAV_WIDTH } from "../../store/constants";
import useCustomClaims from "../../utils/hooks/useCustomClaims";
import { ReactComponent as IconChevronDown } from "../../assets/icon-chevron-down.svg";
import { ReactComponent as IconChevronUp } from "../../assets/icon-chevron-up.svg";
import { useQuery } from "@tanstack/react-query";
import { fetchFeatureToggles } from "../../utils/api/feature-toggles.api";

const openedMixin = (theme) => ({
  backgroundColor: "#F2F0F8",
  border: "none",
  width: SIDE_NAV_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  backgroundColor: "#F2F0F8",
  border: "none",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: SIDE_NAV_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const ListLabel = styled(Typography)({
  color: "#7D7A96",
  fontSize: "12px",
  fontWeight: 600,
  textTransform: "uppercase",
  padding: "12px 12px 0 24px",
});

const SideNav = () => {
  const params = useParams();
  const uuid = params.uuid;
  const dispatch = useDispatch();
  const navIsExpanded = useSelector((state) => state.ui.navIsExpanded);
  const { isMultiPracticeUser } = useCustomClaims();
  const [navItems, setNavItems] = useState([]);

  // Fetch feature toggles
  const { data: practiceFeatureToggles } = useQuery({
    queryKey: [QUERY_KEY_PRACTICE_FEATURE_TOGGLES, uuid],
    queryFn: async ({ signal }) => {
      const { items } = await fetchFeatureToggles({ signal, practiceUuid: uuid });
      return items.reduce(
        (practiceToggleMap, practiceToggle) => ({
          ...practiceToggleMap,
          [practiceToggle.name]: practiceToggle.value,
        }),
        {}
      );
    },
    enabled: !!uuid,
  });

  const toggleNavHandler = () => {
    dispatch(uiActions.toggleNav());
  };

  const [selectedMenuItem, setSelectedMenuItem] = useState("");

  const handleSelectMenuItem = (url) => {
    if (selectedMenuItem === url) {
      setSelectedMenuItem("");
    } else {
      setSelectedMenuItem(url);
    }
  };

  useEffect(() => {
    if (practiceFeatureToggles && practiceFeatureToggles.analytics) {
      setNavItems([...insightsMenuItems, ...menuItems]);
    } else {
      setNavItems([...menuItems]);
    }
  }, [practiceFeatureToggles]);

  return (
    <Drawer variant="permanent" open={navIsExpanded} sx={{ display: { xs: "none", md: "flex" } }}>
      <DrawerHeader>
        {navIsExpanded && <Box component="img" src={imgLogo} height="22px" sx={{ ml: 1 }} />}
        <Button
          disableElevation
          type="button"
          sx={{ p: 1, minWidth: 0, ...(!navIsExpanded && { mx: "auto" }) }}
          onClick={toggleNavHandler}
        >
          {navIsExpanded ? <img src={imgNavOpen} alt="Close Nav" /> : <img src={imgNavClose} alt="Open Nav" />}
        </Button>
      </DrawerHeader>

      <Box component="nav" pb={4}>
        <Box>
          <List>
            <ListItem
              sx={{ py: 0, px: 1 }}
              className={classes.navlist}
              onClick={() => {
                handleSelectMenuItem("practices");
              }}
            >
              {isMultiPracticeUser && (
                <NavLink
                  to="/practices"
                  title="Practices"
                  className={({ isActive }) => (isActive ? classes.active : classes.navlink)}
                  end
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: navIsExpanded ? 2 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <IconPractices />
                  </ListItemIcon>
                  <ListItemText
                    primary="All Practices"
                    sx={{
                      display: navIsExpanded ? "block" : "none",
                      color: "#33313A",
                    }}
                  />
                </NavLink>
              )}
            </ListItem>
          </List>
        </Box>
        {params.uuid && (
          <>
            {navItems.map((menuItem, i) => (
              <React.Fragment key={i}>
                {menuItem.label && (
                  <ListLabel sx={{ display: navIsExpanded ? "block" : "none" }}>{menuItem.label}</ListLabel>
                )}
                <List>
                  {menuItem.items.map((item, index) => (
                    <React.Fragment key={index}>
                      <ListItem
                        sx={{ py: 0, px: 1 }}
                        className={classes.navlist}
                        onClick={() => {
                          handleSelectMenuItem(item.url);
                        }}
                      >
                        <NavLink
                          to={`/practices/${params.uuid}/${item.url}`}
                          title={item.title}
                          className={({ isActive }) => (isActive ? classes.active : classes.navlink)}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: navIsExpanded ? 2 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={item.title}
                            sx={{
                              display: navIsExpanded ? "block" : "none",
                              color: "#33313A",
                              flex: "none",
                              pr: 0.5,
                            }}
                          />
                          {item.children && navIsExpanded && (
                            <>{item.url === selectedMenuItem ? <IconChevronUp /> : <IconChevronDown />}</>
                          )}
                        </NavLink>
                      </ListItem>
                      {item.children && navIsExpanded && (
                        <Collapse in={item.url === selectedMenuItem} timeout="auto" unmountOnExit>
                          {item.children.map((child) => (
                            <ListItem key={child.url}>
                              <NavLink
                                to={`/practices/${params.uuid}/${item.url}/${child.url}`}
                                className={({ isActive }) => (isActive ? classes.childLinkActive : classes.childLink)}
                              >
                                {child.title}
                              </NavLink>
                            </ListItem>
                          ))}
                        </Collapse>
                      )}
                    </React.Fragment>
                  ))}
                </List>
              </React.Fragment>
            ))}
          </>
        )}
      </Box>
    </Drawer>
  );
};

export default SideNav;
