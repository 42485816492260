import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { QUERY_KEY_DATA_TYPE_POLICY } from "../store/constants";
import { createNewPolicy, fetchPolicyTypes, queryClient, updateDataType } from "../utils/http";
import { uiActions } from "../store/ui-slice";
import { snakeCase } from "lodash";
import Header from "../components/Header";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DataTypesNav from "../components/DataTypesNav";
import ErrorBlock from "../components/UI/ErrorBlock";
import Loading from "../components/Loading";
import StyledTableCell from "../components/StyledTableCell";

const DataTypePolicy = () => {
  const dispatch = useDispatch();
  const [policyTypes, setPolicyTypes] = useState([]);
  const [newPolicy, setNewPolicy] = useState("");
  const [newPolicyDescription, setNewPolicyDescription] = useState("");
  const [newPolicyOpen, setNewPolicyOpen] = useState(false);

  const [editTypeOpen, setEditTypeOpen] = useState(false);
  const [editTypeName, setEditTypeName] = useState("");
  const [editTypeId, setEditTypeId] = useState("");
  const [editTypeDescription, setEditTypeDescription] = useState("");
  const [loaded, setLoaded] = useState(false);

  const {
    data,
    isPending: isPendingPolicyFetch,
    isError: isErrorPolicyFetch,
    error: errorPolicyFetch,
  } = useQuery({
    queryKey: [QUERY_KEY_DATA_TYPE_POLICY],
    queryFn: ({ signal, queryKey }) => fetchPolicyTypes({ signal, ...queryKey }),
  });

  const {
    mutate,
    isPending: isPendingNewPolicy,
    isError: isErrorNewPolicy,
    error: errorNewPolicy,
  } = useMutation({
    mutationFn: createNewPolicy,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_DATA_TYPE_POLICY] });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "New Policy Type created.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  const handleNewPolicyChange = (event) => {
    setNewPolicy(event.target.value);
  };

  const handleNewPolicyDescriptionChange = (event) => {
    setNewPolicyDescription(event.target.value);
  };

  const handleNewPolicyClose = () => {
    setNewPolicyOpen(false);
    setNewPolicy("");
  };

  const handleNewPolicyClickOpen = () => {
    setNewPolicyOpen(true);
  };

  const handleNewPolicyAdd = (event) => {
    event.preventDefault();
    let newItem = {};
    newItem.name = snakeCase(newPolicy);
    newItem.display = newPolicy;
    newItem.description = newPolicyDescription;
    mutate(newItem);
    handleNewPolicyClose();
  };

  const handleEditTypeChange = (event) => {
    setEditTypeName(event.target.value);
  };

  const handleEditTypeDescriptionChange = (event) => {
    setEditTypeDescription(event.target.value);
  };

  const handleEditTypeChanged = (event) => {
    event.preventDefault();
    let dataType = {};
    dataType.name = snakeCase(editTypeName);
    dataType.display = editTypeName;
    dataType.description = editTypeDescription;
    mutateDataType({
      id: editTypeId,
      type: "policy/types",
      data: dataType,
    });
    handleEditTypeClose();
  };

  const handleEditTypeClose = () => {
    setEditTypeOpen(false);
    setEditTypeId("");
    setEditTypeName("");
    setEditTypeDescription("");
  };

  const handleEditType = (id, display, description) => {
    setEditTypeOpen(true);
    setEditTypeId(id);
    setEditTypeName(display);
    setEditTypeDescription(description);
  };

  const { mutate: mutateDataType, isPending: isPendingUpdateDataType } = useMutation({
    mutationFn: updateDataType,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_DATA_TYPE_POLICY],
      });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Data Type updated.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_DATA_TYPE_POLICY],
      });
      dispatch(
        uiActions.showNotification({
          status: "error",
          message: "Error updating Data Type. Please try again later.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });
  useEffect(() => {
    if (data) {
      setPolicyTypes(data.items);
      setLoaded(true);
    }
  }, [data]);

  return (
    <>
      <Header
        title="Data Types"
        action={
          <Box>
            <Button
              sx={{ my: { xs: 1, lg: 0 } }}
              variant="outlined"
              disableElevation
              startIcon={<AddIcon />}
              onClick={handleNewPolicyClickOpen}
            >
              Add New Policy Type
            </Button>
          </Box>
        }
      />
      <Box display="flex" sx={{ flexDirection: { xs: "column", md: "row" } }}>
        <DataTypesNav />
        <Box flex={1} sx={{ overflowX: "auto" }}>
          {isErrorPolicyFetch && (
            <ErrorBlock
              title="An error occured."
              message={errorPolicyFetch.info?.message || "Failed to fetch Policy Types. Please try again later."}
            />
          )}
          {isErrorNewPolicy && (
            <ErrorBlock
              title="An error occured."
              message={
                errorNewPolicy.infor?.message || "Failed to create new Policy Type. Please check for duplicates."
              }
            />
          )}
          <Dialog
            open={newPolicyOpen}
            onClose={handleNewPolicyClose}
            PaperProps={{
              component: "form",
              onSubmit: handleNewPolicyAdd,
            }}
            fullWidth
            maxWidth={"xs"}
          >
            <DialogTitle>
              <Typography variant="h4" component="p">
                Add Policy Type
              </Typography>
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                required
                margin="dense"
                label="Policy Type"
                type="text"
                fullWidth
                variant="outlined"
                value={newPolicy}
                onChange={handleNewPolicyChange}
                sx={{ mb: 3 }}
              />
              <TextField
                required
                value={newPolicyDescription}
                fullWidth
                label="Description"
                multiline
                rows={4}
                onChange={handleNewPolicyDescriptionChange}
              />
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 3 }}>
              <Button onClick={handleNewPolicyClose}>Cancel</Button>
              <Button type="submit" variant="contained" disableElevation disabled={isPendingNewPolicy}>
                {isPendingNewPolicy ? "Saving..." : "Save"}
              </Button>
            </DialogActions>
          </Dialog>
          {isPendingPolicyFetch ? (
            <Loading />
          ) : (
            <Paper
              sx={{
                width: "100%",
                border: "1px solid #f0f0f0",
                borderRadius: 1.5,
                boxShadow: "none",
              }}
            >
              <TableContainer>
                <Table size="small" sx={{ borderRadius: "6px 6px 0 0", overflow: "hidden" }}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Policy Type</StyledTableCell>
                      <StyledTableCell>Description</StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loaded && policyTypes.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={3}>No Current Policy Types</TableCell>
                      </TableRow>
                    )}
                    {loaded && policyTypes.length > 0 && (
                      <>
                        {policyTypes.map((policyType) => (
                          <TableRow key={policyType.id} hover>
                            <TableCell sx={{ minWidth: 200 }}>{policyType.display}</TableCell>
                            <TableCell sx={{ minWidth: 200 }}>{policyType.description}</TableCell>
                            <TableCell>
                              <IconButton
                                size="small"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  handleEditType(policyType.id, policyType.display, policyType.description);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                        <Dialog
                          open={editTypeOpen}
                          onClose={handleEditTypeClose}
                          PaperProps={{
                            component: "form",
                            onSubmit: handleEditTypeChanged,
                          }}
                          fullWidth
                          maxWidth={"xs"}
                        >
                          <DialogTitle>
                            <Typography variant="h4" component="p">
                              Edit Policy Type
                            </Typography>
                          </DialogTitle>
                          <DialogContent>
                            <TextField
                              autoFocus
                              required
                              margin="dense"
                              label="Policy Type"
                              type="text"
                              fullWidth
                              variant="outlined"
                              value={editTypeName}
                              onChange={handleEditTypeChange}
                              sx={{ mb: 3 }}
                            />

                            <TextField
                              required
                              margin="dense"
                              label="Description"
                              type="text"
                              fullWidth
                              variant="outlined"
                              value={editTypeDescription}
                              multiline
                              rows={4}
                              onChange={handleEditTypeDescriptionChange}
                            />
                          </DialogContent>
                          <DialogActions sx={{ px: 3, pb: 3 }}>
                            <Button onClick={handleEditTypeClose}>Cancel</Button>
                            <Button
                              type="submit"
                              variant="contained"
                              disableElevation
                              disabled={isPendingUpdateDataType}
                            >
                              {isPendingUpdateDataType ? "Saving..." : "Save"}
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
        </Box>
      </Box>
    </>
  );
};

export default DataTypePolicy;
