import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  page: 1,
};

const practiceSlice = createSlice({
  name: "practice",
  initialState: initialState,
  reducers: {
    setPage(state, action) {
      state.page = action.payload;
    },
  },
});

export const practiceActions = practiceSlice.actions;
export default practiceSlice;
