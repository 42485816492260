import { dataIngestionClient } from "../dataIngestionClient";

export async function fetchPracticeIntegrations({ uuid, signal }) {
  try {
    const integrations = await dataIngestionClient.get(`api/automated-ingestion/${uuid}`, { signal });
    return integrations;
  } catch (error) {
    const errorData = new Error("An error occurerred while fetching integrations");
    errorData.code = error.response.status;
    errorData.info = error.response.data;
    throw errorData;
  }
}

export async function updatePracticeIntegration({ uuid, data, sor }) {
  try {
    const response = await dataIngestionClient.post(`api/automated-ingestion/${uuid}/${sor}`, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.status === 201;
  } catch (error) {
    const errorData = new Error("An error occurred while saving integration");
    errorData.code = error.response.status;
    errorData.info = error.response.data;
    throw errorData;
  }
}

export async function historySynchronize({ uuid }) {
  try {
    const response = await dataIngestionClient.post(`api/automated-ingestion/${uuid}/history-sync`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.status === 201;
  } catch (error) {
    const errorData = new Error("An error occurred while synchronizing integration");
    errorData.code = error.response.status;
    errorData.info = error.response.data;
    throw errorData;
  }
}

export async function historySynchronizeCancel({ uuid }) {
  try {
    const response = await dataIngestionClient.post(`api/automated-ingestion/${uuid}/history-sync/cancel`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.status === 201;
  } catch (error) {
    const errorData = new Error("An error occurred while cancelling synchronizing");
    errorData.code = error.response.status;
    errorData.info = error.response.data;
    throw errorData;
  }
}
