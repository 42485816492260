import React from "react";
import { TableCell, TableRow } from "@mui/material";
import classes from "../../styles/MetricsTable.module.css";

const InsightsTableColumnHeaders = ({ trendingLabels, yoyLabels, ytdLabels, timeTable }) => {
  return (
    <TableRow className={classes.topRow}>
      <TableCell></TableCell>
      {trendingLabels.map((period, i) => (
        <TableCell key={i} align="center">
          {period}
        </TableCell>
      ))}
      {yoyLabels.map((period, i) => (
        <TableCell className={i === 0 ? classes.bodySeparator : null} key={i} align="center">
          {period}
        </TableCell>
      ))}
      <TableCell align="center">Y/Y %</TableCell>
      {timeTable === "years" && (
        <>
          {ytdLabels.map((period, i) => (
            <TableCell className={i === 0 ? classes.bodySeparator : null} key={i} align="center">
              {period}
            </TableCell>
          ))}
          <TableCell align="center">Y/Y %</TableCell>
        </>
      )}
    </TableRow>
  );
};

export default InsightsTableColumnHeaders;