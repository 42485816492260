import { dataIngestionClient } from "../dataIngestionClient";

export async function fetchInsightsMetrics({ practiceUuid, metric, timetable, location, signal }) {
  let fetchUrl = `api/practices/${practiceUuid}/performance-metrics?timetable=${timetable}`;
  if (location !== "all") {
    fetchUrl += `&locationUuid=${location}`;
  }
  try {
    const response = await dataIngestionClient.get(fetchUrl, { signal });
    return response.data;
  } catch (error) {
    const errorData = new Error("An error occured fetching performance metric");
    errorData.code = error.response.status;
    errorData.info = error.response.data;
    throw errorData;
  }
}
