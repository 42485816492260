import * as React from "react";
import {
  Box,
  Button,
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Avatar from "@mui/material/Avatar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link, NavLink, useParams } from "react-router-dom";
import useCustomClaims from "../../utils/hooks/useCustomClaims";
import imgLogo from "../../assets/bonsai.svg";
import imgNavClose from "../../assets/nav-close.svg";
import imgNavOpen from "../../assets/nav-open.svg";
import { menuItems } from "../../data/menuItems";
import { insightsMenuItems } from "../../data/insightsMenuItems";
import { QUERY_KEY_PRACTICE, QUERY_KEY_PRACTICE_FEATURE_TOGGLES, SIDE_NAV_WIDTH } from "../../store/constants";
import classes from "./TopNav.module.scss";
import { ReactComponent as IconPractices } from "../../assets/icon-practices.svg";
import { useQuery } from "@tanstack/react-query";
import { fetchPractice } from "../../utils/api/practices.api";
import { fetchFeatureToggles } from "../../utils/api/feature-toggles.api";

const TopNav = () => {
  const { isBonsaiAdmin } = useCustomClaims();
  const params = useParams();
  const uuid = params.uuid;
  const [navItems, setNavItems] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { user, logout } = useAuth0();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const handleMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  const { isMultiPracticeUser } = useCustomClaims();
  const [selectedMenuItem, setSelectedMenuItem] = React.useState("");

  const handleSelectMenuItem = (url) => {
    if (selectedMenuItem === url) {
      setSelectedMenuItem("");
    } else {
      setSelectedMenuItem(url);
    }
  };

  /** Fetch Practice **/
  const { data } = useQuery({
    queryKey: [QUERY_KEY_PRACTICE, uuid],
    queryFn: async ({ signal }) => {
      const [practice] = await Promise.all([fetchPractice({ uuid, signal })]);
      return { practice };
    },
    enabled: !!uuid,
  });

  // Fetch feature toggles
  const { data: practiceFeatureToggles } = useQuery({
    queryKey: [QUERY_KEY_PRACTICE_FEATURE_TOGGLES, uuid],
    queryFn: async ({ signal }) => {
      const { items } = await fetchFeatureToggles({ signal, practiceUuid: uuid });
      return items.reduce(
        (practiceToggleMap, practiceToggle) => ({
          ...practiceToggleMap,
          [practiceToggle.name]: practiceToggle.value,
        }),
        {}
      );
    },
    enabled: !!uuid,
  });

  useEffect(() => {
    window.addEventListener("resize", handleMobileNavClose, false);
    if (practiceFeatureToggles && practiceFeatureToggles.analytics) {
      setNavItems([...insightsMenuItems, ...menuItems]);
    } else {
      setNavItems([...menuItems]);
    }
  }, [practiceFeatureToggles]);

  return (
    <Box
      display="flex"
      alignItems="center"
      px={2}
      py={1}
      sx={{ borderBottom: "1px solid #EBEFF3", justifyContent: { xs: "space-between", md: "flex-end" } }}
    >
      <Box sx={{ display: { xs: "flex", md: "none" }, alignItems: "center" }}>
        <Button disableElevation type="button" sx={{ minWidth: 0, padding: "5px" }} onClick={handleMobileNavOpen}>
          <img src={imgNavClose} alt="Open Nav" />
        </Button>
        <Drawer open={mobileNavOpen} onClose={handleMobileNavClose}>
          <Box sx={{ width: SIDE_NAV_WIDTH, py: 2, px: 1 }}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
              <Box component="img" src={imgLogo} height="22px" sx={{ ml: 1 }} />
              <Button
                disableElevation
                type="button"
                sx={{ minWidth: 0, padding: "5px" }}
                onClick={handleMobileNavClose}
              >
                <img src={imgNavOpen} alt="Close Nav" />
              </Button>
            </Box>
            <List>
              <ListItem
                className={classes.navlist}
                onClick={() => {
                  handleSelectMenuItem("practices");
                }}
              >
                {isMultiPracticeUser && (
                  <NavLink
                    onClick={handleMobileNavClose}
                    to="/practices"
                    className={({ isActive }) => (isActive ? classes.active : classes.navlink)}
                    end
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        justifyContent: "center",
                      }}
                    >
                      <IconPractices />
                    </ListItemIcon>
                    All Practices
                  </NavLink>
                )}
              </ListItem>
              {params.uuid && (
                <>
                  {navItems.map((menuItem, i) => (
                    <React.Fragment key={i}>
                      {menuItem.label && (
                        <Typography
                          color="primary.light"
                          variant="body2"
                          sx={{
                            p: 1,
                            fontWeight: 600,
                            padding: "12px 12px 8px 0",
                            textTransform: "uppercase",
                            fontSize: "12px",
                          }}
                        >
                          {menuItem.label}
                        </Typography>
                      )}
                      {menuItem.items.map((item, index) => (
                        <React.Fragment key={index}>
                          <ListItem
                            sx={{ py: 0, px: 1 }}
                            className={classes.navlist}
                            onClick={() => {
                              handleSelectMenuItem(item.url);
                            }}
                          >
                            <NavLink
                              to={`/practices/${params.uuid}/${item.url}`}
                              title={item.title}
                              className={({ isActive }) => (isActive ? classes.active : classes.navlink)}
                              onClick={item.children ? null : handleMobileNavClose}
                            >
                              <ListItemIcon
                                sx={{
                                  minWidth: 0,
                                  justifyContent: "center",
                                }}
                              >
                                {item.icon}
                              </ListItemIcon>
                              <ListItemText
                                primary={item.title}
                                sx={{
                                  color: "#33313A",
                                  flex: "none",
                                  pr: 0.5,
                                }}
                              />
                            </NavLink>
                          </ListItem>
                          {item.children && (
                            <Collapse in={item.url === selectedMenuItem} timeout="auto" unmountOnExit>
                              {item.children.map((child) => (
                                <ListItem key={child.url}>
                                  <NavLink
                                    to={`/practices/${params.uuid}/${item.url}/${child.url}`}
                                    className={({ isActive }) =>
                                      isActive ? classes.childLinkActive : classes.childLink
                                    }
                                    onClick={handleMobileNavClose}
                                  >
                                    {child.title}
                                  </NavLink>
                                </ListItem>
                              ))}
                            </Collapse>
                          )}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                </>
              )}
            </List>
          </Box>
        </Drawer>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ width: "100%" }}>
        <Box>
          {data?.practice && (
            <Typography sx={{ textWrap: "wrap", lineHeight: 1.2, ml: 1 }} component="p" variant="bodyBiotifMedium">
              {data.practice.name}
            </Typography>
          )}
        </Box>
        <Box>
          <Button
            component="label"
            sx={{ borderRadius: "26px" }}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            endIcon={<ExpandMoreIcon />}
          >
            <Avatar
              src={user.picture}
              sx={{
                mr: 2,
              }}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              color="inherit"
            />
            <Typography
              component="span"
              variant="bodySemi"
              color="primary.dark"
              textTransform="none"
              sx={{ display: { xs: "none", md: "block" } }}
            >
              {user.name}
            </Typography>
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {isBonsaiAdmin && (
              <MenuItem onClick={handleClose}>
                <Link to="/data-types/appointment/type" style={{ textDecoration: "none", color: "inherit" }}>
                  Bonsai Data Types
                </Link>
              </MenuItem>
            )}
            <MenuItem onClick={() => logout()}>Logout</MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  );
};

export default TopNav;
