import { React, useContext, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  TextField,
  Autocomplete,
  Chip,
} from "@mui/material";
import { icd10 } from "../data/icd10";
import { useMutation } from "@tanstack/react-query";
import { updatePracticeRecallType } from "../utils/api/practice-recall-types";
import { QUERY_KEY_PRACTICE_RECALL_TYPES } from "../store/constants";
import { useDispatch } from "react-redux";
import { uiActions } from "../store/ui-slice";
import { queryClient } from "../utils/http";
import { SelectedRecallTypeContext } from "./PracticeRecallDiagnosisCodesTable";

const PracticeRecallTypeEditDialog = ({ isOpen, onClose, practiceUuid }) => {
  const recallType = useContext(SelectedRecallTypeContext);
  const dispatch = useDispatch();
  const [exclusionCodes, setExclusionCodes] = useState([]);
  const [inclusionCodes, setInclusionCodes] = useState([]);

  const { mutate: updateRecallType, isPending } = useMutation({
    mutationFn: updatePracticeRecallType,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_PRACTICE_RECALL_TYPES] });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Diagnosis type updated.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_PRACTICE_RECALL_TYPES] });
      dispatch(
        uiActions.showNotification({
          status: "error",
          message: "Error updating diagnosis type. Please try again later.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
    onSettled: () => {
      onInternalClose();
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    updateRecallType({
      id: recallType.id,
      practiceUuid: practiceUuid,
      data: {
        inclusionIcdCodes: inclusionCodes,
        exclusionIcdCodes: exclusionCodes,
      },
    });
    onInternalClose();
  };

  const handleExclusionIcdCodesChange = (event, value) => {
    setExclusionCodes(value);
  };

  const handleInclusionIcdCodesChange = (event, value) => {
    setInclusionCodes(value);
  };

  const onInternalClose = () => {
    setExclusionCodes([]);
    setInclusionCodes([]);
    onClose();
  };

  useEffect(() => {
    if (recallType) {
      setExclusionCodes(recallType.exclusionIcdCodes);
      setInclusionCodes(recallType.inclusionIcdCodes);
    }
  }, [recallType]);

  return (
    <Dialog
      open={isOpen}
      onClose={onInternalClose}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit,
      }}
      fullWidth
      maxWidth={"xs"}
    >
      <DialogTitle>
        <Typography variant="h4" component="p">
          Edit {recallType?.bonsaiDiagnosisCode.display || ""} Diagnosis Type
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          sx={{ my: 3 }}
          multiple
          id="exclusion-icd-codes"
          options={recallType?.bonsaiDiagnosisCode.icdCodes || []}
          defaultValue={[]}
          value={exclusionCodes}
          onChange={handleExclusionIcdCodesChange}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)
          }
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Exclusion ICD10 Codes" placeholder="" />
          )}
        />

        <Autocomplete
          sx={{ my: 3 }}
          multiple
          id="inclusion-icd-codes"
          options={icd10.map((option) => option.code)}
          defaultValue={[]}
          value={inclusionCodes}
          onChange={handleInclusionIcdCodesChange}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)
          }
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Inclusion ICD10 Codes" placeholder="" />
          )}
        />
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 3 }}>
        <Button onClick={onInternalClose}>Cancel</Button>
        <Button type="submit" variant="contained" disableElevation disabled={isPending}>
          {isPending ? "Saving..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PracticeRecallTypeEditDialog;
