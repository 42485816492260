import { Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BarChart from "../../components/Charts/BarChart";
import InsightsFilters from "../../components/Insights/InsightsFilters";
import PerformanceTable from "../../components/Insights/PerformanceTable";
import Loading from "../../components/Loading";
import ErrorBlock from "../../components/UI/ErrorBlock";
import { QUERY_KEY_INSIGHTS_METRICS, QUERY_KEY_PRACTICE_LOCATIONS } from "../../store/constants";
import { fetchInsightsMetrics } from "../../utils/api/insights-metrics.api";
import { fetchPracticeLocations } from "../../utils/http";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as IconActivityHeart } from "../../assets/icon-activity-heart.svg";
import { ReactComponent as IconBank } from "../../assets/icon-bank.svg";
import { ReactComponent as IconBreakout } from "../../assets/icon-breakout.svg";
import { ReactComponent as IconMedicalCircle } from "../../assets/icon-medical-circle.svg";
import { currencyFormatter, numberFormatter, percentageFormatter } from "../../data/valueFormatter";
import { capitalizeString } from "../../data/stringFormatter";

const Performance = () => {
  const [selectedFrequency, setSelectedFrequency] = React.useState("monthly");
  const [selectedType, setSelectedType] = React.useState("Charts");
  const [selectedRevenue, setSelectedRevenue] = useState("net");
  const [selectedLocation, setSelectedLocation] = useState("all");
  const [locations, setLocations] = useState([]);
  const [metricData, setMetricData] = useState([]);
  const theme = useTheme();
  const label = "Performance";

  const { uuid: practiceUuid } = useParams();

  const { data, isPending, isError, error } = useQuery({
    queryKey: [
      QUERY_KEY_INSIGHTS_METRICS,
      QUERY_KEY_PRACTICE_LOCATIONS,
      practiceUuid,
      selectedFrequency,
      selectedLocation,
    ],
    queryFn: async ({ signal }) => {
      const [locations, metricData] = await Promise.all([
        fetchPracticeLocations({ uuid: practiceUuid, signal }),
        fetchInsightsMetrics({ practiceUuid, timetable: selectedFrequency, location: selectedLocation, signal }),
      ]);
      return { locations, metricData };
    },
  });

  useEffect(() => {
    if (data) {
      if (data.locations) {
        setLocations(data.locations.locations);
      }
      if (data.metricData) {
        setMetricData(data.metricData);
      }
    }
  }, [data]);
  return (
    <>
      {isError && (
        <ErrorBlock
          title="An error occured."
          message={error.info?.message || "Failed to fetch Performance Metrics. Please try again later"}
        />
      )}
      <InsightsFilters
        setSelectedFrequency={setSelectedFrequency}
        setSelectedType={setSelectedType}
        setSelectedRevenue={setSelectedRevenue}
        setSelectedLocation={setSelectedLocation}
        label={label}
        selectedType={selectedType}
        revenueFilter
        locationFilter={locations.length > 1}
      />
      {isPending ? (
        <Loading />
      ) : (
        <>
          <PerformanceTable label={label} type={selectedType} metricData={metricData} revenueType={selectedRevenue} />
          <BarChart
            metricData={metricData}
            type={selectedType}
            metric={`${selectedRevenue}Revenue`}
            priorColor={theme.palette.primary.gray200}
            currentColor={theme.palette.primary.turquoise500}
            heading="Revenue"
            subHeading={`Total ${selectedRevenue} revenue`}
            icon={<IconBank />}
            formatter={currencyFormatter}
            margin={{ top: 60, left: 96 }}
          />

          <Grid container spacing={4} sx={{ my: 0.5 }}>
            <Grid item xs={12} md={6}>
              <BarChart
                metricData={metricData}
                type={selectedType}
                metric={`${selectedRevenue}RevenuePerVisit`}
                priorColor={theme.palette.primary.gray200}
                currentColor={theme.palette.primary.turquoise500}
                heading="Revenue Per Visit"
                subHeading={`Average ${selectedRevenue} revenue per paid visit`}
                icon={<IconBreakout />}
                formatter={currencyFormatter}
                margin={{ top: 60, left: 70 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BarChart
                metricData={metricData}
                type={selectedType}
                metric={`annual${capitalizeString(selectedRevenue)}RevenuePerPatient`}
                priorColor={theme.palette.primary.gray200}
                currentColor={theme.palette.primary.turquoise500}
                heading="Annual Revenue Per Patient"
                subHeading={`Annual ${selectedRevenue} revenue per patient`}
                icon={<IconBreakout />}
                formatter={currencyFormatter}
                margin={{ top: 60, left: 70 }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <BarChart
                metricData={metricData}
                type={selectedType}
                metric="newPatients"
                priorColor={theme.palette.primary.gray200}
                currentColor={theme.palette.primary.turquoise500}
                heading="New Patients"
                subHeading="Number of new patients"
                icon={<IconActivityHeart />}
                formatter={numberFormatter}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BarChart
                metricData={metricData}
                type={selectedType}
                metric="returningPatients"
                priorColor={theme.palette.primary.gray200}
                currentColor={theme.palette.primary.turquoise500}
                heading="Returning Patients"
                subHeading="Number of returning patients"
                icon={<IconActivityHeart />}
                formatter={numberFormatter}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BarChart
                metricData={metricData}
                type={selectedType}
                metric="visits"
                priorColor={theme.palette.primary.gray200}
                currentColor={theme.palette.primary.violet600}
                heading="Visits"
                subHeading="Number of completed visits"
                icon={<IconMedicalCircle />}
                formatter={numberFormatter}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BarChart
                metricData={metricData}
                type={selectedType}
                metric="annualVisitsPerPatient"
                priorColor={theme.palette.primary.gray200}
                currentColor={theme.palette.primary.violet600}
                heading="Annual Visits Per Patient"
                subHeading="Average visits per patient per year"
                icon={<IconMedicalCircle />}
                formatter={numberFormatter}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BarChart
                metricData={metricData}
                type={selectedType}
                metric="retentionRateNew12"
                priorColor={theme.palette.primary.gray200}
                currentColor={theme.palette.primary.violet600}
                heading="New Patient Retention"
                subHeading="Percentage of new patients returning within 12 months"
                icon={<IconMedicalCircle />}
                formatter={percentageFormatter}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BarChart
                metricData={metricData}
                type={selectedType}
                metric="retentionRate12"
                priorColor={theme.palette.primary.gray200}
                currentColor={theme.palette.primary.violet600}
                heading="All Patient Retention"
                subHeading="Percentage of all patients returning within 12 months"
                icon={<IconMedicalCircle />}
                formatter={percentageFormatter}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default Performance;
