import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { QUERY_KEY_PRACTICE_PATIENT_UNSUBSCRIBE_OPTIONS } from "../../store/constants";
import { getUnsubscribeOptions, updateUnsubscribeOptions } from "../../utils/api/contact-unsubscribes.api";
import { useEffect, useState } from "react";
import { validate as validateUuid } from "uuid";
import {
  Box,
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import BonsaiSwitch from "../../components/BonsaiSwitch/BonsaiSwitch";

const PracticePatientUnsubscribe = () => {
  const [searchParams] = useSearchParams();
  const [groups, setGroups] = useState({});
  const [isUnsubscribedFromSms, setIsUnsubscribedFromSms] = useState(false);

  const email = searchParams.get("email");
  // eslint-disable-next-line
  const emailValidation = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const practiceUuid = searchParams.get("pid");

  const validUnsubscribeData = emailValidation.test(email) && validateUuid(practiceUuid);

  const { data: unsubscribeOptions, isPending } = useQuery({
    queryKey: [QUERY_KEY_PRACTICE_PATIENT_UNSUBSCRIBE_OPTIONS, practiceUuid, email],
    queryFn: async ({ signal }) => {
      return await getUnsubscribeOptions({ practiceUuid, email, signal });
    },
    enabled: validUnsubscribeData,
  });

  useEffect(() => {
    if (isPending || !unsubscribeOptions) {
      return;
    }
    const mappedGroups = {};
    unsubscribeOptions.emailUnsubscribeGroups.forEach((group) => {
      mappedGroups[group.groupId] = group;
    });
    setGroups(mappedGroups);
    setIsUnsubscribedFromSms(unsubscribeOptions.unsubscribedFromSms);
  }, [unsubscribeOptions, isPending, setGroups, setIsUnsubscribedFromSms]);

  const handleSave = () => {
    updateUnsubscribeOptions({
      practiceUuid: practiceUuid,
      email: email,
      unsubGroups: Object.values(groups),
      isUnsubscribedFromSms: isUnsubscribedFromSms,
    });
  };

  const handleGroupSubscribedStatus = ($event, groupId) => {
    groups[groupId].unsubscribed = !$event.target.checked;
    setGroups({
      ...groups,
    });
  };

  return (
    <>
      <Container sx={{ p: 5 }}>
        {validUnsubscribeData && !isPending ? (
          <Box>
            <Typography component="p" variant="h5" sx={{ mb: 1 }}>
              {unsubscribeOptions?.practiceName || ""}
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 700 }}>Email</TableCell>
                    <TableCell sx={{ fontWeight: 700 }}>Subscribed</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.values(groups).length > 0 ? (
                    Object.values(groups).map((group) => (
                      <TableRow key={group.groupId}>
                        <TableCell>{group.unsubscribeGroupName}</TableCell>
                        <TableCell>
                          <BonsaiSwitch
                            color="success"
                            checked={!group.unsubscribed}
                            onChange={($event) => {
                              handleGroupSubscribedStatus($event, group.groupId);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={2}>No Email Unsubscribe Groups</TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell colSpan={2} sx={{ fontWeight: 700 }}>SMS</TableCell>
                  </TableRow>
                  <TableRow key="sms-unsubscribe-group">
                    <TableCell>Booking Reminders</TableCell>
                    <TableCell>
                      <BonsaiSwitch
                        color="success"
                        checked={!isUnsubscribedFromSms}
                        onChange={($event) => {setIsUnsubscribedFromSms(!$event.target.checked)}}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={2} sx={{ borderBottom: "none" }}>
                      <Button
                        variant="contained"
                        sx={{ backgroundColor: "#6340C1" }}
                        disableElevation
                        onClick={handleSave}
                      >
                        Save
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <Typography>Unable to unsubscribe</Typography>
        )}
      </Container>
    </>
  );
};

export default PracticePatientUnsubscribe;
