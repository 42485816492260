import { coreAppClient } from "../coreAppClient";

const buildBaseUrl = (practiceUuid) => `/recall/${practiceUuid}`;

export const enrollPatients = async ({ practiceUuid }) => {
  await coreAppClient.post(`${buildBaseUrl(practiceUuid)}/enroll`);
};

export const exitPatients = async({ practiceUuid }) => {
  await coreAppClient.post(`${buildBaseUrl(practiceUuid)}/exit`);
};

export const attributePatients = async({ practiceUuid }) => {
  await coreAppClient.post(`${buildBaseUrl(practiceUuid)}/attributions`);
};

export const cleanupExits = async({ practiceUuid }) => {
  await coreAppClient.post(`${buildBaseUrl(practiceUuid)}/exit_cleanup`);
};