import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import InsightsFilters from "../../components/Insights/InsightsFilters";
import { QUERY_KEY_INSIGHTS_METRICS, QUERY_KEY_PRACTICE_LOCATIONS } from "../../store/constants";
import { fetchPracticeLocations } from "../../utils/http";
import { fetchInsightsMetrics } from "../../utils/api/insights-metrics.api";
import ErrorBlock from "../../components/UI/ErrorBlock";
import Loading from "../../components/Loading";
import KeyDriversTable from "../../components/Insights/KeyDriversTable";
import { Grid } from "@mui/material";
import BarChart from "../../components/Charts/BarChart";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as IconSlashCircle } from "../../assets/icon-slash-circle.svg";
import { ReactComponent as IconRefresh } from "../../assets/icon-refresh.svg";
import { ReactComponent as IconBreakout } from "../../assets/icon-breakout.svg";
import { percentageFormatter } from "../../data/valueFormatter";

const KeyDrivers = () => {
  const [selectedFrequency, setSelectedFrequency] = useState("monthly");
  const [selectedType, setSelectedType] = useState("Charts");
  const [selectedLocation, setSelectedLocation] = useState("all");
  const [locations, setLocations] = useState([]);
  const [metricData, setMetricData] = useState([]);
  const theme = useTheme();
  const label = "Key Drivers";

  const { uuid: practiceUuid } = useParams();

  const { data, isPending, isError, error } = useQuery({
    queryKey: [
      QUERY_KEY_INSIGHTS_METRICS,
      QUERY_KEY_PRACTICE_LOCATIONS,
      practiceUuid,
      selectedFrequency,
      selectedLocation,
    ],
    queryFn: async ({ signal }) => {
      const [locations, metricData] = await Promise.all([
        fetchPracticeLocations({ uuid: practiceUuid, signal }),
        fetchInsightsMetrics({ practiceUuid, timetable: selectedFrequency, location: selectedLocation, signal }),
      ]);
      return { locations, metricData };
    },
  });

  useEffect(() => {
    if (data) {
      if (data.locations) {
        setLocations(data.locations.locations);
      }
      if (data.metricData) {
        setMetricData(data.metricData);
      }
    }
  }, [data]);

  return (
    <>
      {isError && (
        <ErrorBlock
          title="An error occured."
          message={error.info?.message || "Failed to fetch Key Drivers. Please try again later"}
        />
      )}
      <InsightsFilters
        setSelectedFrequency={setSelectedFrequency}
        setSelectedType={setSelectedType}
        setSelectedLocation={setSelectedLocation}
        label={label}
        locationFilter={locations.length > 1}
      />
      {isPending ? (
        <Loading />
      ) : (
        <>
          <KeyDriversTable label={label} type={selectedType} metricData={metricData} />
          <BarChart
            metricData={metricData}
            type={selectedType}
            metric="rebookingRate"
            priorColor={theme.palette.primary.gray200}
            currentColor={theme.palette.primary.violet600}
            heading="Rebooking %"
            subHeading="Percentage booking next appointment same day"
            icon={<IconRefresh />}
            formatter={percentageFormatter}
          />
          <Grid container spacing={4} sx={{ my: 0.5 }}>
            <Grid item xs={12} md={6}>
              <BarChart
                metricData={metricData}
                type={selectedType}
                metric="noShowRate"
                priorColor={theme.palette.primary.gray200}
                currentColor={theme.palette.primary.violet600}
                heading="No Show %"
                subHeading="Percentage of appointments marked no-shows"
                icon={<IconSlashCircle />}
                formatter={percentageFormatter}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BarChart
                metricData={metricData}
                type={selectedType}
                metric="noShowRescheduleRate"
                priorColor={theme.palette.primary.gray200}
                currentColor={theme.palette.primary.turquoise500}
                heading="No Show Reschedule %"
                subHeading="Percentage of no-shows rescheduled"
                icon={<IconSlashCircle />}
                formatter={percentageFormatter}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BarChart
                metricData={metricData}
                type={selectedType}
                metric="cancellationRate"
                priorColor={theme.palette.primary.gray200}
                currentColor={theme.palette.primary.turquoise500}
                heading="Cancellation %"
                subHeading="Percentage of appointments canceled"
                icon={<IconSlashCircle />}
                formatter={percentageFormatter}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BarChart
                metricData={metricData}
                type={selectedType}
                metric="cancellationRescheduleRate"
                priorColor={theme.palette.primary.gray200}
                currentColor={theme.palette.primary.violet600}
                heading="Cancellation Reschedule %"
                subHeading="Percentage of cancellations rescheduled"
                icon={<IconSlashCircle />}
                formatter={percentageFormatter}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BarChart
                metricData={metricData}
                type={selectedType}
                metric="cancellationFillRate"
                priorColor={theme.palette.primary.gray200}
                currentColor={theme.palette.primary.violet600}
                heading="Cancelled Slot Fill %"
                subHeading="Percentage of canceled slots refilled"
                icon={<IconRefresh />}
                formatter={percentageFormatter}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BarChart
                metricData={metricData}
                type={selectedType}
                metric="retailPurchasedRate"
                priorColor={theme.palette.primary.gray200}
                currentColor={theme.palette.primary.turquoise500}
                heading="Purchased Retail %"
                subHeading="Percentage of patients with retail purchases"
                icon={<IconBreakout />}
                formatter={percentageFormatter}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default KeyDrivers;
