import { Box, IconButton, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";
import { INFO_DRAWER_WIDTH, QUERY_KEY_PRACTICE } from "../../store/constants";
import { fetchPractice } from "../../utils/api/practices.api";
import Loading from "../Loading";
import CloseIcon from "@mui/icons-material/Close";
import classes from "./Drawers.module.css";

const ResultsDrawer = (props) => {
  const params = useParams();
  const uuid = params.uuid;

  /** Fetch Practice **/
  const { data: formData, isPending } = useQuery({
    queryKey: [QUERY_KEY_PRACTICE],
    queryFn: async ({ signal }) => {
      const [practice] = await Promise.all([fetchPractice({ uuid, signal })]);
      return { practice };
    },
  });

  if (isPending) {
    return <Loading />;
  }

  return (
    <Box sx={{ width: { xs: "100%", sm: INFO_DRAWER_WIDTH } }}>
      <Box
        sx={{ backgroundColor: "primary.main", display: "flex", alignItems: "center", justifyContent: "space-between" }}
      >
        <Typography component="p" noWrap sx={{ color: "white", p: 2, fontWeight: 500 }}>
          Results
        </Typography>
        <IconButton onClick={props.onClick} sx={{ mr: 1 }}>
          <CloseIcon sx={{ color: "primary.contrastText" }} />
        </IconButton>
      </Box>

      <Box p={2} pb={5}>
        <Typography component="p" className={classes.label}>
          What is this?
        </Typography>
        <Typography component="p" className={classes.info}>
          Results from the automation campaigns.
        </Typography>

        <Typography component="p" className={classes.label}>
          How is it calculated?
        </Typography>
        <ul className={classes.ulDisc}>
          <li className={classes.li}>
            <strong>Patients Contacted:</strong> The number of patients included in the campaign.
          </li>
          <li className={classes.li}>
            <strong>Form Leads:</strong> The number of patients who requested an appointment through the Bonsai
            appointment request form.
          </li>
          <li className={classes.li}>
            <strong>Phone Leads:</strong> The number of patients who called the Bonsai phone number listed in the email.
          </li>
          <li className={classes.li}>
            <strong>Total Tracked Leads:</strong> The total number of leads generated from both form submissions and
            phone calls.
          </li>
          <li className={classes.li}>
            <strong>Tracked Appointments:</strong> The number of appointments booked as a result of form and phone
            leads.
          </li>
          <li className={classes.li}>
            <strong>Inferred Appointments:</strong> The number of appointments booked when a patient directly calls the
            practice’s phone number within 10 days of opening the campaign email.
          </li>
          <li className={classes.li}>
            <strong>Total Appointments:</strong> The sum of tracked and inferred appointments.
          </li>
          <li className={classes.li}>
            <strong>Average Revenue Per Visit:</strong> The total amount collected by the practice for a given service,
            per appointment.
            {formData?.practice.systemOfRecord === "modmed" && (
              <ul className={classes.ulCircle}>
                <li className={classes.li}>
                  The sum of Net Production (Subtotal – Adjustments – Discounts) divided by the number of visits (based
                  on Patient PMSID and Date), where Net Production > $0, for a given service (mapped to ICD-10,
                  CPT/Product, or CPT/Product Description values from the Production report).
                </li>
              </ul>
            )}
            {formData?.practice.systemOfRecord === "aesthetic_record" && (
              <ul className={classes.ulCircle}>
                <li className={classes.li}>
                  The sum of Total Collected divided by the number of unique invoice numbers for a given service (mapped
                  to Category and/or Item values from the Sales report). The calculation period covers the past 12
                  months.
                </li>
              </ul>
            )}
          </li>
          <li className={classes.li}>
            <strong>Estimated Revenue:</strong> The number of appointments multiplied by the average revenue per visit.
          </li>
        </ul>
      </Box>
    </Box>
  );
};

export default ResultsDrawer;
