import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { queryClient } from "../../utils/http";
import { useDispatch } from "react-redux";
import { uiActions } from "../../store/ui-slice";
import { fetchBonsaiCampaigns } from "../../utils/api/bonsai-campaigns.api";
import {
  createPracticeCampaign,
  fetchPracticeCampaigns,
  updatePracticeCampaign,
} from "../../utils/api/practice-campaigns.api";
import { QUERY_KEY_BONSAI_CAMPAIGNS, QUERY_KEY_PRACTICE_CAMPAIGNS } from "../../store/constants";
import { Card, CardContent, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ReactComponent as IconSettings } from "../../assets/icon-settings-white.svg";
import classes from "./EmailCampaigns.module.scss";
import BonsaiSwitch from "../BonsaiSwitch/BonsaiSwitch";
import PopMenu from "../PopMenu/PopMenu";
import PracticeCampaignSettingsModal from "./PracticeCampaignSettingsModal";
import useCustomClaims from "../../utils/hooks/useCustomClaims";
import CampaignSpamSettingsModal from "./CampaignSpamSettingsModal";

const CAMPAIGN_COPY_MAP = {
  NEUROMODULATORS: {
    text: "Neuromodulators",
    description: "Automatically send reminders to Neuromodulator patients that were treated over 3 months ago.",
  },
  SKIN_SCREENING: {
    text: "Skin Screening",
    description: "Automatically send reminders to Skin Screening patients that were treated over 1 year ago.",
  },
  FILLERS: {
    text: "Fillers",
    description: "Automatically send reminders to Filler patients that were treated over 6 months ago.",
  },
};

const EmailCampaigns = () => {
  const { uuid: practiceUuid } = useParams();
  const dispatch = useDispatch();
  const { isBonsaiAdmin } = useCustomClaims();

  /** Fetch Bonsai Campaign Options **/
  const { data: bonsaiCampaigns, isPending: isBonsaiCampaignsLoading } = useQuery({
    queryKey: [QUERY_KEY_BONSAI_CAMPAIGNS],
    queryFn: ({ signal }) => fetchBonsaiCampaigns({ signal }),
  });

  /** Fetch Practice Campaigns **/
  const { data: practiceCampaigns, isPending: isPracticeCampaignsLoading } = useQuery({
    queryKey: [QUERY_KEY_PRACTICE_CAMPAIGNS, practiceUuid],
    queryFn: ({ signal }) => fetchPracticeCampaigns({ practiceUuid, signal }),
  });

  /** Map bonsai campaigns to practice campaign status **/
  const [practiceCampaignMap, setPracticeCampaignMap] = useState();
  useEffect(() => {
    if (isBonsaiCampaignsLoading || isPracticeCampaignsLoading || !bonsaiCampaigns?.length) {
      return;
    }
    const baseMap = bonsaiCampaigns.reduce(
      (bonsaiCampaignIdMap, bonsaiCampaign) => ({
        ...bonsaiCampaignIdMap,
        [bonsaiCampaign.uuid]: {
          uuid: null,
          isEnabled: false,
          bonsaiCampaignUuid: bonsaiCampaign.uuid,
          type: bonsaiCampaign.type,
        },
      }),
      {}
    );
    practiceCampaigns.forEach((campaign) => {
      if (baseMap[campaign.bonsaiCampaignUuid]) {
        baseMap[campaign.bonsaiCampaignUuid] = {
          ...baseMap[campaign.bonsaiCampaignUuid],
          ...campaign,
        };
      }
    });
    setPracticeCampaignMap(baseMap);
  }, [
    isBonsaiCampaignsLoading,
    bonsaiCampaigns,
    practiceCampaigns,
    isPracticeCampaignsLoading,
    setPracticeCampaignMap,
  ]);

  /** Enable / Disable Practice Campaigns **/
  const { mutate: saveCampaign } = useMutation({
    mutationFn: ({ campaign, isEnabled }) => {
      const practiceCampaign = {
        ...campaign,
        isEnabled,
      };
      if (!campaign.uuid) {
        return createPracticeCampaign({ practiceUuid, practiceCampaign });
      } else {
        return updatePracticeCampaign({ practiceUuid, practiceCampaign });
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_PRACTICE_CAMPAIGNS],
      });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Practice campaign saved",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
    onError: () => {
      dispatch(
        uiActions.showNotification({
          status: "error",
          message: "Error saving practice campaign",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  /** Campaign Settings **/
  const [campaignToEdit, setCampaignToEdit] = useState();
  const handleSettingsClick = (campaign) => {
    setCampaignToEdit(campaign);
  };
  const handleCloseSettings = () => {
    setCampaignToEdit(null)
  };

  /** Spam Settings **/
  const [isSpamSettingsModalOpen, setIsSpamSettingsModalOpen] = useState(false);

  return (
    <Box sx={{ mb: 6 }}>
      <Typography variant="h2" sx={{ mb: 1, maxWidth: 650 }}>
        Email Campaigns
        {isBonsaiAdmin && (
          <IconButton
            size="small"
            variant="outlined"
            color="primary"
            className={classes.iconButton}
            sx={{
              float: 'right',
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent",
              },
              "path:not(:hover)": {
                stroke: "rgb(162, 154, 178)",
              },
            }}
            onClick={() => setIsSpamSettingsModalOpen(true)}
          >
            <IconSettings />
          </IconButton>
        )}
      </Typography>
      <Typography color="primary.gray600" sx={{ mb: 2.5 }}>
        Turn on automated email campaigns to remind your patients to book an appointment.
      </Typography>
      {practiceCampaignMap &&
        bonsaiCampaigns.map((campaign) => (
          <Card
            key={campaign.uuid}
            variant="outlined"
            sx={{ minWidth: 275, maxWidth: 650, borderRadius: "12px", mb: 2 }}
            className={practiceCampaignMap[campaign.uuid].isEnabled ? classes.cardActive : null}
          >
            <CardContent sx={{ display: "flex", justifyContent: "space-between", p: 3 }}>
              <Box pr={4}>
                <Typography variant="bodyBiotifMedium" sx={{ mb: 1 }} component="p">
                  {CAMPAIGN_COPY_MAP[campaign.type].text}
                </Typography>
                <Typography variant="body2" color="primary.gray500">
                  {CAMPAIGN_COPY_MAP[campaign.type].description}
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}
              >
                <BonsaiSwitch
                  color="success"
                  checked={practiceCampaignMap[campaign.uuid].isEnabled}
                  onChange={($event) =>
                    saveCampaign({ campaign: practiceCampaignMap[campaign.uuid], isEnabled: $event.target.checked })
                  }
                />
                <PopMenu
                  id={`${campaign.type}-settings`}
                  options={[{ text: 'Settings' }]}
                  handler={() => handleSettingsClick(practiceCampaignMap[campaign.uuid])}
                />
              </Box>
            </CardContent>
          </Card>
        ))
      }
      {
        campaignToEdit && (
          <PracticeCampaignSettingsModal
            title={`Edit ${CAMPAIGN_COPY_MAP[campaignToEdit.type].text} Settings`}
            campaign={campaignToEdit}
            onClose={handleCloseSettings}
            isOpen={true}
          />
        )
      }
      {
        isSpamSettingsModalOpen && (
          <CampaignSpamSettingsModal
            isOpen={isSpamSettingsModalOpen}
            onClose={() => setIsSpamSettingsModalOpen(false)}
          />
        )
      }
    </Box>
  );
};

export default EmailCampaigns;
