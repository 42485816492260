import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Collapse,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import Header from "../components/Header";
import SelectInput from "../components/SelectInput";
import TextInput from "../components/TextInput";
import Loading from "../components/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { uiActions } from "../store/ui-slice";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  QUERY_KEY_PRACTICE,
  QUERY_KEY_PRACTICE_LOCATIONS, QUERY_KEY_PRACTICE_USERS,
  QUERY_KEY_USER_FORM_POSSIBLE_VALUES
} from "../store/constants";
import {
  createPracticeUser,
  editPracticeUser,
  fetchPossibleValues,
  fetchPracticeUser
} from "../utils/api/practice-users.api";
import { fetchPractice } from "../utils/api/practices.api";
import { fetchPracticeLocations } from "../utils/api/practice-locations";
import { queryClient } from "../utils/http";

const ProviderInput = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /** Form Management */
  const [userFormData, setUserFormData] = useState({
    email: "",
    employmentStatus: "",
    firstName: "",
    hasPortalAccess: "no",
    isProvider: "no",
    lastName: "",
    middleName: "",
    npiNumber: "",
    otherProviderType: "",
    providerLocations: [],
    providerType: "",
    role: "",
    status: "",
    title: "",
  });
  const isEditState = !!params.providerUuid;

  /** Initialize Form Dependencies */
  const { data: formOptions, isPending: isInitFormLoading } = useQuery({
    queryKey: [QUERY_KEY_PRACTICE_LOCATIONS, QUERY_KEY_USER_FORM_POSSIBLE_VALUES, QUERY_KEY_PRACTICE],
    queryFn: async ({ signal }) => {
      const [values, practice, locations] = await Promise.all([
        fetchPossibleValues({ uuid: params.uuid, signal }),
        fetchPractice({ uuid: params.uuid, signal }),
        fetchPracticeLocations({ uuid: params.uuid, signal }),
      ]);
      return { values, practice, locations };
    },
  });

  /** Load Existing User */
  const { data: existingUser, isPending: isExistingUserLoading } = useQuery({
    queryKey: [QUERY_KEY_PRACTICE_USERS],
    queryFn: ({ signal }) => {
      if (!isEditState) {
        return null;
      }
      return fetchPracticeUser({ practiceUuid: params.uuid, userUuid: params.providerUuid, signal });
    },
  });

  useEffect(() => {
    if (!isEditState || isExistingUserLoading || !existingUser) {
      return;
    }
    setUserFormData({
      email: existingUser.email,
      employmentStatus: existingUser.practiceEmploymentStatus,
      firstName: existingUser.firstName,
      hasPortalAccess: existingUser.hasPortalAccess ? "yes" : "no",
      isProvider: existingUser.provider && !!existingUser.provider.type ? "yes" : "no",
      lastName: existingUser.lastName,
      middleName: existingUser.middleName || "",
      npiNumber: existingUser.provider.npiNumber,
      otherProviderType: existingUser.provider.otherType,
      providerLocations: existingUser.locations.map(location => location.uuid),
      providerType: existingUser.provider.type,
      role: existingUser.practiceRole,
      status: existingUser.status,
      title: existingUser.title || "",
    })
  }, [existingUser, isExistingUserLoading, isEditState]);

  /** Create User */
  const { mutate: createUser } = useMutation({
    mutationFn: (event) => {
      const user = buildUserFromForm({ form: new FormData(event.target) });
      return createPracticeUser({ user, practiceUuid: params.uuid });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_PRACTICE_USERS],
      });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Provider created",
        }),
        dispatch(uiActions.showSnackBar(true)),
      );
      navigate(`/practices/${params.uuid}/providers`);
    },
    onError: () => {
      dispatch(
        uiActions.showNotification({
          status: "error",
          message: "Error creating provider",
        }),
        dispatch(uiActions.showSnackBar(true)),
      )
    },
  });

  /** Edit User */
  const { mutate: editUser } = useMutation({
    mutationFn: (event) => {
      const user = buildUserFromForm({ form: new FormData(event.target) });
      return editPracticeUser({ user, practiceUuid: params.uuid, userUuid: params.providerUuid });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_PRACTICE_USERS],
      });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Provider updated",
        }),
        dispatch(uiActions.showSnackBar(true)),
      );
      navigate(`/practices/${params.uuid}/providers`);
    },
    onError: () => {
      dispatch(
        uiActions.showNotification({
          status: "error",
          message: "Error updating provider",
        }),
        dispatch(uiActions.showSnackBar(true)),
      )
    },
  });

  /** Form Submission */
  const buildUserFromForm = ({ form }) => ({
    firstName: form.get("firstName"),
    middleName: form.get("middleName") || null,
    lastName: form.get("lastName"),
    title: form.get("title") || null,
    email: form.get("email"),
    provider: {
      npiNumber: form.get("npiNumber"),
      type: form.get("providerType"),
      otherType: form.get("otherProviderType"),
    },
    locationUuids: form.get("providerLocations") ? form.get("providerLocations").split(",") : [],
    hasPortalAccess: form.get("hasPortalAccess") === "yes",
    workAsProvider: form.get("isProvider") === "yes",
    status: form.get("status"),
    practiceRole: form.get("role"),
    practiceEmploymentStatus: form.get("employmentStatus"),
  });

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (isEditState) {
      editUser(event);
    } else {
      createUser(event);
    }
  }

  return (
    <>
      <Header title="Add New Provider"/>
      <Container sx={{ py: 3, m: 0 }}>
        {formOptions?.practice && (
          <Typography variant="h3" component="p" sx={{ mb: 3 }}>
            {formOptions.practice.name}
          </Typography>
        )}
        {isExistingUserLoading || isInitFormLoading
          ? (<Loading/>)
          : (
            <ProviderForm
              uuid={params.uuid}
              userFormData={userFormData}
              formOptions={formOptions}
              setUserFormData={setUserFormData}
              handleFormSubmit={handleFormSubmit}
              isEditState={isEditState}
            />
          )
        }
      </Container>
    </>
  );
};

const ProviderForm = ({ uuid, formOptions, userFormData, setUserFormData, handleFormSubmit, isEditState }) => {
  /** Form Data Management */
  const handleFormInput = (e) => {
    setUserFormData({
      ...userFormData,
      [e.target.name]: e.target.value,
    });
  };

  /** Is Provider Input */
  const handleIsProviderChange = (e) => {
    const updatedData = { ...userFormData };
    if (e.target.value === "no") {
      updatedData.npiNumber = "";
      updatedData.otherProviderType = "";
      updatedData.providerType = "";
    }
    setUserFormData({
      ...updatedData,
      [e.target.name]: e.target.value,
    })
  };

  /** Provider Type Input */
  const handleProviderTypeChange = (e) => {
    const isOtherSelected = e.target.value.indexOf("other") > -1;
    setIsOther(isOtherSelected);

    const updatedData = { ...userFormData };
    if (!isOtherSelected) {
      updatedData.otherProviderType = "";
    }
    setUserFormData({
      ...updatedData,
      [e.target.name]: e.target.value,
    })
  };

  /** Other Provider Type Management */
  const [isOther, setIsOther] = useState(userFormData.providerType === "other");
  useEffect(() => {
    setIsOther(userFormData.providerType === "other");
  }, [userFormData]);

  /** Cancel Click */
  const navigate = useNavigate();
  const returnHandler = () => {
    navigate(`/practices/${uuid}/providers`);
  };
  return (
    <form onSubmit={handleFormSubmit}>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={6}>
          <SelectInput
            required
            label="Role"
            name="role"
            value={userFormData.role}
            onChange={handleFormInput}
            items={formOptions.values.practiceRole}
          />
          <TextInput
            label="Title"
            name="title"
            value={userFormData.title}
            onChange={handleFormInput}
          />
          <Stack sx={{ mb: 3 }}>
            <FormControl>
              <InputLabel id="input-location">Location</InputLabel>
              <Select
                label="Location"
                labelId="input-location"
                name="providerLocations"
                value={userFormData.providerLocations}
                onChange={handleFormInput}
                multiple
                sx={{ background: "rgb(248, 250, 252)" }}
              >
                {formOptions.locations.map((location) => (
                  <MenuItem key={location.uuid} value={location.uuid}>
                    {location.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <TextInput
            label="First Name"
            name="firstName"
            value={userFormData.firstName}
            onChange={handleFormInput}
            required
          />
          <TextInput
            label="Middle Name"
            name="middleName"
            value={userFormData.middleName}
            onChange={handleFormInput}
          />
          <TextInput
            label="Last Name"
            name="lastName"
            value={userFormData.lastName}
            onChange={handleFormInput}
            required
          />
        </Grid>
        <Grid item xs={12} lg={6} sx={{ paddingTop: 0 }}>
          <TextInput
            label="Email"
            name="email"
            value={userFormData.email}
            onChange={handleFormInput}
            required
            disabled={isEditState}
          />
          <SelectInput
            required
            label="Status"
            name="status"
            value={userFormData.status}
            items={formOptions.values.status}
            onChange={handleFormInput}
          />
          <SelectInput
            required
            label="Employment Status"
            name="employmentStatus"
            value={userFormData.employmentStatus}
            items={formOptions.values.practiceEmploymentStatus}
            onChange={handleFormInput}
          />
          <Stack sx={{ mb: 3 }}>
            <FormControl>
              <FormLabel id="portal-access-label">Portal Access?</FormLabel>
              <RadioGroup
                row
                aria-labelledby="portal-access-label"
                value={userFormData.hasPortalAccess}
                onChange={handleFormInput}
              >
                <FormControlLabel value="yes" control={<Radio/>} label="Yes" name="hasPortalAccess"/>
                <FormControlLabel value="no" control={<Radio/>} label="No" name="hasPortalAccess"/>
              </RadioGroup>
            </FormControl>
          </Stack>
        </Grid>
      </Grid>
      <Stack sx={{ mb: 3 }}>
        <FormControl>
          <FormLabel id="is-provider-label">Work as a Provider</FormLabel>
          <RadioGroup
            row aria-labelledby="is-provider-label"
            value={userFormData.isProvider}
            onChange={handleIsProviderChange}
          >
            <FormControlLabel value="yes" control={<Radio/>} label="Yes" name="isProvider"/>
            <FormControlLabel value="no" control={<Radio/>} label="No" name="isProvider"/>
          </RadioGroup>
        </FormControl>
      </Stack>
      <Collapse in={userFormData.isProvider === "yes"}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 3,
            flexWrap: "wrap",
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              <SelectInput
                required={userFormData.isProvider === "yes"}
                label="Provider Type"
                name="providerType"
                value={userFormData.providerType}
                onChange={handleProviderTypeChange}
                items={formOptions.values.providerType}
              />
              <TextInput
                label="NPI Number"
                name="npiNumber"
                value={userFormData.npiNumber}
                onChange={handleFormInput}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Collapse in={isOther}>
                <TextInput
                  label="Other Type"
                  name="otherProviderType"
                  value={userFormData.otherProviderType}
                  onChange={handleFormInput}
                />
              </Collapse>
            </Grid>
          </Grid>
        </Box>
      </Collapse>

      <Box>
        <Stack direction="row" spacing={2} py={3}>
          <Button variant="outlined" onClick={returnHandler}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" disableElevation>
            Save
          </Button>
        </Stack>
      </Box>
    </form>
  )
};

export default ProviderInput;
