import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Container, Divider, FormControl, Stack, Typography } from "@mui/material";
import TextInput from "../components/TextInput";
import Header from "../components/Header";
import BonsaiSwitch from "../components/BonsaiSwitch/BonsaiSwitch";
import { useParams } from "react-router-dom";
import {
  INTEGRATION_SOR_AESTHETIC_RECORD,
  INTEGRATION_SOR_MODMED,
  QUERY_KEY_PRACTICE_INTEGRATIONS,
} from "../store/constants";
import {
  fetchPracticeIntegrations,
  historySynchronize,
  historySynchronizeCancel,
  updatePracticeIntegration,
} from "../utils/api/practice-integrations";
import { useMutation, useQuery } from "@tanstack/react-query";
import ErrorBlock from "../components/UI/ErrorBlock";
import Loading from "../components/Loading";
import { queryClient } from "../utils/http";
import { uiActions } from "../store/ui-slice";
import { useDispatch } from "react-redux";
import StatusBadge from "../components/StatusBadge/StatusBadge";
import moment from "moment";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const PracticeIntegrations = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const queryKey = [QUERY_KEY_PRACTICE_INTEGRATIONS, params.uuid];
  const [showError, setShowError] = useState(false);
  const [sor, setSor] = useState("");
  const [extractionEnabled, setExtractionEnabled] = useState(false);

  const { data, isPending, isError, error } = useQuery({
    queryKey: queryKey,
    queryFn: ({ signal }) =>
      fetchPracticeIntegrations({
        signal,
        uuid: params.uuid,
      }),
  });

  const { mutate: updateIntegration } = useMutation({
    mutationFn: updatePracticeIntegration,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Integration updated.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: queryKey });
      dispatch(
        uiActions.showNotification({
          status: "error",
          message: "Integration update failed, please try again later.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  const { mutate: synchronizeIntegration } = useMutation({
    mutationFn: historySynchronize,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Synchronization scheduled.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: queryKey });
      dispatch(
        uiActions.showNotification({
          status: "error",
          message: "Integration synchronization failed, please try again later.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  const { mutate: synchronizeIntegrationCancel } = useMutation({
    mutationFn: historySynchronizeCancel,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Synchronization canceled.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: queryKey });
      dispatch(
        uiActions.showNotification({
          status: "error",
          message: "Integration synchronization failed to cancel, please try again later.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  const [formData, setFormData] = useState({
    practiceName: "",
    email: "",
    password: "",
    enabled: false,
    overrideFromDate: null,
    overrideToDate: null,
  });

  const [notModifiable, setNotModifiable] = useState(false);

  const handleFormInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleOverrideFromDate = (value) => {
    setFormData({
      ...formData,
      overrideFromDate: value ? value.format() : null,
    });
  };

  const handleOverrideToDate = (value) => {
    setFormData({ ...formData, overrideToDate: value ? value.format() : null });
  };

  const handleEnabledStatus = () => {
    setFormData({ ...formData, enabled: !formData.enabled });
  };

  const checkDates = () => {
    setShowError(false);
    if (
      !!formData.overrideToDate &&
      !!formData.overrideFromDate &&
      formData.overrideToDate < formData.overrideFromDate
    ) {
      setShowError(true);
      return false;
    }
    return true;
  };

  const synchronizeHistory = () => {
    let validDates = checkDates();
    if (validDates) {
      synchronizeIntegration({
        uuid: params.uuid,
      });
    }
  };

  const saveIntegrationData = () => {
    let validDates = checkDates();
    if (validDates) {
      updateIntegration({ uuid: params.uuid, data: formData, sor: sor });
    }
  };

  const synchronizeCancel = () => {
    synchronizeIntegrationCancel({
      uuid: params.uuid,
    });
  };

  useEffect(() => {
    if (data?.data) {
      setFormData(data.data);
      if (data.data.ingestionInProgress || (data.data.ingestionScheduled && !data.data.ingestionFailed)) {
        setNotModifiable(true);
      }
      if (data.data.type === INTEGRATION_SOR_MODMED) {
        setSor("modmed");
      }
      if (data.data.type === INTEGRATION_SOR_AESTHETIC_RECORD) {
        setSor("aesthetic-record");
      }
      setExtractionEnabled(data.data.enabled);
    }
  }, [data]);

  return (
    <>
      <Header title="Practice Integrations" />
      {isError && (
        <ErrorBlock
          title="An error occured."
          message={error.info?.message || "Failed to fetch practice integrations. Please try again later."}
        />
      )}
      {isPending && <Loading />}
      <Container sx={{ pb: 3, m: 0 }} maxWidth="sm">
        {!isPending && (
          <Stack sx={{ mb: 3, width: "auto" }}>
            {formData.lastSyncDate && (
              <Box display="flex" alignItems="center" mb={1}>
                <Typography variant="body2" color="primary.gray500">
                  Last Sync: {moment(formData.lastSyncDate).format("MM/DD/YYYY hh:mm:ss A")}
                </Typography>
              </Box>
            )}

            <Box mb={1.5}>
              <Button
                disableElevation
                variant="contained"
                onClick={synchronizeHistory}
                disabled={notModifiable || !extractionEnabled}
              >
                Synchronize
              </Button>
              {formData.ingestionInProgress && (
                <Button disableElevation variant="outlined" onClick={synchronizeCancel} sx={{ ml: 1 }}>
                  Cancel Synchronization
                </Button>
              )}
            </Box>
            <Box>
              {formData.ingestionInProgress && !formData.ingestionScheduled && (
                <StatusBadge status="active" label="Ingestion in Progress" sx={{ mr: 1 }} />
              )}
              {formData.ingestionInProgress && formData.ingestionScheduled && (
                <StatusBadge status="active" label="Historical Sync In Progress" sx={{ mr: 1 }} />
              )}

              {!formData.ingestionInProgress && formData.ingestionScheduled && (
                <StatusBadge status="inactive" label="Ingestion Scheduled" sx={{ mr: 1 }} />
              )}
              {formData.ingestionFailed && <StatusBadge status="error" label="Ingestion Failed" sx={{ mr: 1 }} />}
            </Box>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
              <Typography variant="h3" component="p">
                Extract Settings
              </Typography>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="body2" color="primary.gray500" sx={{ mr: 2 }}>
                  Enabled
                </Typography>

                <BonsaiSwitch
                  color="success"
                  checked={formData.enabled}
                  onChange={handleEnabledStatus}
                  disabled={notModifiable}
                />
              </Box>
            </Box>

            <FormControl>
              <TextInput
                type="text"
                label="Practice Name"
                name="practiceName"
                value={formData.practiceName}
                required
                onChange={handleFormInput}
              />
            </FormControl>
            {formData.type === INTEGRATION_SOR_MODMED && (
              <>
                <FormControl>
                  <TextInput
                    type="text"
                    label="Login"
                    name="login"
                    value={formData.login}
                    required
                    onChange={handleFormInput}
                  />
                </FormControl>
                <FormControl>
                  <TextInput
                    type="text"
                    label="URL"
                    name="url"
                    value={formData.url}
                    required
                    onChange={handleFormInput}
                  />
                </FormControl>
                <FormControl>
                  <TextInput
                    type="text"
                    label="MFA Secret Key"
                    name="mfaSecretKey"
                    value={formData.mfaSecretKey}
                    onChange={handleFormInput}
                  />
                </FormControl>
              </>
            )}

            {formData.type === INTEGRATION_SOR_AESTHETIC_RECORD && (
              <FormControl>
                <TextInput
                  type="email"
                  label="Email"
                  name="email"
                  value={formData.email}
                  required
                  onChange={handleFormInput}
                />
              </FormControl>
            )}

            <FormControl>
              <TextInput
                type="password"
                label="Password"
                name="password"
                value={formData.password}
                onChange={handleFormInput}
              />
            </FormControl>

            <Box mb={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DemoContainer components={["DatePicker"]} sx={{ display: "flex", justifyContent: "space-between" }}>
                  <DatePicker
                    value={formData.overrideFromDate ? moment.utc(formData.overrideFromDate) : null}
                    label="Override From Date"
                    onChange={(newValue) => handleOverrideFromDate(newValue)}
                    slotProps={{
                      field: { clearable: true },
                    }}
                    disabled={notModifiable}
                    sx={{ backgroundColor: "rgb(248, 250, 252)", width: 260 }}
                  />
                  <DatePicker
                    value={formData.overrideToDate ? moment.utc(formData.overrideToDate) : null}
                    label="Override To Date"
                    onChange={(newValue) => handleOverrideToDate(newValue)}
                    slotProps={{
                      field: { clearable: true },
                    }}
                    disabled={notModifiable}
                    sx={{ backgroundColor: "rgb(248, 250, 252)", width: 260 }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box>
            {showError && (
              <Box mb={3}>
                <Alert severity="error">
                  Please ensure <strong>Override To Date</strong> is after <strong>Override From Date</strong>
                </Alert>
              </Box>
            )}

            <Box>
              <Button
                type="submit"
                variant="contained"
                disableElevation
                onClick={saveIntegrationData}
                disabled={notModifiable}
              >
                Save
              </Button>
            </Box>
          </Stack>
        )}
      </Container>
    </>
  );
};

export default PracticeIntegrations;
