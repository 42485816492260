import { Box, FormControl, InputLabel, List, ListItemButton, ListItemText, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import classes from "./DataTypesNav.module.scss";

const links = [
  { label: "Appointment Type", url: "/data-types/appointment/type" },
  { label: "Appointment Status", url: "/data-types/appointment/status" },
  { label: "Policy", url: "/data-types/policy" },
  { label: "Procedure", url: "/data-types/procedure" },
  { label: "Retail", url: "/data-types/retail" },
  { label: "Tender", url: "/data-types/tender" },
  { label: "Diagnosis Codes", url: "/data-types/diagnosis-codes" },
];

const DataTypesNav = () => {
  const [dataType, setDataType] = useState();
  const navigate = useNavigate();
  const handleDataTypeChange = (event) => {
    navigate(event.target.value);
  };
  useEffect(() => {
    setDataType(window.location.pathname);
  }, []);
  return (
    <Box sx={{ pr: { xs: 0, md: 3 } }}>
      <FormControl fullWidth sx={{ display: { xs: "flex", md: "none" }, mb: 3 }}>
        <InputLabel id="data-type-label">Data Type</InputLabel>
        <Select
          size="small"
          labelId="data-type-label"
          id="data-type-select"
          value={dataType || ""}
          label="Data Type"
          onChange={handleDataTypeChange}
        >
          {links.map((link) => (
            <MenuItem key={link.url} value={link.url}>
              {link.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <List sx={{ width: 200, pt: 0, display: { xs: "none", md: "block" } }} component="nav">
        {links.map((link, i) => (
          <NavLink
            key={i}
            to={link.url}
            className={({ isActive }) => (isActive ? classes.active : classes.datatypeNav)}
          >
            <ListItemButton>
              <ListItemText primary={link.label} />
            </ListItemButton>
          </NavLink>
        ))}
      </List>
    </Box>
  );
};

export default DataTypesNav;
