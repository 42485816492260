import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  TYPE_APPOINTMENT,
  QUERY_KEY_PRACTICE_APPOINTMENT_MAPPINGS,
  PAGINATION_ROWS_PER_PAGE_OPTIONS,
  PAGINATION_ROWS_PER_PAGE_DEFAULT,
} from "../store/constants";
import { createNewMapping, fetchPracticeAppointmentMappings, queryClient, updatePracticeMapping } from "../utils/http";
import ErrorBlock from "../components/UI/ErrorBlock";
import Loading from "../components/Loading";
import StyledTableCell from "../components/StyledTableCell";
import {
  Box,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { useDispatch } from "react-redux";
import { uiActions } from "../store/ui-slice";
import useMappingsOrder from "../utils/hooks/useMappingsOrder";
import useDebouncedFilter from "../utils/hooks/useDebouncedFilter";
import StyledOrderedTableCell from "../components/StyledOrderedTableCell";
import SearchBox from "../components/SearchBox";

const PracticeMappingsAppointmentType = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const { orderField, orderDirection, changeOrder } = useMappingsOrder();
  const { filter, setFilter, debouncedFilter } = useDebouncedFilter("", 300);
  const [practiceAppointmentTypes, setPracticeAppointmentTypes] = useState([]);
  const [bonsaiAppointmentTypes, setBonsaiAppointmentTypes] = useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(PAGINATION_ROWS_PER_PAGE_DEFAULT);

  const { data, isPending, isError, error } = useQuery({
    queryKey: [
      QUERY_KEY_PRACTICE_APPOINTMENT_MAPPINGS,
      params.uuid,
      rowsPerPage,
      page,
      orderField,
      orderDirection,
      debouncedFilter,
    ],
    queryFn: ({ signal }) =>
      fetchPracticeAppointmentMappings({
        signal,
        uuid: params.uuid,
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        orderField: orderField,
        order: orderDirection,
        filter: debouncedFilter.length > 0 ? debouncedFilter : null,
      }),
  });

  const {
    mutate,
    isError: isErrorPracticeMapping,
    error: errorPracticeMapping,
  } = useMutation({
    mutationFn: updatePracticeMapping,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_PRACTICE_APPOINTMENT_MAPPINGS],
      });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Appointment Type Mapping updated.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  useEffect(() => {
    if (data) {
      setPracticeAppointmentTypes(data.practiceData.data);
      setBonsaiAppointmentTypes(data.bonsaiData.items);
    }
  }, [data]);

  const handleBonsaiAppointmentChange = (selectedOption, id) => {
    let data = {};
    let values = [];
    selectedOption.target.value.forEach((element) => values.push(element));
    data.substitutes = values;

    mutate({ uuid: params.uuid, id: id, data: data });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const {
    mutate: mutateCreateMapping,
    isError: isErrorCreateMapping,
    error: errorCreateMapping,
  } = useMutation({
    mutationFn: createNewMapping,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_PRACTICE_APPOINTMENT_MAPPINGS],
      });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Appointment Type Mapping created.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  const handleMappingCreate = (selectedOption, substitute) => {
    let data = {};
    let values = [];
    values.push(selectedOption.target.value);
    data.original = substitute;
    data.substitutes = values;

    mutateCreateMapping({
      uuid: params.uuid,
      type: TYPE_APPOINTMENT,
      data: data,
    });
  };

  const handleClearFilter = () => {
    setFilter("");
  };

  return (
    <>
      <Box display="flex">
        <Box flex={1} sx={{ overflowX: "auto" }}>
          {isErrorCreateMapping && (
            <ErrorBlock
              title="An error occured."
              message={
                errorCreateMapping.info?.message || "Failed to create appointment mapping. Please try again later."
              }
            />
          )}
          {isError && (
            <ErrorBlock
              title="An error occured."
              message={error.info?.message || "Failed to fetch practice appointment mappings. Please try again later."}
            />
          )}
          {isErrorPracticeMapping && (
            <ErrorBlock
              title="An error occured."
              message={
                errorPracticeMapping.info?.message || "Failed to update appointment mapping. Please try again later."
              }
            />
          )}
          {(data?.practiceData.data.length ?? 0) === 0 && isPending && debouncedFilter === "" && <Loading />}
          {(data?.practiceData.data.length ?? 0) === 0 && !isPending && debouncedFilter === "" && (
            <Typography>No current procedure mappings</Typography>
          )}
          {(data?.practiceData.data.length ?? 0) === 0 && !isPending && debouncedFilter !== "" && (
            <Typography>No result</Typography>
          )}
          {((data?.practiceData.data.length ?? 0) > 0 || debouncedFilter !== "") && (
            <>
              <SearchBox
                value={filter}
                onChange={(event) => setFilter(event.target.value)}
                handleClearFilter={handleClearFilter}
              />

              <Paper
                sx={{
                  width: "100%",
                  border: "1px solid #f0f0f0",
                  borderRadius: 1.5,
                  boxShadow: "none",
                }}
              >
                <TableContainer>
                  <Table size="small" stickyHeader sx={{ borderRadius: "6px 6px 0 0", overflow: "hidden" }}>
                    <TableHead>
                      <TableRow>
                        <StyledOrderedTableCell
                          fieldName="rawValue"
                          orderField={orderField}
                          orderDirection={orderDirection}
                          changeOrder={changeOrder}
                        >
                          Practice Appointment Types
                        </StyledOrderedTableCell>
                        <StyledOrderedTableCell
                          sx={{ textAlign: "center" }}
                          fieldName="count"
                          orderField={orderField}
                          orderDirection={orderDirection}
                          changeOrder={changeOrder}
                        >
                          Count
                        </StyledOrderedTableCell>
                        <StyledTableCell>Bonsai Appointment Types</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {practiceAppointmentTypes.map((practiceAppointmentType, i) => (
                        <TableRow key={i} hover>
                          <TableCell sx={{ minWidth: 200 }}>{practiceAppointmentType.rawValue}</TableCell>
                          <TableCell sx={{ textAlign: "center" }}>{practiceAppointmentType.count}</TableCell>
                          <TableCell sx={{ minWidth: 200 }}>
                            <FormControl sx={{ width: "100%" }}>
                              {practiceAppointmentType.substitute ? (
                                <Select
                                  labelId={`bonsai-appointment-type-${i}`}
                                  onChange={(selectedOption) =>
                                    handleBonsaiAppointmentChange(
                                      selectedOption,
                                      practiceAppointmentType.substitute.substituteId
                                    )
                                  }
                                  size="small"
                                  defaultValue={practiceAppointmentType.substitute.substitutes}
                                  value={practiceAppointmentType.substitute.substitutes}
                                  multiple
                                  sx={{ backgroundColor: "#ffffff" }}
                                >
                                  {bonsaiAppointmentTypes.map((bonsaiAppointmentType) => (
                                    <MenuItem key={bonsaiAppointmentType.id} value={bonsaiAppointmentType.id}>
                                      {bonsaiAppointmentType.display}
                                    </MenuItem>
                                  ))}
                                </Select>
                              ) : (
                                <Select
                                  labelId={`bonsai-appointment-type-${i}`}
                                  onChange={(selectedOption) =>
                                    handleMappingCreate(selectedOption, practiceAppointmentType.rawValue)
                                  }
                                  size="small"
                                  defaultValue={practiceAppointmentType.rawValue}
                                  value={practiceAppointmentType.rawValue}
                                  sx={{ backgroundColor: "#ffffff" }}
                                >
                                  {bonsaiAppointmentTypes.map((bonsaiAppointmentType) => (
                                    <MenuItem key={bonsaiAppointmentType.id} value={bonsaiAppointmentType.id}>
                                      {bonsaiAppointmentType.display}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                            </FormControl>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={PAGINATION_ROWS_PER_PAGE_OPTIONS}
                  component="div"
                  count={data?.practiceData?.total ?? 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default PracticeMappingsAppointmentType;
