import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DataTypesNav from "../components/DataTypesNav";
import { snakeCase } from "lodash";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createNewTender, fetchTenderTypes, queryClient, updateDataType } from "../utils/http";
import { useDispatch } from "react-redux";
import { uiActions } from "../store/ui-slice";
import { QUERY_KEY_DATA_TYPE_TENDER } from "../store/constants";
import ErrorBlock from "../components/UI/ErrorBlock";
import Loading from "../components/Loading";
import StyledTableCell from "../components/StyledTableCell";

const DataTypeTender = () => {
  const dispatch = useDispatch();
  const [tenderTypes, setTenderTypes] = useState([]);
  const [newTender, setNewTender] = useState("");
  const [newTenderDescription, setNewTenderDescription] = useState("");
  const [newTenderOpen, setNewTenderOpen] = useState(false);

  const [editTypeOpen, setEditTypeOpen] = useState(false);
  const [editTypeName, setEditTypeName] = useState("");
  const [editTypeId, setEditTypeId] = useState("");
  const [editTypeDescription, setEditTypeDescription] = useState("");

  const [loaded, setLoaded] = useState(false);

  const {
    data,
    isPending: isPendingTenderFetch,
    isError: isErrorTenderFetch,
    error: errorTenderFetch,
  } = useQuery({
    queryKey: [QUERY_KEY_DATA_TYPE_TENDER],
    queryFn: ({ signal, queryKey }) => fetchTenderTypes({ signal, ...queryKey }),
  });

  const {
    mutate,
    isPending: isPendingNewTender,
    isError: isErrorNewTender,
    error: errorNewTender,
  } = useMutation({
    mutationFn: createNewTender,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_DATA_TYPE_TENDER] });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "New Tender Type created.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  const handleNewTenderChange = (event) => {
    setNewTender(event.target.value);
  };

  const handleNewTenderDescriptionChange = (event) => {
    setNewTenderDescription(event.target.value);
  };

  const handleNewTenderClose = () => {
    setNewTenderOpen(false);
    setNewTender("");
  };

  const handleNewTenderClickOpen = () => {
    setNewTenderOpen(true);
  };

  const handleNewTenderAdd = (event) => {
    event.preventDefault();
    let newItem = {};
    newItem.name = snakeCase(newTender);
    newItem.display = newTender;
    newItem.description = newTenderDescription;
    mutate(newItem);
    handleNewTenderClose();
  };

  const handleEditTypeChange = (event) => {
    setEditTypeName(event.target.value);
  };

  const handleEditTypeDescriptionChange = (event) => {
    setEditTypeDescription(event.target.value);
  };

  const handleEditTypeChanged = (event) => {
    event.preventDefault();
    let dataType = {};
    dataType.name = snakeCase(editTypeName);
    dataType.display = editTypeName;
    dataType.description = editTypeDescription;
    mutateDataType({
      id: editTypeId,
      type: "tender/types",
      data: dataType,
    });
    handleEditTypeClose();
  };

  const handleEditTypeClose = () => {
    setEditTypeOpen(false);
    setEditTypeId("");
    setEditTypeName("");
    setEditTypeDescription("");
  };

  const handleEditType = (id, display, description) => {
    setEditTypeOpen(true);
    setEditTypeId(id);
    setEditTypeName(display);
    setEditTypeDescription(description);
  };

  const { mutate: mutateDataType, isPending: isPendingUpdateDataType } = useMutation({
    mutationFn: updateDataType,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_DATA_TYPE_TENDER],
      });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Data Type updated.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_DATA_TYPE_TENDER],
      });
      dispatch(
        uiActions.showNotification({
          status: "error",
          message: "Error updating Data Type. Please try again later.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  useEffect(() => {
    if (data) {
      setTenderTypes(data.items);
      setLoaded(true);
    }
  }, [data]);

  return (
    <>
      <Header
        title="Data Types"
        action={
          <Box>
            <Button
              sx={{ my: { xs: 1, lg: 0 } }}
              variant="outlined"
              disableElevation
              startIcon={<AddIcon />}
              onClick={handleNewTenderClickOpen}
            >
              Add New Tender Type
            </Button>
          </Box>
        }
      />
      <Box display="flex" sx={{ flexDirection: { xs: "column", md: "row" } }}>
        <DataTypesNav />
        <Box flex={1} sx={{ overflowX: "auto" }}>
          {isErrorTenderFetch && (
            <ErrorBlock
              title="An error occured."
              message={
                errorTenderFetch.info?.message || "Failed to fetch appointment statuses. Please try again later."
              }
            />
          )}
          {isErrorNewTender && (
            <ErrorBlock
              title="An error occured."
              message={
                errorNewTender.infor?.message || "Failed to create new Tender Type. Please check for duplicates."
              }
            />
          )}
          <Dialog
            open={newTenderOpen}
            onClose={handleNewTenderClose}
            PaperProps={{
              component: "form",
              onSubmit: handleNewTenderAdd,
            }}
            fullWidth
            maxWidth={"xs"}
          >
            <DialogTitle>
              <Typography variant="h4" component="p">
                Add Tender Type
              </Typography>
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                required
                margin="dense"
                label="Tender Type"
                type="text"
                fullWidth
                variant="outlined"
                value={newTender}
                onChange={handleNewTenderChange}
                sx={{ mb: 3 }}
              />
              <TextField
                required
                value={newTenderDescription}
                fullWidth
                label="Description"
                multiline
                rows={4}
                onChange={handleNewTenderDescriptionChange}
              />
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 3 }}>
              <Button onClick={handleNewTenderClose}>Cancel</Button>
              <Button type="submit" variant="contained" disableElevation disabled={isPendingNewTender}>
                {isPendingNewTender ? "Saving..." : "Save"}
              </Button>
            </DialogActions>
          </Dialog>
          {isPendingTenderFetch ? (
            <Loading />
          ) : (
            <>
              <Paper
                sx={{
                  width: "100%",
                  border: "1px solid #f0f0f0",
                  borderRadius: 1.5,
                  boxShadow: "none",
                }}
              >
                <TableContainer>
                  <Table size="small" sx={{ borderRadius: "6px 6px 0 0", overflow: "hidden" }}>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Tender Type</StyledTableCell>
                        <StyledTableCell>Description</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loaded && tenderTypes.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={3}>No Current Tender Types</TableCell>
                        </TableRow>
                      )}
                      {loaded && tenderTypes.length > 0 && (
                        <>
                          {tenderTypes.map((tenderType) => (
                            <TableRow key={tenderType.id} hover>
                              <TableCell sx={{ minWidth: 200 }}>{tenderType.display}</TableCell>
                              <TableCell sx={{ minWidth: 200 }}>{tenderType.description}</TableCell>
                              <TableCell>
                                <IconButton
                                  size="small"
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => {
                                    handleEditType(tenderType.id, tenderType.display, tenderType.description);
                                  }}
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                      <Dialog
                        open={editTypeOpen}
                        onClose={handleEditTypeClose}
                        PaperProps={{
                          component: "form",
                          onSubmit: handleEditTypeChanged,
                        }}
                        fullWidth
                        maxWidth={"xs"}
                      >
                        <DialogTitle>
                          <Typography variant="h4" component="p">
                            Edit Tender Type
                          </Typography>
                        </DialogTitle>
                        <DialogContent>
                          <TextField
                            autoFocus
                            required
                            margin="dense"
                            label="Tender Type"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={editTypeName}
                            onChange={handleEditTypeChange}
                            sx={{ mb: 3 }}
                          />

                          <TextField
                            required
                            margin="dense"
                            label="Description"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={editTypeDescription}
                            multiline
                            rows={4}
                            onChange={handleEditTypeDescriptionChange}
                          />
                        </DialogContent>
                        <DialogActions sx={{ px: 3, pb: 3 }}>
                          <Button onClick={handleEditTypeClose}>Cancel</Button>
                          <Button type="submit" variant="contained" disableElevation disabled={isPendingUpdateDataType}>
                            {isPendingUpdateDataType ? "Saving..." : "Save"}
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default DataTypeTender;
