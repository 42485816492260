import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { INFO_DRAWER_WIDTH, QUERY_KEY_PRACTICE } from "../../store/constants";
import { fetchPractice } from "../../utils/api/practices.api";
import classes from "./Drawers.module.css";
import Loading from "../Loading";
import CloseIcon from "@mui/icons-material/Close";
import useCustomClaims from "../../utils/hooks/useCustomClaims";

const FillerDrawer = (props) => {
  const params = useParams();
  const uuid = params.uuid;
  const { isBonsaiAdmin } = useCustomClaims();

  /** Email Preview */
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };

  /** Fetch Practice **/
  const { data: formData, isPending } = useQuery({
    queryKey: [QUERY_KEY_PRACTICE],
    queryFn: async ({ signal }) => {
      const [practice] = await Promise.all([fetchPractice({ uuid, signal })]);
      return { practice };
    },
  });

  if (isPending) {
    return <Loading />;
  }

  return (
    <Box sx={{ width: { xs: "100%", sm: INFO_DRAWER_WIDTH } }}>
      <Box
        sx={{ backgroundColor: "primary.main", display: "flex", alignItems: "center", justifyContent: "space-between" }}
      >
        <Typography component="p" noWrap sx={{ color: "white", p: 2, fontWeight: 500 }}>
          Filler Overdue
        </Typography>
        <IconButton onClick={props.onClick} sx={{ mr: 1 }}>
          <CloseIcon sx={{ color: "primary.contrastText" }} />
        </IconButton>
      </Box>
      <Box p={2} pb={5}>
        <Typography component="p" className={classes.label}>
          What is this?
        </Typography>
        <Typography component="p" className={classes.info}>
          Filler overdue patients are those who are due for their next injection of a filler, such as Juvederm,
          Restylane, Radiesse, Sculptra, Belotero Balance, Revanesse, Versa, RHA or Redensity and currently do not have
          a scheduled appointment
        </Typography>
        {formData?.practice.systemOfRecord === "modmed" && isBonsaiAdmin && (
          <>
            <Typography component="p" className={classes.label}>
              How is it calculated?
            </Typography>
            <ul className={classes.ulDisc}>
              <li className={classes.li}>
                Find patients who haven't had a recent treatment:
                <ul className={classes.ulCircle}>
                  <li className={classes.li}>Use the "Production (Custom)" report.</li>
                  <li className={classes.li}>
                    Look for patients who previously had a filler treatment as per combination of (CPT Product and CPT
                    Product Desc).
                  </li>
                  <li className={classes.li}>
                    Exclude patients who had a treatment within the last 9 months (270 days).
                  </li>
                </ul>
              </li>
              <li className={classes.li}>
                Exclude patients with upcoming appointments:
                <ul className={classes.ulCircle}>
                  <li className={classes.li}>Use the "Appointment (Custom)" report again.</li>
                  <li className={classes.li}>Remove patients with upcoming filler appointments.</li>
                  <li className={classes.li}>Exclude "No Show" and "Canceled" appointments as well.</li>
                </ul>
              </li>
              <li className={classes.li}>
                Exclude inactive patients:
                <ul className={classes.ulCircle}>
                  <li className={classes.li}>Use the "Demographics (Custom)" report.</li>
                  <li className={classes.li}>Remove patients marked as inactive based on their account status.</li>
                </ul>
              </li>
              <li className={classes.li}>
                Final list:
                <ul className={classes.ulCircle}>
                  <li className={classes.li}>
                    The remaining patients from step 1, after removing those with upcoming appointments and inactive
                    accounts,are the ones eligible for filler reminders.
                  </li>
                </ul>
              </li>
            </ul>
          </>
        )}

        {formData?.practice.systemOfRecord === "aesthetic_record" && isBonsaiAdmin && (
          <>
            <Typography component="p" className={classes.label}>
              How is it calculated?
            </Typography>
            <ul className={classes.ulDisc}>
              <li className={classes.li}>
                Find patients who haven't had a recent treatment:
                <ul className={classes.ulCircle}>
                  <li className={classes.li}>Use the "Sales (Invoices)" report.</li>
                  <li className={classes.li}>
                    Look for patients who previously had a filler treatment as per combination of (item and category).
                  </li>
                  <li className={classes.li}>
                    Exclude patients who had a treatment within the last 9 months (270 days).
                  </li>
                </ul>
              </li>
              <li className={classes.li}>
                Exclude patients with upcoming appointments:
                <ul className={classes.ulCircle}>
                  <li className={classes.li}>Use the "Appointments (Booking History)" report again.</li>
                  <li className={classes.li}>Remove patients with upcoming filler appointments.</li>
                  <li className={classes.li}>Exclude "No Show" and "Canceled" appointments as well.</li>
                </ul>
              </li>
              <li className={classes.li}>
                Exclude inactive patients:
                <ul className={classes.ulCircle}>
                  <li className={classes.li}>Use the "Patients (Clients)" report.</li>
                  <li className={classes.li}>Remove patients who are Fired.</li>
                </ul>
              </li>
              <li className={classes.li}>
                Exclude inconclusive patients:
                <ul className={classes.ulCircle}>
                  <li className={classes.li}>Use the "Patients (Clients)" report.</li>
                  <li className={classes.li}>
                    Remove patients that have the same 'First Name' and 'Last Name' but different 'Ids'.
                  </li>
                </ul>
              </li>
              <li className={classes.li}>
                Final list:
                <ul className={classes.ulCircle}>
                  <li className={classes.li}>
                    The remaining patients from step 1, after removing those with upcoming appointments, inconclusive
                    and inactive patients,are the ones eligible for filler reminders.
                  </li>
                </ul>
              </li>
            </ul>
          </>
        )}
        <Typography component="p" className={classes.label}>
          What happens when a reminder is enabled?
        </Typography>
        <Typography component="p" className={classes.info}>
          Bonsai finds patients due for filler treatments and sends them{" "}
          <Link underline="always" onClick={handleToggle}>
            reminder emails
          </Link>{" "}
          over 90 days.
        </Typography>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Card variant="outlined" sx={{ mb: 3 }}>
            <CardHeader title="Schedule your next filler appointment" sx={{ backgroundColor: "primary.gray200" }} />
            <CardContent>
              <Typography variant="body2">
                Hi [Patient first Name],
                <br />
                <br />
                Our records indicate it is time for your next filler treatment. As a reminder, we recommend fillers
                treatments every six months to maintain optimal results.
                <br />
                <br />
                Please schedule your appointment here or call us directly at [phone number].
                <br />
                <br />
                Sincerely,
                <br />
                {formData?.practice?.name ? formData.practice.name : "[Practice Name]"}
              </Typography>
            </CardContent>
          </Card>
        </Collapse>
        <Typography component="p" className={classes.label}>
          Campaign Schedule:
        </Typography>
        <TableContainer component={Paper} sx={{ mb: 3 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 600 }}>Reminder</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Day</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Channel</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Local Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>1</TableCell>
                <TableCell>1</TableCell>
                <TableCell>Text</TableCell>
                <TableCell>9:00 AM</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>2</TableCell>
                <TableCell>2</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>12:00 PM</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>3</TableCell>
                <TableCell>5</TableCell>
                <TableCell>Text</TableCell>
                <TableCell>12:00 PM</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>4</TableCell>
                <TableCell>30</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>3:00 PM</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>5</TableCell>
                <TableCell>60</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>9:00 AM</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Typography component="p" className={classes.label}>
          How patients exit the Campaign?
        </Typography>
        <ul className={classes.ulDisc}>
          <li className={classes.li}>Booking an appointment through the online form.</li>
          <li className={classes.li}>Calling the practice using the phone number provided.</li>
          <li className={classes.li}>Having a new appointment created in the system by the practice.</li>
          <li className={classes.li}>Unsubscribing from the campaign emails.</li>
          <li className={classes.li}>The practice manually opting them out.</li>
        </ul>
      </Box>
    </Box>
  );
};

export default FillerDrawer;
