import { Box, DialogContent, Typography } from "@mui/material";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { QUERY_KEY_PRACTICE } from "../../store/constants";
import { useQuery } from "@tanstack/react-query";
import Loading from "../Loading";
import { fetchPractice } from "../../utils/api/practices.api";

const SkinScreeningSmsPreview = () => {
  const params = useParams();
  const uuid = params.uuid;
  /** Fetch Practice **/
  const { data: formData, isPending } = useQuery({
    queryKey: [QUERY_KEY_PRACTICE, uuid],
    queryFn: async ({ signal }) => {
      const [practice] = await Promise.all([fetchPractice({ uuid, signal })]);
      return { practice };
    },
  });

  if (isPending) {
    return <Loading />;
  }
  return (
    <>
      <Box sx={{ backgroundColor: "primary.gray200", p: 2 }}>
        <Typography variant="bodySemi">SMS Message</Typography>
      </Box>

      <DialogContent p={3}>
        <Typography>
          Hi there,
          <br />
          <br />
          It's been a year since your last skin screening. Let's schedule your next one to stay proactive about your
          skin health.
          <br />
          <br />
          Call us at [phone] or request{" "}
          <Link href="https://form.jotform.com/241775643117156" target="_blank" underline="always">
            here
          </Link>
          .
          <br />
          <br />
          Reply 'UNSUB' to unsubscribe
          <br />
          <br />
          Best,
          <br />
          {formData?.practice?.name ? formData.practice.name : "[Practice Name]"}
        </Typography>
      </DialogContent>
    </>
  );
};

export default SkinScreeningSmsPreview;
