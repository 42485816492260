import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import useCustomClaims from "../utils/hooks/useCustomClaims";

const HomePage = () => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { isMultiPracticeUser } = useCustomClaims();

  useEffect(() => {
    if (isMultiPracticeUser) {
      navigate("/practices");
    } else if (user.bonsaiHealthAppPracticeUuids.length === 1) {
      const [uuid] = user.bonsaiHealthAppPracticeUuids;
      navigate(`/practices/${uuid}/campaigns`);
    }
  }, [user, isMultiPracticeUser, navigate]);
  return <div>HomePage</div>;
};

export default HomePage;
