import { Button, ButtonGroup } from "@mui/material";
import React, { useState } from "react";
import classes from "../../styles/Filters.module.css";

const FrequencyToggle = ({ change }) => {
  const frequencies = [
    { label: "Monthly", value: "monthly" },
    { label: "Quarterly", value: "quarterly" },
    { label: "Annual", value: "yearly" },
  ];
  const [selectedFrequency, setSelectedFrequency] = useState("monthly");
  const handleFrequencyClick = (event) => {
    setSelectedFrequency(event.target.value);
    change(event.target.value);
  };
  return (
    <>
      <ButtonGroup
        disableElevation
        variant="contained"
        aria-label="Report Frequency"
        sx={{ border: "1px solid #D0D5DD" }}
      >
        {frequencies.map((frequency, i) => (
          <Button
            key={i}
            className={selectedFrequency === frequency.value ? classes.buttonActive : classes.button}
            value={frequency.value}
            onClick={handleFrequencyClick}
          >
            {frequency.label}
          </Button>
        ))}
      </ButtonGroup>
    </>
  );
};

export default FrequencyToggle;
