export const icd10 = [
  { code: "D22.5" },
  { code: "L57.0" },
  { code: "L82.1" },
  { code: "D48.5" },
  { code: "L57.8" },
  { code: "L82.0" },
  { code: "Z08" },
  { code: "D49.2" },
  { code: "Z12.83" },
  { code: "D22.62" },
  { code: "D22.61" },
  { code: "D22.72" },
  { code: "Z87.2" },
  { code: "Z85.828" },
  { code: "D22.71" },
  { code: "L91.8" },
  { code: "D22.9" },
  { code: "D22.39" },
  { code: "D23.72" },
  { code: "D22.4" },
  { code: "D23.71" },
  { code: "Z85.820" },
  { code: "D23.39" },
  { code: "D23.5" },
  { code: "Z80.8" },
  { code: "D23.61" },
  { code: "D37.01" },
  { code: "L57.3" },
  { code: "D23.62" },
  { code: "D17.22" },
  { code: "D22.0" },
  { code: "C44.319" },
  { code: "D23.9" },
  { code: "C44.311" },
  { code: "L56.5" },
  { code: "C44.719" },
  { code: "C44.329" },
  { code: "C44.722" },
  { code: "D04.5" },
  { code: "C44.519" },
  { code: "D29.4" },
  { code: "D04.9" },
  { code: "C44.619" },
  { code: "C44.92" },
  { code: "C44.712" },
  { code: "C44.91" },
  { code: "C43.62" },
  { code: "D04.39" },
  { code: "D23.112" },
  { code: "C44.42" },
  { code: "C44.612" },
  { code: "C44.629" },
  { code: "C44.729" },
  { code: "D04.62" },
  { code: "L59.0" },
  { code: "C84.A0" },
  { code: "C43.39" },
  { code: "D04.61" },
  { code: "C44.41" },
  { code: "C44.219" },
  { code: "D04.71" },
  { code: "C79.9" },
];

export const ICD10_WITH_DESCRIPTION = [
  { code: "L57.0", description: "Actinic keratosis" },
  { code: "D22.5", description: "Melanocytic nevi of trunk" },
  { code: "L82.1", description: "Other seborrheic keratosis" },
  { code: "L82.0", description: "Inflamed seborrheic keratosis" },
  { code: "D22.9", description: "Melanocytic nevi, unspecified" },
  { code: "L91.8", description: "Other hypertrophic disorders of the skin" },
  { code: "D22.4", description: "Melanocytic nevi of scalp and neck" },
  { code: "D22.0", description: "Melanocytic nevi of lip" },
  { code: "Z87.2", description: "Personal history of diseases of the skin and subcutaneous tissue" },
  { code: "Z85.820", description: "Personal history of malignant melanoma of skin" },
  { code: "Z12.83", description: "Encounter for screening for malignant neoplasm of skin" },
  { code: "Z85.828", description: "Personal history of other malignant neoplasm of skin" },
  { code: "Z85.821", description: "Personal history of malignant neoplasm of other parts of skin" },
  { code: "Z86.008", description: "Personal history of other neoplasms of skin" },
  { code: "Z86.018", description: "Personal history of in situ neoplasm of other and unspecified sites" },
  { code: "D22.1", description: "Melanocytic nevi of eyelid, including canthus" },
  { code: "D22.2", description: "Melanocytic nevi of ear and external auricular canal" },
  { code: "D22.3", description: "Melanocytic nevi of other parts of face" },
  { code: "D22.6", description: "Melanocytic nevi of upper limb, including shoulder" },
  { code: "D22.7", description: "Melanocytic nevi of lower limb, including hip" },
  { code: "D22.8", description: "Melanocytic nevi of other sites" },
  { code: "L57.8", description: "Other skin changes due to chronic exposure to nonionizing radiation" },
  { code: "D48.5", description: "Neoplasm of uncertain behavior of skin" },
  { code: "D22.61", description: "Melanocytic nevi of right upper limb, including shoulder" },
  { code: "D22.62", description: "Melanocytic nevi of left upper limb, including shoulder" },
  { code: "D22.71", description: "Melanocytic nevi of right lower limb, including hip" },
  { code: "D22.72", description: "Melanocytic nevi of left lower limb, including hip" },
  { code: "D22.39", description: "Melanocytic nevi of other parts of face" },
  { code: "D23.72", description: "Other benign neoplasm of skin of left lower limb, including hip" },
  { code: "D23.71", description: "Other benign neoplasm of skin of right lower limb, including hip" },
  { code: "D23.39", description: "Other benign neoplasm of skin of other parts of face" },
  { code: "Z08", description: "Encounter for follow-up examination after completed treatment for malignant neoplasm" },
  { code: "D23.61", description: "Benign neoplasm of right upper limb, including shoulder" },
  { code: "D37.01", description: "Neoplasm of uncertain behavior of lip" },
  { code: "D23.5", description: "Benign neoplasm of trunk, unspecified" },
  { code: "D23.62", description: "Benign neoplasm of left upper limb, including shoulder" },
  { code: "D29.4", description: "Benign neoplasm of skin of scalp and neck" },
  { code: "L57.3", description: "Actinic keratosis" },
  { code: "Z80.8", description: "Personal history of malignant neoplasm of other organs and systems" },
  { code: "D23.9", description: "Benign neoplasm of skin, unspecified" },
  { code: "L56.5", description: "Chronic actinic dermatitis" },
  { code: "C44.529", description: "Squamous cell carcinoma of skin of unspecified parts of face" },
  { code: "D17.22", description: "Benign lipomatous neoplasm of skin of trunk" },
  { code: "D23.112", description: "Benign neoplasm of skin of left eyelid, including canthus" },
  { code: "C44.719", description: "Basal cell carcinoma of skin of other parts of head" },
  { code: "D04.39", description: "Carcinoma in situ of other parts of skin" },
  { code: "D04.5", description: "Carcinoma in situ of skin of trunk" },
  { code: "C44.41", description: "Basal cell carcinoma of skin of scalp and neck" },
  { code: "C44.722", description: "Squamous cell carcinoma of skin of left ear and external auricular canal" },
  { code: "C44.319", description: "Unspecified malignant neoplasm of skin of other and unspecified parts of face" },
  { code: "C44.212", description: "Squamous cell carcinoma of skin of other and unspecified parts of face" },
  { code: "D04.72", description: "Carcinoma in situ of skin of abdomen" },
  { code: "C44.311", description: "Basal cell carcinoma of skin of other and unspecified parts of face" },
  { code: "D22.121", description: "Melanocytic nevi of right upper eyelid, including canthus" },
  { code: "D04.62", description: "Carcinoma in situ of skin of left upper limb, including shoulder" },
  { code: "C44.91", description: "Unspecified malignant neoplasm of skin" },
  { code: "C44.329", description: "Squamous cell carcinoma of skin of unspecified eyelid, including canthus" },
  { code: "C44.519", description: "Basal cell carcinoma of skin, unspecified eyelid, including canthus" },
];
