import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { QUERY_KEY_SPAM_SETTINGS } from "../../store/constants";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchPracticeSpamSettings, savePracticeSpamSettings } from "../../utils/api/spam-settings.api";
import { queryClient } from "../../utils/http";
import { uiActions } from "../../store/ui-slice";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tab, Tabs, Typography } from "@mui/material";
import TextInput from "../TextInput";

function TabPanel({ children, index, value }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
    >
      {
        value === index &&
        <>
          {children}
        </>
      }
    </div>
  );
}

function CampaignSpamSettingsModal({ isOpen, onClose }) {
  const { uuid: practiceUuid } = useParams();
  const dispatch = useDispatch();
  const [practiceSpamSettings, setPracticeSpamSettings] = useState([]);
  const queryKey = [QUERY_KEY_SPAM_SETTINGS, practiceUuid];

  /** Fetch Practice Spam Settings **/
  const { data: spamSettings, isPending } = useQuery({
    queryKey: queryKey,
    queryFn: ({ signal }) => fetchPracticeSpamSettings({ practiceUuid, signal }),
  });

  useEffect(() => {
    if (isPending) {
      return;
    }
    setPracticeSpamSettings(spamSettings);
  }, [setPracticeSpamSettings, spamSettings, isPending]);

  /** Tab Management **/
  const tabs = ['email', 'sms'];
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  /** Spam Setting Form Updates **/
  const handleChangeValue = ($event, uuid) => {
    const updatedValues = practiceSpamSettings.map((setting) => {
      if (setting.uuid !== uuid) {
        return setting;
      }
      return {
        ...setting,
        maxMessagesAllowed: $event.target.value,
      };
    });
    setPracticeSpamSettings(updatedValues);
  };

  /** Save Practice Spam Settings **/
  const { mutate: saveSpamSettings } = useMutation({
    mutationFn: () => {
      const settings = practiceSpamSettings.map((setting) => ({
        type: setting.type,
        days: setting.days,
        maxMessagesAllowed: parseInt(setting.maxMessagesAllowed, 10),
      }));
      return savePracticeSpamSettings({ practiceUuid, settings});
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKey,
      });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Practice message limits saved",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
    onError: () => {
      dispatch(
        uiActions.showNotification({
          status: "error",
          message: "Error saving practice message limits",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  const handleSubmit = ($event) => {
    $event.preventDefault();
    saveSpamSettings();
  };


  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit,
      }}
    >
      <DialogTitle>
        <Typography variant="h3" component="p">
          Message Communication Limits
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Tabs sx={{marginBottom: '32px' }} value={tabValue} onChange={handleTabChange}>
          {
            tabs.map((tab, i) => (
              <Tab label={tab} key={i} />
            ))
          }
        </Tabs>
        {
          tabs.map((tab, index) => (
            <TabPanel value={tabValue} index={index} key={index}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {
                    !isPending && practiceSpamSettings.length && practiceSpamSettings
                    .filter(setting => setting.type === tab)
                    .map((setting) => (
                      <>
                        <TextInput
                          min={1}
                          required
                          label={`${setting.days} Day Limit`}
                          type="number"
                          value={setting.maxMessagesAllowed}
                          onChange={($event) => handleChangeValue($event, setting.uuid)}
                        />
                      </>
                    ))
                  }
                </Grid>
              </Grid>
            </TabPanel>
          ))
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit" variant="contained" disableElevation>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default CampaignSpamSettingsModal;