import { QueryClient } from "@tanstack/react-query";
import { dataIngestionClient } from "./dataIngestionClient";
import { coreAppClient } from "./coreAppClient";

const {
  REACT_APP_API_ENDPOINT,
} = process.env;

export const queryClient = new QueryClient();

// FETCH APPOINTMENT DATA (TYPES, CATEGORIES)
export async function fetchAppointmentData({ signal }) {
  const response = {};

  const types = await fetch(`${REACT_APP_API_ENDPOINT}/appointments/types`, {
    signal: signal,
  });

  if (!types.ok) {
    const error = new Error("An error occured while fetching Appointment Types");
    error.code = types.status;
    error.info = await types.json();
    throw error;
  }

  const categories = await fetch(`${REACT_APP_API_ENDPOINT}/appointments/categories`, {
    signal: signal,
  });

  if (!categories.ok) {
    const error = new Error("An error occured while fetching Appointment Categories");
    error.code = categories.status;
    error.info = await categories.json();
    throw error;
  }

  response.types = await types.json();
  response.categories = await categories.json();

  return response;
}

// NEW APPOINTMENT CATEGORY
export async function createNewAppointmentCategory(data) {
  const response = await fetch(`${REACT_APP_API_ENDPOINT}/appointments/categories`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("An error occurred while creating the Appointment Category");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return await response.json();
}

// NEW APPOINTMENT TYPE
export async function createNewAppointmentType(data) {
  const response = await fetch(`${REACT_APP_API_ENDPOINT}/appointments/types`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("An error occurred while creating the Appointment Type");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return await response.json();
}

// UPDATE APPOINTMENT TYPE
export async function updateAppointmentType({ id, data }) {
  const response = await fetch(`${REACT_APP_API_ENDPOINT}/appointments/types/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("An error occurred while updating the Appointment Category.");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return response.json();
}

// FETCH APPOINTMENT STATUSES
export async function fetchAppointmentStatuses({ signal }) {
  const response = await fetch(`${REACT_APP_API_ENDPOINT}/appointments/statuses`, {
    signal: signal,
  });

  if (!response.ok) {
    const error = new Error("An error occured while fetching Appointment Statuses");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return await response.json();
}

// FETCH TENDER TYPES
export async function fetchTenderTypes({ signal }) {
  const response = await fetch(`${REACT_APP_API_ENDPOINT}/tender/types`, {
    signal: signal,
  });

  if (!response.ok) {
    const error = new Error("An error occured while fetching Tender Types");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return await response.json();
}

// FETCH POLICY TYPES
export async function fetchPolicyTypes({ signal }) {
  const response = await fetch(`${REACT_APP_API_ENDPOINT}/policy/types`, {
    signal: signal,
  });

  if (!response.ok) {
    const error = new Error("An error occured while fetching Policy Types");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return await response.json();
}

// CREATE NEW APPOINTMENT STATUS
export async function createNewAppointmentStatus(data) {
  const response = await fetch(`${REACT_APP_API_ENDPOINT}/appointments/statuses`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("An error occurred while creating the Appointment Status");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return await response.json();
}

// CREATE NEW TENDER
export async function createNewTender(data) {
  const response = await fetch(`${REACT_APP_API_ENDPOINT}/tender/types`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("An error occurred while creating the Tender Type");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return await response.json();
}

// CREATE NEW POLICY
export async function createNewPolicy(data) {
  const response = await fetch(`${REACT_APP_API_ENDPOINT}/policy/types`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("An error occurred while creating the Policy Type");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return await response.json();
}

// FETCH PROCEDURE DATA (TYPES, CATEGORIES)
export async function fetchProcedureData({ signal }) {
  const response = {};

  const types = await fetch(`${REACT_APP_API_ENDPOINT}/procedures/types`, {
    signal: signal,
  });

  if (!types.ok) {
    const error = new Error("An error occured while fetching Procedure Types");
    error.code = types.status;
    error.info = await types.json();
    throw error;
  }

  const categories = await fetch(`${REACT_APP_API_ENDPOINT}/procedures/categories`, {
    signal: signal,
  });

  if (!categories.ok) {
    const error = new Error("An error occured while fetching Procedure Categories");
    error.code = categories.status;
    error.info = await categories.json();
    throw error;
  }

  response.types = await types.json();
  response.categories = await categories.json();

  return response;
}

// NEW PROCEDURE TYPE
export async function createNewProcedureType(data) {
  const response = await fetch(`${REACT_APP_API_ENDPOINT}/procedures/types`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("An error occurred while creating the Procedure Type");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return await response.json();
}

// UPDATE PROCEDURE TYPE
export async function updateProcedureType({ id, data }) {
  const response = await fetch(`${REACT_APP_API_ENDPOINT}/procedures/types/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("An error occurred while updating the Procedure Category.");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return response.json();
}

// NEW PROCEDURE CATEGORY
export async function createNewProcedureCategory(data) {
  const response = await fetch(`${REACT_APP_API_ENDPOINT}/procedures/categories`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("An error occurred while creating the Procedure Category");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return await response.json();
}

// FETCH PRACTICE LOCATIONS
export async function fetchPracticeLocations({ uuid, signal }) {
  const response = {};

  const locations = await fetch(`${REACT_APP_API_ENDPOINT}/practices/${uuid}/locations`, {
    signal: signal,
  });

  if (!locations.ok) {
    const error = new Error("An error occurred while fetching practice locations");
    error.code = locations.status;
    error.info = await locations.json();
    throw error;
  }

  response.locations = await locations.json();

  const values = await fetch(`${REACT_APP_API_ENDPOINT}/practices/${uuid}/locations/possible-values`);

  if (!values.ok) {
    const error = new Error("An error occurred while fetching practice values");
    error.code = values.status;
    error.info = await values.json();
    throw error;
  }

  response.values = await values.json();

  return response;
}

const fetchMappings = async ({ practiceDataUrl, bonsaiDataUrl, signal }) => {
  try {
    const [practiceDataResponse, bonsaiDataResponse] = await Promise.all([
      dataIngestionClient.get(practiceDataUrl, { signal },
      ),
      coreAppClient.get(bonsaiDataUrl, { signal })
    ]);
    return { practiceData: practiceDataResponse.data, bonsaiData: bonsaiDataResponse.data };
  } catch (error) {
    const errorData = new Error("An error occurred while fetching mappings");
    errorData.code = error.response.status;
    errorData.info = error.response.data;
    throw errorData;
  }
};

const mapOrderDirection = (direction) => {
  return (direction.toLowerCase() === 'asc') ? 'ASC' : 'DESC';
}
const buildAndFilter = (filter) => {
  return filter !== null ? `&filter=${filter}` : '';
}
const buildOrderFilterUri = (orderField, order, filter) => {
  return `&orderField=${orderField}&order=${mapOrderDirection(order)}${buildAndFilter(filter)}`
}

// FETCH PRACTICE APPOINTMENT MAPPINGS
export async function fetchPracticeAppointmentMappings({ uuid, limit, offset, orderField, order, filter, signal }) {
  try {
    return await fetchMappings({
      practiceDataUrl: `api/${uuid}/system-of-record/appointment-type?limit=${limit}&offset=${offset}${buildOrderFilterUri(orderField, order, filter)}`,
      bonsaiDataUrl: 'appointments/types',
      signal,
    });
  } catch (error) {
    error.message = "An error occurred while fetching appointment mappings";
    throw error;
  }
}

// FETCH PRACTICE PROCEDURE MAPPINGS
export async function fetchPracticeProcedureMappings({ uuid, limit, offset, orderField, order, filter, signal }) {
  try {
    return await fetchMappings({
      practiceDataUrl: `api/${uuid}/system-of-record/procedure-type?limit=${limit}&offset=${offset}${buildOrderFilterUri(orderField, order, filter)}`,
      bonsaiDataUrl: 'procedures/types',
      signal,
    });
  } catch (error) {
    error.message = "An error occurred while fetching procedure mappings";
    throw error;
  }
}

// FETCH PRACTICE LOCATION MAPPINGS
export async function fetchPracticeLocationMappings({ uuid, limit, offset, orderField, order, filter, signal }) {
  try {
    return await fetchMappings({
      practiceDataUrl: `api/${uuid}/system-of-record/location?limit=${limit}&offset=${offset}${buildOrderFilterUri(orderField, order, filter)}`,
      bonsaiDataUrl: `practices/${uuid}/locations`,
      signal,
    });
  } catch (error) {
    error.message = "An error occurred while fetching location mappings";
    throw error;
  }
}

// FETCH PRACTICE PROVIDER MAPPINGS
export async function fetchPracticeProviderMappings({ uuid, limit, offset, orderField, order, filter, signal }) {
  try {
    return await fetchMappings({
      practiceDataUrl: `api/${uuid}/system-of-record/provider?limit=${limit}&offset=${offset}${buildOrderFilterUri(orderField, order, filter)}`,
      bonsaiDataUrl: `practices/${uuid}/users`,
      signal,
    });
  } catch (error) {
    error.message = "An error occurred while fetching provider mappings";
    throw error;
  }
}

// FETCH PRACTICE RETAIL MAPPINGS
export async function fetchPracticeRetailMappings({ uuid, limit, offset, orderField, order, filter, signal }) {
  try {
    return await fetchMappings({
      practiceDataUrl: `api/${uuid}/system-of-record/retail-type?limit=${limit}&offset=${offset}${buildOrderFilterUri(orderField, order, filter)}`,
      bonsaiDataUrl: 'retails/types',
      signal,
    });
  } catch (error) {
    error.message = "An error occurred while fetching retail mappings";
    throw error;
  }
}

// FETCH PRACTICE APPOINTMENT STATUS MAPPINGS
export async function fetchPracticeAppointmentStatusMappings({ uuid, limit, offset, orderField, order, filter, signal }) {
  try {
    return await fetchMappings({
      practiceDataUrl: `api/${uuid}/system-of-record/appointment-status?limit=${limit}&offset=${offset}${buildOrderFilterUri(orderField, order, filter)}`,
      bonsaiDataUrl: 'appointments/statuses',
      signal,
    });
  } catch (error) {
    error.message = "An error occurred while fetching appointment mappings";
    throw error;
  }
}

// FETCH PRACTICE APPOINTMENT STATUS MAPPINGS
export async function fetchPracticeTenderMappings({ uuid, limit, offset, orderField, order, filter, signal }) {
  try {
    return await fetchMappings({
      practiceDataUrl: `api/${uuid}/system-of-record/tender-type?limit=${limit}&offset=${offset}${buildOrderFilterUri(orderField, order, filter)}`,
      bonsaiDataUrl: 'tender/types',
      signal,
    });
  } catch (error) {
    error.message = "An error occurred while fetching tender mappings";
    throw error;
  }
}

// FETCH PRACTICE POLICY MAPPINGS
export async function fetchPracticePolicyMappings({ uuid, limit, offset, orderField, order, filter, signal }) {
  try {
    return await fetchMappings({
      practiceDataUrl: `api/${uuid}/system-of-record/policy-type?limit=${limit}&offset=${offset}${buildOrderFilterUri(orderField, order, filter)}`,
      bonsaiDataUrl: 'policy/types',
      signal,
    });
  } catch (error) {
    error.message = "An error occurred while fetching practice tender mappings";
    throw error;
  }
}

// UPDATE PRACTICE MAPPING
export async function updatePracticeMapping({ uuid, id, data }) {
  try {
    const response = await dataIngestionClient.patch(`api/practices/${uuid}/mappings/${id}`, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    const errorData = new Error("An error occurred while updating practice mapping substitute.");
    errorData.code = error.response.status;
    errorData.info = error.response.data;
    throw errorData;
  }
}

// RETAIL DATA (TYPES, CATEGORIES)
export async function fetchRetailData({ signal }) {
  const response = {};

  const types = await fetch(`${REACT_APP_API_ENDPOINT}/retails/types`, {
    signal: signal,
  });

  if (!types.ok) {
    const error = new Error("An error occurred while fetching Retail Types");
    error.code = types.status;
    error.info = await types.json();
    throw error;
  }

  const categories = await fetch(`${REACT_APP_API_ENDPOINT}/retails/categories`, {
    signal: signal,
  });

  if (!categories.ok) {
    const error = new Error("An error occurred while fetching Retail Categories");
    error.code = categories.status;
    error.info = await categories.json();
    throw error;
  }

  response.types = await types.json();
  response.categories = await categories.json();

  return response;
}

// TENDER DATA (TYPES, CATEGORIES)
export async function fetchTenderData({ signal }) {
  const response = {};

  const types = await fetch(`${REACT_APP_API_ENDPOINT}/tender/types`, {
    signal: signal,
  });

  if (!types.ok) {
    const error = new Error("An error occurred while fetching Tender Types");
    error.code = types.status;
    error.info = await types.json();
    throw error;
  }

  const categories = await fetch(`${REACT_APP_API_ENDPOINT}/retails/categories`, {
    signal: signal,
  });

  if (!categories.ok) {
    const error = new Error("An error occurred while fetching Retail Categories");
    error.code = categories.status;
    error.info = await categories.json();
    throw error;
  }

  response.types = await types.json();
  response.categories = await categories.json();

  return response;
}

// NEW RETAIL TYPE
export async function createNewRetailType(data) {
  const response = await fetch(`${REACT_APP_API_ENDPOINT}/retails/types`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("An error occurred while creating the Retail Type");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return await response.json();
}

// UPDATE RETAIL TYPE
export async function updateRetailType({ id, data }) {
  const response = await fetch(`${REACT_APP_API_ENDPOINT}/retails/types/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("An error occurred while updating the Retail Category.");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return response.json();
}

// NEW RETAIL CATEGORY
export async function createNewRetailCategory(data) {
  const response = await fetch(`${REACT_APP_API_ENDPOINT}/retails/categories`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("An error occurred while creating the Retail Category");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return await response.json();
}

// DATA TYPE PATCH
export async function updateDataType({ id, type, data }) {
  const response = await fetch(`${REACT_APP_API_ENDPOINT}/${type}/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("An error occurred while updating the Data Type.");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return response.json();
}

// CREATE NEW MAPPING
export async function createNewMapping({ uuid, type, data }) {
  try {
    const mapping = { mappings: [data] };
    const response = await dataIngestionClient.post(`api/practices/${uuid}/mappings/${type}`, mapping, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    const errorData = new Error("An error occurred while creating Mapping");
    errorData.code = error.response.status;
    errorData.info = error.response.data;
    throw errorData;
  }
}

// UPDATE PRACTICE
export async function updatePracticeLocation({ uuid, id, data }) {
  const response = await fetch(`${REACT_APP_API_ENDPOINT}/practices/${uuid}/locations/${id}`, {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("An error occurred while updating the Appointment Category.");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return response.json();
}

// FETCH DIAGNOSIS TYPES
export async function fetchRecallTypes({ signal }) {
  const response = await fetch(`${REACT_APP_API_ENDPOINT}/diagnosis-codes`, {
    signal: signal,
  });

  if (!response.ok) {
    const error = new Error("An error occured while fetching Diagnosis Types");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return await response.json();
}

// NEW DIAGNOSIS TYPE
export async function createNewRecallType(data) {
  const response = await fetch(`${REACT_APP_API_ENDPOINT}/diagnosis-codes`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error = new Error("An error occurred while creating the Diagnosis Type");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return await response.json();
}
