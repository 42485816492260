import { DialogContent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";
import { QUERY_KEY_PRACTICE } from "../../store/constants";
import { fetchPractice } from "../../utils/api/practices.api";
import Loading from "../Loading";

const FillerEmailPreview = () => {
  const params = useParams();
  const uuid = params.uuid;
  /** Fetch Practice **/
  const { data: formData, isPending } = useQuery({
    queryKey: [QUERY_KEY_PRACTICE, uuid],
    queryFn: async ({ signal }) => {
      const [practice] = await Promise.all([fetchPractice({ uuid, signal })]);
      return { practice };
    },
  });

  if (isPending) {
    return <Loading />;
  }

  return (
    <>
      <Box sx={{ backgroundColor: "primary.gray200", p: 2 }}>
        <Typography variant="bodySemi">Schedule your next filler appointment</Typography>
      </Box>

      <DialogContent p={3}>
        <Typography>
          Hi [Patient first Name],
          <br />
          <br />
          Our records indicate it is time for your next filler treatment. As a reminder, we recommend fillers treatments
          every six months to maintain optimal results.
          <br />
          <br />
          Please schedule your appointment here or call us directly at [phone number].
          <br />
          <br />
          Sincerely,
          <br />
          {formData?.practice?.name ? formData.practice.name : "[Practice Name]"}
        </Typography>
      </DialogContent>
    </>
  );
};

export default FillerEmailPreview;
