import { dataIngestionClient } from "../dataIngestionClient";

export async function fetchUploadsList({ uuid, limit, offset, signal }) {
  try {
    const uploads = await dataIngestionClient.get(
      `api/upload-session/list?limit=${limit}&offset=${offset}&practiceId=${uuid}`,
      { signal },
    );
    return uploads.data;
  } catch (error) {
    const errorData = new Error("An error occurred while fetching uploads list");
    errorData.code = error.response.status;
    errorData.info = error.response.data;
    throw errorData;
  }
}

export async function updateSessionUpload({ id, action }) {
  try {
    const response = await dataIngestionClient.post(
      `api/upload-session/${id}/${action}`,
      null,
      { headers: { Accept: "*/*" } },
    );
    return response.data;
  } catch (error) {
    const errorData = new Error("An error occurred while updating Upload Session");
    errorData.code = error.response.status;
    errorData.info = error.response.data;
    throw errorData;
  }
}

export async function uploadSessionFile({ uuid, fileName, file, fileType }) {
  let sessionId = null;
  let uploadUrl = null;

  // 1. Create new Session and get ID
  try {
    const newSession = await dataIngestionClient.post('api/upload-session/new', { practiceId: uuid },{
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    sessionId = newSession.data.id;
  } catch (error) {
    const errorData = new Error("An error occurred while creating upload session");
    errorData.code = error.response.status;
    errorData.info = error.response.data;
    throw errorData;
  }

  // 2. Create new File Name for newly created Session ID
  try {
    const newSessionFile = await dataIngestionClient.post(
      `api/upload-session/${sessionId}/new-file`,
      { fileName: fileName },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    uploadUrl = newSessionFile.data.uploadUrl;
  } catch (error) {
    const errorData = new Error("An error occurred while creating upload session file");
    errorData.code = error.response.status;
    errorData.info = error.response.data;
    throw errorData;
  }

  // 3. Put file into S3 (returned uploadUrl)
  try {
   await dataIngestionClient.put(uploadUrl, file, {
      headers: {
        "Content-Type": fileType,
      },
    });
  } catch (error) {
    const errorData = new Error("An error occurred while uploading file to S3");
    errorData.code = error.response.status;
    errorData.info = error.response.data;
    throw errorData;
  }
}
