import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { INFO_DRAWER_WIDTH, QUERY_KEY_PRACTICE } from "../../store/constants";
import { fetchPractice } from "../../utils/api/practices.api";
import classes from "./Drawers.module.css";
import Loading from "../Loading";
import { ICD10_WITH_DESCRIPTION } from "../../data/icd10";
import CloseIcon from "@mui/icons-material/Close";
import useCustomClaims from "../../utils/hooks/useCustomClaims";

const SkinScreeningDrawer = (props) => {
  const params = useParams();
  const uuid = params.uuid;
  const { isBonsaiAdmin } = useCustomClaims();

  /** Fetch Practice **/
  const { data: formData, isPending } = useQuery({
    queryKey: [QUERY_KEY_PRACTICE],
    queryFn: async ({ signal }) => {
      const [practice] = await Promise.all([fetchPractice({ uuid, signal })]);
      return { practice };
    },
  });

  /** Email Preview */
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };

  if (isPending) {
    return <Loading />;
  }

  return (
    <Box sx={{ width: { xs: "100%", sm: INFO_DRAWER_WIDTH } }}>
      <Box
        sx={{ backgroundColor: "primary.main", display: "flex", alignItems: "center", justifyContent: "space-between" }}
      >
        <Typography component="p" noWrap sx={{ color: "white", p: 2, fontWeight: 500 }}>
          Skin Screening Overdue
        </Typography>
        <IconButton onClick={props.onClick} sx={{ mr: 1 }}>
          <CloseIcon sx={{ color: "primary.contrastText" }} />
        </IconButton>
      </Box>
      <Box p={2} pb={5}>
        <Typography component="p" className={classes.label}>
          What is this?
        </Typography>
        <Typography component="p" className={classes.info}>
          Skin screening overdue patients are those who are due for their annual screening appointment and currently do
          not have a scheduled appointment. If a patient has previously had a skin screening appointment or has been
          diagnosed with any of the following ICD10 codes, they are eligible for a skin screening recall
        </Typography>
        <TableContainer component={Paper} sx={{ mb: 3 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 600 }}>ICD10</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ICD10_WITH_DESCRIPTION.map((icd) => (
                <TableRow key={icd.code}>
                  <TableCell>{icd.code}</TableCell>
                  <TableCell>{icd.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {formData?.practice.systemOfRecord === "modmed" && isBonsaiAdmin && (
          <>
            <Typography component="p" className={classes.label}>
              How is it calculated?
            </Typography>
            <ul className={classes.ulDisc}>
              <li className={classes.li}>
                Find patients who haven't had a recent treatment:
                <ul className={classes.ulCircle}>
                  <li className={classes.li}>Use the "Production (Custom)" report.</li>
                  <li className={classes.li}>
                    Look for patients who were previously diagnosed with Skin screening ICD codes (ICD-10)
                  </li>
                  <li className={classes.li}>Exclude patients who were diagnosed within the last 12 months</li>
                </ul>
              </li>
              <li className={classes.li}>
                Find patients who haven't had a recent appointment:
                <ul className={classes.ulCircle}>
                  <li className={classes.li}>Use the "Appointment (Custom)" report.</li>
                  <li className={classes.li}>Look for patients who had a skin screening appointment.</li>
                  <li className={classes.li}>Exclude patients who had an appointment within the last 12 months.</li>
                  <li className={classes.li}>Also exclude appointments marked as "No Show" or "Canceled."</li>
                </ul>
              </li>
              <li className={classes.li}>
                Combine patients from both searches:
                <ul className={classes.ulCircle}>
                  <li className={classes.li}>Gather the unique patients identified in steps 1 and 2.</li>
                </ul>
              </li>
              <li className={classes.li}>
                Exclude patients with upcoming appointments:
                <ul className={classes.ulCircle}>
                  <li className={classes.li}>Use the "Appointment (Custom)" report again.</li>
                  <li className={classes.li}>Remove patients with upcoming skin screening appointments.</li>
                  <li className={classes.li}>Exclude "No Show" and "Canceled" appointments as well.</li>
                </ul>
              </li>
              <li className={classes.li}>
                Exclude inactive patients:
                <ul className={classes.ulCircle}>
                  <li className={classes.li}>Use the "Demographics (Custom)" report.</li>
                  <li className={classes.li}>Remove patients marked as inactive based on their account status.</li>
                </ul>
              </li>
              <li className={classes.li}>
                Final list:
                <ul className={classes.ulCircle}>
                  <li className={classes.li}>
                    The remaining patients from step 3, after removing those with upcoming appointments and inactive
                    accounts,are the ones eligible for skin screening reminders.
                  </li>
                </ul>
              </li>
            </ul>
          </>
        )}
        <Typography component="p" className={classes.label}>
          What happens when a reminder is enabled?
        </Typography>
        <Typography component="p" className={classes.info}>
          Bonsai finds patients due for skin screening treatments and sends them{" "}
          <Link underline="always" onClick={handleToggle}>
            reminder emails
          </Link>{" "}
          over 90 days.
        </Typography>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Card variant="outlined" sx={{ mb: 3 }}>
            <CardHeader
              title="Action required: Schedule your skin screening appointment"
              sx={{ backgroundColor: "primary.gray200" }}
            />
            <CardContent>
              <Typography variant="body2">
                Dear [Patient first Name],
                <br />
                <br />
                I've noticed you're overdue for your next skin screening appointment.
                <br />
                <br />
                Did you know?
                <br />
              </Typography>
              <Typography variant="body2" component="ul" sx={{ listStyleType: "disc", ml: 4, my: 2 }}>
                <li>1 in 5 Americans will develop skin cancer by age 70.</li>
                <li>Early detection boasts a 99% 5-year survival rate for skin cancer.</li>
                <li>Without early detection, that rate drops to 30%.</li>
              </Typography>
              <Typography variant="body2">
                <br />
                Early detection is truly vital. Please schedule your appointment or call us directly at [phone number].
                <br />
                <br />
                <Button
                  href="https://form.jotform.com/241775643117156"
                  target="_blank"
                  variant="contained"
                  disableElevation
                >
                  Book Now
                </Button>
                <br />
                <br />
                Looking forward to seeing you soon.
                <br />
                <br />
                Sincerely,
                <br />
                {formData?.practice?.name ? formData.practice.name : "[Practice Name]"}
              </Typography>
            </CardContent>
          </Card>
        </Collapse>
        <Typography component="p" className={classes.label}>
          Campaign Schedule:
        </Typography>
        <TableContainer component={Paper} sx={{ mb: 3 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 600 }}>Reminder</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Day</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Channel</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Local Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>1</TableCell>
                <TableCell>1</TableCell>
                <TableCell>Text</TableCell>
                <TableCell>9:00 AM</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>2</TableCell>
                <TableCell>2</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>12:00 PM</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>3</TableCell>
                <TableCell>5</TableCell>
                <TableCell>Text</TableCell>
                <TableCell>12:00 PM</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>4</TableCell>
                <TableCell>30</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>3:00 PM</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>5</TableCell>
                <TableCell>60</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>9:00 AM</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Typography component="p" className={classes.label}>
          How patients exit the Campaign?
        </Typography>
        <ul className={classes.ulDisc}>
          <li className={classes.li}>Booking an appointment through the online form.</li>
          <li className={classes.li}>Calling the practice using the phone number provided.</li>
          <li className={classes.li}>Having a new appointment created in the system by the practice.</li>
          <li className={classes.li}>Unsubscribing from the campaign emails.</li>
          <li className={classes.li}>The practice manually opting them out.</li>
        </ul>
      </Box>
    </Box>
  );
};

export default SkinScreeningDrawer;
