import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Header from "../components/Header";
import { useDispatch } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createNewRetailCategory,
  createNewRetailType,
  fetchRetailData,
  queryClient,
  updateDataType,
  updateRetailType,
} from "../utils/http";
import Loading from "../components/Loading";
import StyledTableCell from "../components/StyledTableCell";
import ErrorBlock from "../components/UI/ErrorBlock";
import { snakeCase } from "lodash";
import { uiActions } from "../store/ui-slice";
import DataTypesNav from "../components/DataTypesNav";
import { TYPE_RETAIL } from "../store/constants";

const DataTypeRetail = () => {
  const dispatch = useDispatch();

  const [newRetailType, setNewRetailType] = useState("");
  const [newRetailCategory, setNewRetailCategory] = useState("");

  const [types, setTypes] = useState([]);
  const [categories, setCategories] = useState([]);

  const [newRetailTypeOpen, setNewRetailTypeOpen] = useState(false);
  const [newRetailCategoryOpen, setNewRetailCategoryOpen] = useState(false);

  const [editTypeOpen, setEditTypeOpen] = useState(false);
  const [editTypeName, setEditTypeName] = useState("");
  const [editTypeId, setEditTypeId] = useState("");
  const [editTypeCategoryId, setEditTypeCategoryId] = useState("");

  const [loaded, setLoaded] = useState(false);

  const handleNewRetailClose = () => {
    setNewRetailTypeOpen(false);
    setNewRetailType("");
    setNewRetailCategory("");
  };

  const handleNewRetailTypeChange = (event) => {
    setNewRetailType(event.target.value);
  };

  const handleNewRetailCategoryChange = (event) => {
    setNewRetailCategory(event.target.value);
  };

  const handleNewRetailTypeOpen = () => {
    setNewRetailTypeOpen(true);
  };

  const handleNewRetailCategoryOpen = () => {
    setNewRetailCategoryOpen(true);
  };

  const handleNewRetailCategoryClose = () => {
    setNewRetailCategoryOpen(false);
    setNewRetailCategory("");
  };

  const {
    data: retailData,
    isPending: isPendingRetail,
    isError: isErrorRetail,
    error: errorRetail,
  } = useQuery({
    queryKey: [TYPE_RETAIL],
    queryFn: ({ signal, queryKey }) => fetchRetailData({ signal, ...queryKey }),
  });

  const {
    mutate: mutateNewRetailType,
    isPending: isPendingNewRetailType,
    isError: isErrorNewRetailType,
    error: errorNewRetailType,
  } = useMutation({
    mutationFn: createNewRetailType,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [TYPE_RETAIL] });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "New Retail Type created.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  const { mutate: mutateUpdateCategory } = useMutation({
    mutationFn: updateRetailType,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [TYPE_RETAIL] });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Retail Category updated.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: [TYPE_RETAIL] });
      dispatch(
        uiActions.showNotification({
          status: "error",
          message: "Error updating retail category. Please try again later.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  const {
    mutate: mutateNewRetailCategory,
    isPending: isPendingNewRetailCategory,
    isError: isErrorNewRetailCategory,
    error: errorNewRetailCategory,
  } = useMutation({
    mutationFn: createNewRetailCategory,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [TYPE_RETAIL] });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "New Retail Category created.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  useEffect(() => {
    if (retailData) {
      setTypes(retailData.types.items);
      setCategories(retailData.categories.items);
      setLoaded(true);
    }
  }, [retailData]);

  const handleCategoryChange = (selectedOption, id, display) => {
    let updatedType = {};
    updatedType.display = display;
    updatedType.categoryId = selectedOption.target.value;
    mutateUpdateCategory({ id: id, data: updatedType });
    selectedOption.defaultValue = selectedOption.target.value;
  };

  const handleNewRetailTypeAdd = (event) => {
    event.preventDefault();
    let newType = {};
    newType.name = snakeCase(newRetailType);
    newType.display = newRetailType;
    newType.categoryId = newRetailCategory;
    mutateNewRetailType(newType);
    handleNewRetailClose();
  };

  const handleNewRetailCategoryAdd = (event) => {
    event.preventDefault();

    let newCat = {};
    newCat.name = snakeCase(newRetailCategory);
    newCat.display = newRetailCategory;
    mutateNewRetailCategory(newCat);
    handleNewRetailCategoryClose();
  };

  const { mutate: mutateDataType, isPending: isPendingUpdateDataType } = useMutation({
    mutationFn: updateDataType,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [TYPE_RETAIL] });
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "Data Type updated.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: [TYPE_RETAIL] });
      dispatch(
        uiActions.showNotification({
          status: "error",
          message: "Error updating Data Type. Please try again later.",
        }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  const handleEditTypeChange = (event) => {
    setEditTypeName(event.target.value);
  };

  const handleEditTypeChanged = (event) => {
    event.preventDefault();
    let dataType = {};
    dataType.name = snakeCase(editTypeName);
    dataType.display = editTypeName;
    dataType.categoryId = editTypeCategoryId;
    mutateDataType({ id: editTypeId, type: "retails/types", data: dataType });
    handleEditTypeClose();
  };

  const handleEditType = (id, display, categoryId) => {
    setEditTypeOpen(true);
    setEditTypeId(id);
    setEditTypeName(display);
    setEditTypeCategoryId(categoryId);
  };

  const handleEditTypeClose = () => {
    setEditTypeOpen(false);
    setEditTypeId("");
    setEditTypeName("");
    setEditTypeCategoryId("");
  };

  return (
    <>
      <Header
        title="Data Types"
        action={
          <Box>
            <Button
              sx={{ mr: 1 }}
              variant="outlined"
              disableElevation
              startIcon={<AddIcon />}
              onClick={handleNewRetailTypeOpen}
            >
              Add New Retail Type
            </Button>
            <Button
              variant="outlined"
              disableElevation
              startIcon={<AddIcon />}
              sx={{ mr: 1, my: { xs: 1, lg: 0 } }}
              onClick={handleNewRetailCategoryOpen}
            >
              Add New Retail Category
            </Button>
          </Box>
        }
      />
      <Box display="flex" sx={{ flexDirection: { xs: "column", md: "row" } }}>
        <DataTypesNav />
        <Box flex={1} sx={{ overflowX: "auto" }}>
          {isErrorRetail && (
            <ErrorBlock
              title="An error occured."
              message={errorRetail.info?.message || "Failed to fetch retail data. Please try again later."}
            />
          )}
          {isErrorNewRetailCategory && (
            <ErrorBlock
              title="An error occured."
              message={
                errorNewRetailCategory.info?.message || "Failed to create new Retail Category. Please try again later."
              }
            />
          )}
          {isErrorNewRetailType && (
            <ErrorBlock
              title="An error occured."
              message={errorNewRetailType.info?.message || "Failed to create new Retail Type. Please try again later."}
            />
          )}
          <Dialog
            open={newRetailTypeOpen}
            onClose={handleNewRetailClose}
            PaperProps={{
              component: "form",
              onSubmit: handleNewRetailTypeAdd,
            }}
            fullWidth
            maxWidth={"xs"}
          >
            <DialogTitle>
              <Typography variant="h4" component="p">
                Add Retail Type
              </Typography>
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                required
                margin="dense"
                label="Retail Type Name"
                type="text"
                fullWidth
                variant="outlined"
                value={newRetailType}
                onChange={handleNewRetailTypeChange}
              />
              <FormControl sx={{ mt: 3 }} fullWidth>
                <InputLabel id="new-retail-category">Retail Category</InputLabel>
                <Select
                  labelId="new-retail-category"
                  onChange={handleNewRetailCategoryChange}
                  label="Retail Category"
                  value={newRetailCategory}
                  required
                >
                  {categories.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.display}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 3 }}>
              <Button onClick={handleNewRetailClose}>Cancel</Button>
              <Button type="submit" variant="contained" disableElevation disabled={isPendingNewRetailType}>
                {isPendingNewRetailType ? "Saving..." : "Save"}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={newRetailCategoryOpen}
            onClose={handleNewRetailCategoryClose}
            PaperProps={{
              component: "form",
              onSubmit: handleNewRetailCategoryAdd,
            }}
            fullWidth
            maxWidth={"xs"}
          >
            <DialogTitle>
              <Typography variant="h4" component="p">
                Add new Retail Category
              </Typography>
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                required
                margin="dense"
                label="Category Name"
                type="text"
                fullWidth
                variant="outlined"
                value={newRetailCategory}
                onChange={handleNewRetailCategoryChange}
              />
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 3 }}>
              <Button onClick={handleNewRetailCategoryClose}>Cancel</Button>
              <Button type="submit" variant="contained" disableElevation disabled={isPendingNewRetailCategory}>
                {isPendingNewRetailCategory ? "Saving..." : "Save"}
              </Button>
            </DialogActions>
          </Dialog>
          {isPendingRetail ? (
            <Loading />
          ) : (
            <Paper
              sx={{
                width: "100%",
                border: "1px solid #f0f0f0",
                borderRadius: 1.5,
                boxShadow: "none",
              }}
            >
              <TableContainer>
                <Table size="small" sx={{ borderRadius: "6px 6px 0 0", overflow: "hidden" }}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Retail Type</StyledTableCell>
                      <StyledTableCell>Retail Category</StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loaded && types.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={3}>No Current Retail Types</TableCell>
                      </TableRow>
                    )}
                    {loaded && types.length > 0 && (
                      <>
                        {types.map((type) => (
                          <TableRow key={type.id} hover>
                            <TableCell>{type.display}</TableCell>
                            <TableCell>
                              <FormControl sx={{ width: "100%" }}>
                                <Select
                                  onChange={(selectedOption) =>
                                    handleCategoryChange(selectedOption, type.id, type.display)
                                  }
                                  size="small"
                                  defaultValue={type.categoryId}
                                  value={type.categoryId}
                                  sx={{ backgroundColor: "#ffffff" }}
                                >
                                  {categories.map((category) => (
                                    <MenuItem key={category.id} value={category.id}>
                                      {category.display}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <IconButton
                                size="small"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  handleEditType(type.id, type.display, type.categoryId);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                        <Dialog
                          open={editTypeOpen}
                          onClose={handleEditTypeClose}
                          PaperProps={{
                            component: "form",
                            onSubmit: handleEditTypeChanged,
                          }}
                          fullWidth
                          maxWidth={"xs"}
                        >
                          <DialogTitle>
                            <Typography variant="h4" component="p">
                              Edit Retail Type
                            </Typography>
                          </DialogTitle>
                          <DialogContent>
                            <TextField
                              autoFocus
                              required
                              margin="dense"
                              label="Retail Type Name"
                              type="text"
                              fullWidth
                              variant="outlined"
                              value={editTypeName}
                              onChange={handleEditTypeChange}
                            />
                          </DialogContent>
                          <DialogActions sx={{ px: 3, pb: 3 }}>
                            <Button onClick={handleEditTypeClose}>Cancel</Button>
                            <Button
                              type="submit"
                              variant="contained"
                              disableElevation
                              disabled={isPendingUpdateDataType}
                            >
                              {isPendingUpdateDataType ? "Saving..." : "Save"}
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
        </Box>
      </Box>
    </>
  );
};

export default DataTypeRetail;
