import { useEffect, useState } from "react";

export default function useTimezones() {
  const [timezoneOptions, setTimezoneOptions] = useState([]);
  useEffect(() => {
    if (timezoneOptions.length) {
      return;
    }
    const locale = new Intl.Locale('en-us');
    if (!locale.timeZones) {
      return;
    }
    setTimezoneOptions(locale.timeZones);
  }, [timezoneOptions, setTimezoneOptions]);
  return { timezoneOptions };
}