import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../store/ui-slice";

const Notification = (props) => {
  const dispatch = useDispatch();
  const snackbarShow = useSelector((state) => state.ui.showSnack);
  const handleClose = () => {
    dispatch(uiActions.showSnackBar(false));
  };

  return (
    <Snackbar
      open={snackbarShow}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={props.status} variant="filled">
        {props.message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
