import { Button, ButtonGroup } from "@mui/material";
import React from "react";
import classes from "../../styles/Filters.module.css";

const TypeToggle = ({ change }) => {
  const types = ["Charts", "Table"];
  const [selectedType, setSelectedType] = React.useState("Charts");
  const handleTypeClick = (event) => {
    setSelectedType(event.target.value);
    change(event.target.value);
  };
  return (
    <>
      <ButtonGroup
        disableElevation
        variant="contained"
        aria-label="Report Frequency"
        sx={{ border: "1px solid #D0D5DD", ml: { xs: 0, sm: 2 }, my: { xs: 1, sm: 0 } }}
      >
        {types.map((type, i) => (
          <Button
            key={i}
            className={selectedType === type ? classes.buttonActive : classes.button}
            onClick={handleTypeClick}
            value={type}
          >
            {type}
          </Button>
        ))}
      </ButtonGroup>
    </>
  );
};

export default TypeToggle;
