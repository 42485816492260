import React from "react";
import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import ErrorBlock from "../components/UI/ErrorBlock";
import { QUERY_KEY_PRACTICE_RECALL_TYPES } from "../store/constants";
import Loading from "../components/Loading";
import { fetchPracticeRecallTypes } from "../utils/api/practice-recall-types";
import { useParams } from "react-router-dom";
import PracticeRecallDiagnosisCodesTable from "../components/PracticeRecallDiagnosisCodesTable";

const PracticeDiagnosisCodes = () => {
  const params = useParams();

  const { data, isPending, isError, error } = useQuery({
    queryKey: [QUERY_KEY_PRACTICE_RECALL_TYPES, params.uuid],
    queryFn: ({ signal }) =>
      fetchPracticeRecallTypes({
        signal,
        practiceUuid: params.uuid,
      }),
  });

  return (
    <>
      <Box display="flex">
        <Box flex={1} sx={{ overflowX: "auto" }}>
          {isError && (
            <ErrorBlock
              title="An error occured."
              message={error.info?.message || "Failed to fetch Practice Diagnosis Types. Please try again later."}
            />
          )}
          {isPending ? (
            <Loading />
          ) : (
            <PracticeRecallDiagnosisCodesTable
              recallTypes={data.items}
              practiceUuid={params.uuid}
            ></PracticeRecallDiagnosisCodesTable>
          )}
        </Box>
      </Box>
    </>
  );
};

export default PracticeDiagnosisCodes;
