import React from "react";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { AppBar, Box, Button, Toolbar } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 5, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "30ch",
    },
  },
}));

const SearchBox = ({ value, onChange, handleClearFilter }) => {
  return (
    <Box sx={{ flexGrow: 1, mb: 1.75 }}>
      <AppBar position="static" sx={{ borderRadius: 1.5, boxShadow: "none", backgroundColor: "#3D3652" }}>
        <Toolbar disableGutters sx={{ px: 1 }}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              value={value}
              onChange={onChange}
            />
            {value && handleClearFilter && (
              <Button
                onClick={handleClearFilter}
                disableElevation
                disableRipple
                sx={{ position: "absolute", right: 0, minWidth: "24px", top: 2 }}
              >
                <ClearIcon sx={{ color: "#fff" }} />
              </Button>
            )}
          </Search>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default SearchBox;
