import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Divider, MenuItem, Select, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchPractice, updatePractice } from "../utils/api/practices.api";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  QUERY_KEY_FEATURE_TOGGLE_OPTIONS,
  QUERY_KEY_PRACTICE,
  QUERY_KEY_PRACTICE_FEATURE_TOGGLES,
  QUERY_KEY_PRACTICE_LOCATIONS,
  QUERY_KEY_PRACTICE_TIMEZONE,
} from "../store/constants";
import { fetchPracticeLocations } from "../utils/api/practice-locations";
import Loading from "../components/Loading";
import StatusBadge from "../components/StatusBadge/StatusBadge";
import { attributePatients, enrollPatients, exitPatients, cleanupExits } from "../utils/api/recall-collector.api";
import { queryClient } from "../utils/http";
import { uiActions } from "../store/ui-slice";
import useTimezones from "../utils/hooks/useTimezones";
import useCustomClaims from "../utils/hooks/useCustomClaims";
import { fetchFeatureToggleOptions, fetchFeatureToggles, updateFeatureToggle } from "../utils/api/feature-toggles.api";
import BonsaiSwitch from "../components/BonsaiSwitch/BonsaiSwitch";
import { sendPracticePerformanceEmails } from "../utils/api/practice-performance.api";

const PracticeDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const uuid = params.uuid;
  const queryKey = [QUERY_KEY_PRACTICE, QUERY_KEY_PRACTICE_LOCATIONS, QUERY_KEY_PRACTICE_TIMEZONE, uuid];
  const { isBonsaiAdmin } = useCustomClaims();

  const { data, isPending } = useQuery({
    queryKey: queryKey,
    queryFn: async ({ signal }) => {
      const [practice, locations] = await Promise.all([
        fetchPractice({ uuid, signal }),
        fetchPracticeLocations({ uuid, signal }),
      ]);
      return { practice, locations };
    },
  });

  const [primaryLocation, setPrimaryLocation] = useState("");
  useEffect(() => {
    if (data && data.practice) {
      setPrimaryLocation(data.practice.primaryLocationUuid);
    }
  }, [setPrimaryLocation, data]);

  const { mutate } = useMutation({
    mutationFn: updatePractice,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey });
      dispatch(
        uiActions.showNotification({ status: "success", message: "Primary Location updated." }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: queryKey });
      dispatch(
        uiActions.showNotification({ status: "error", message: "Primary Location failed, please try again later." }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  const primaryLocationHandler = (event) => {
    let practiceData = {
      primaryLocationUuid: event.target.value,
    };
    mutate({ uuid: params.uuid, practiceData });
  };

  const dispatchJobQueued = (jobType) => {
    dispatch(
      uiActions.showNotification({ status: "success", message: `${jobType} job queued` }),
      dispatch(uiActions.showSnackBar(true))
    );
  };

  /** Enrollment **/
  const [isEnrollLoading, setIsEnrollLoading] = useState(false);
  const handleEnrollClick = async () => {
    if (isEnrollLoading) {
      return;
    }
    setIsEnrollLoading(true);
    await enrollPatients({ practiceUuid: uuid });
    dispatchJobQueued("Enrollment");
    setIsEnrollLoading(false);
  };

  /** Attribution **/
  const [isAttributionLoading, setIsAttributionLoading] = useState(false);
  const handleAttributeClick = async () => {
    if (isAttributionLoading) {
      return;
    }
    setIsAttributionLoading(true);
    await attributePatients({ practiceUuid: uuid });
    dispatchJobQueued("Attribution");
    setIsAttributionLoading(false);
  };

  /** Exiting **/
  const [isExitLoading, setIsExitLoading] = useState(false);
  const handleExitClick = async () => {
    if (isExitLoading) {
      return;
    }
    setIsExitLoading(true);
    await exitPatients({ practiceUuid: uuid });
    dispatchJobQueued("Exit");
    setIsExitLoading(false);
  };

  /** Cleanup Exits **/
  const [isCleanupLoading, setIsCleanupLoading] = useState(false);
  const handleCleanupClick = async () => {
    if (isCleanupLoading) {
      return;
    }
    setIsCleanupLoading(true);
    await cleanupExits({ practiceUuid: uuid });
    dispatchJobQueued("Cleanup");
    setIsCleanupLoading(false);
  };

  /** Send Practice Performance Emails **/
  const [isPerformanceEmailLoading, setIsPerformanceEmailLoading] = useState(false);
  const handleSendEmailClick = async () => {
    if (isPerformanceEmailLoading) {
      return;
    }
    setIsPerformanceEmailLoading(true);
    await sendPracticePerformanceEmails({ practiceUuid: uuid });
    dispatchJobQueued("Practice Performance Email");
    setIsPerformanceEmailLoading(false);
  };

  /** Timezone **/
  const [timezone, setTimezone] = useState("");
  useEffect(() => {
    if (data && data.practice) {
      setTimezone(data.practice.timezone);
    }
  }, [setTimezone, data]);

  const { mutate: updateTimezone } = useMutation({
    mutationFn: updatePractice,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey });
      dispatch(
        uiActions.showNotification({ status: "success", message: "Practice timezone updated." }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: queryKey });
      dispatch(
        uiActions.showNotification({ status: "error", message: "Timezone update failed, please try again later." }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });
  const handleTimezoneUpdate = ($event) => {
    const timezone = $event.target.value;
    const practiceData = { timezone };
    updateTimezone({ uuid: params.uuid, practiceData });
  };

  const { timezoneOptions } = useTimezones();

  /** Feature Toggle **/
  const { data: featureToggleOptions, isPending: isFeatureToggleOptionsLeading } = useQuery({
    queryKey: [QUERY_KEY_FEATURE_TOGGLE_OPTIONS],
    queryFn: async ({ signal }) => {
      const { items } = await fetchFeatureToggleOptions({ signal });
      return items;
    },
  });

  const { data: practiceFeatureToggles, isPending: isFeatureToggleLoading } = useQuery({
    queryKey: [QUERY_KEY_PRACTICE, QUERY_KEY_PRACTICE_FEATURE_TOGGLES, uuid],
    queryFn: async ({ signal }) => {
      const { items } = await fetchFeatureToggles({ signal, practiceUuid: uuid });
      return items.reduce(
        (practiceToggleMap, practiceToggle) => ({
          ...practiceToggleMap,
          [practiceToggle.name]: practiceToggle.value,
        }),
        {}
      );
    },
  });

  const { mutate: updateFeatureToggleStatus } = useMutation({
    mutationFn: ({ name, value }) =>
      updateFeatureToggle({
        practiceUuid: uuid,
        name,
        value,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_PRACTICE, QUERY_KEY_PRACTICE_FEATURE_TOGGLES, uuid] });
      dispatch(
        uiActions.showNotification({ status: "success", message: "Feature enabled." }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_PRACTICE, QUERY_KEY_PRACTICE_FEATURE_TOGGLES, uuid] });
      dispatch(
        uiActions.showNotification({ status: "error", message: "Feature failed to enable, please try again later." }),
        dispatch(uiActions.showSnackBar(true))
      );
    },
  });

  if (isPending || isFeatureToggleLoading || isFeatureToggleOptionsLeading) {
    return <Loading />;
  }
  return (
    <Box>
      <Typography variant="h1" sx={{ mb: 3 }}>
        {data.practice.name}
      </Typography>
      {isBonsaiAdmin && (
        <>
          <Box sx={{ display: "flex", mb: 2, gap: { xs: 1, md: 2 } }}>
            <Button disabled={isEnrollLoading} disableElevation variant="contained" onClick={handleEnrollClick}>
              Enroll
            </Button>
            <Button disabled={isAttributionLoading} disableElevation variant="contained" onClick={handleAttributeClick}>
              Attribute
            </Button>
            <Button disabled={isExitLoading} disableElevation variant="contained" onClick={handleExitClick}>
              Exit
            </Button>
            <Button disabled={isCleanupLoading} disableElevation variant="contained" onClick={handleCleanupClick}>
              Clean Exits
            </Button>
          </Box>
          {
            practiceFeatureToggles['performance_emails'] && (
              <Box sx={{ display: "flex", mb: 2, gap: { xs: 1, md: 2 } }}>
                <Button disabled={isPerformanceEmailLoading} disableElevation variant="contained" onClick={handleSendEmailClick}>
                  Send Performance Email
                </Button>
              </Box>
            )
          }
        </>
      )}
      <Card variant="outlined" sx={{ minWidth: 275, maxWidth: 450, borderRadius: "12px" }}>
        <CardContent sx={{ position: "relative" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="body2" color="primary.gray500">
                Business Line(s)
              </Typography>
              <Box>
                {data.practice.businessLines.map((line, i) => (
                  <Typography component="span" variant="bodySemi2" key={i} sx={{ textTransform: "capitalize" }}>
                    {line}
                    {i < data.practice.businessLines.length - 1 && `, `}
                  </Typography>
                ))}
              </Box>
            </Box>
            <StatusBadge status={data.practice.status} />
          </Box>
          <Typography variant="body2" color="primary.gray500">
            System of Record
          </Typography>
          <Typography component="span" variant="bodySemi2">
            {data.practice.systemOfRecord}
          </Typography>
          <Divider sx={{ my: 3 }} />
          <Box display="flex" sx={{ alignItems: "center" }}>
            <Typography variant="body2" color="primary.gray500" sx={{ mr: 2 }}>
              Primary Location
            </Typography>
            <Select size="small" value={primaryLocation || ""} onChange={primaryLocationHandler} sx={{ flexGrow: 1 }}>
              {data.locations?.map((location, i) => (
                <MenuItem value={location.uuid} key={i}>
                  {location.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {isBonsaiAdmin && (
            <>
              <Box my={2} display="flex" sx={{ alignItems: "center" }}>
                <Typography variant="body2" color="primary.gray500" sx={{ mr: 2 }}>
                  Timezone
                </Typography>
                {!!timezoneOptions.length ? (
                  <Select size="small" value={timezone || ""} onChange={handleTimezoneUpdate} sx={{ flexGrow: 1 }}>
                    {timezoneOptions.map((tz, i) => (
                      <MenuItem value={tz} key={i}>
                        {tz}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  timezone
                )}
              </Box>
              <Divider sx={{ my: 3 }} />
              {featureToggleOptions &&
                featureToggleOptions.map((featureToggle, i) => (
                  <Box my={2} display="flex" sx={{ alignItems: "center", justifyContent: "space-between" }} key={i}>
                    <Typography variant="body2" color="primary.gray500" sx={{ mr: 2 }}>
                      {featureToggle.display}
                    </Typography>
                    <BonsaiSwitch
                      color="success"
                      checked={practiceFeatureToggles[featureToggle.name]}
                      disabled={featureToggle.canBeToggled ? false:practiceFeatureToggles[featureToggle.name]}
                      onChange={($event) =>
                        updateFeatureToggleStatus({ name: featureToggle.name, value: $event.target.checked })
                      }
                    />
                  </Box>
                ))}
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default PracticeDetails;
