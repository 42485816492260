import React, { useEffect, useRef } from "react";

export default function useDebouncedFilter(initialState, debounceTime) {
  const [filter, setFilter] = React.useState(initialState);
  const [debouncedFilter, setDebouncedFilter] = React.useState(initialState)

  const debounceTimeout = useRef(null);
  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current)
    }
    debounceTimeout.current = setTimeout(() => {
      setDebouncedFilter(filter)
    }, debounceTime)

    return () => {
      clearTimeout(debounceTimeout.current)
    }
  }, [filter, debounceTime])

  return { filter, setFilter, debouncedFilter }
}