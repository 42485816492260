import React, { useEffect, useState } from "react";
import { Table, TableBody, TableContainer } from "@mui/material";
import classes from "../../styles/MetricsTable.module.css";

import NoDataTable from "./NoDataTable";
import { tableDataPrep } from "../../utils/insights/tableDataPrep";
import InsightsTableRow from "./InsightsTableRow";
import { percentageFormatter } from "../../data/valueFormatter";
import InsightsTableHeader from "../InsightsTableHeader";
import InsightsTableColumnHeaders from "./InsightsTableColumnHeaders";

const KeyDriversTable = ({ label, type, metricData }) => {
  const [trendingLabels, setTrendingLabels] = useState([]);
  const [yoyLabels, setYoyLabels] = useState([]);
  const [yoyData, setYoyData] = useState([]);
  const [ytdLabels, setYtdLabels] = useState([]);
  const [ytdData, setYtdData] = useState([]);
  const [timeTable, setTimeTable] = useState("");

  const [rebookingData, setRebookingData] = useState([]);
  const [noShowData, setNoShowData] = useState([]);
  const [noShowRescheduleData, setNoShowRescheduleData] = useState([]);
  const [cancellationData, setCancellationData] = useState([]);
  const [cancellationRescheduleData, setCancellationRescheduleData] = useState([]);
  const [cancellationFillRateData, setCancellationFillRateData] = useState([]);
  const [retailPurchasedRateData, setRetailPurchasedRateData] = useState([]);

  useEffect(() => {
    if (metricData?.data?.length) {
      let rebookingTableData = tableDataPrep(metricData, "rebookingRate");
      setRebookingData(rebookingTableData);
      setTrendingLabels(rebookingTableData.trendingLabels);
      setTimeTable(rebookingTableData.timeTable);
      setYoyLabels(rebookingTableData.yoyLabels);
      setYoyData(rebookingTableData.yoyData);
      setYtdData(rebookingTableData.ytdData);
      setYtdLabels(rebookingTableData.ytdLabels);

      setNoShowData(tableDataPrep(metricData, "noShowRate"));
      setNoShowRescheduleData(tableDataPrep(metricData, "noShowRescheduleRate"));
      setCancellationData(tableDataPrep(metricData, "cancellationRate"));
      setCancellationRescheduleData(tableDataPrep(metricData, "cancellationRescheduleRate"));
      setCancellationFillRateData(tableDataPrep(metricData, "cancellationFillRate"));
      setRetailPurchasedRateData(tableDataPrep(metricData, "retailPurchasedRate"));
    }
  }, [metricData]);
  return (
    <>
      {type === "Table" && (
        <>
          {trendingLabels.length === 0 && <NoDataTable label={label} />}
          {trendingLabels.length > 0 && (
            <TableContainer className={classes.insightsTable}>
              <Table size="small">
                <InsightsTableHeader
                  label={label}
                  trendingLabels={trendingLabels}
                  timeTable={timeTable}
                  yoyData={yoyData}
                  ytdData={ytdData}
                />
                <TableBody>
                  <InsightsTableColumnHeaders
                    trendingLabels={trendingLabels}
                    yoyLabels={yoyLabels}
                    ytdLabels={ytdLabels}
                    timeTable={timeTable}
                  />

                  {rebookingData && (
                    <InsightsTableRow
                      data={rebookingData}
                      formatter={percentageFormatter}
                      topRowLabel="Rebookings"
                      label="Rebooking %"
                    />
                  )}

                  {noShowData && (
                    <InsightsTableRow
                      data={noShowData}
                      formatter={percentageFormatter}
                      topRowLabel="Broken Appointments"
                      label="No Show %"
                      negativeIsGood
                    />
                  )}
                  {noShowRescheduleData && (
                    <InsightsTableRow
                      data={noShowRescheduleData}
                      formatter={percentageFormatter}
                      label="No Show Reschedule %"
                    />
                  )}
                  {cancellationData && (
                    <InsightsTableRow
                      data={cancellationData}
                      formatter={percentageFormatter}
                      label="Cancellation %"
                      negativeIsGood
                    />
                  )}
                  {cancellationRescheduleData && (
                    <InsightsTableRow
                      data={cancellationRescheduleData}
                      formatter={percentageFormatter}
                      label="Cancellation Reschedule %"
                    />
                  )}
                  {cancellationFillRateData && (
                    <InsightsTableRow
                      data={cancellationFillRateData}
                      formatter={percentageFormatter}
                      label="Cancelled Slot Fill %"
                    />
                  )}
                  {retailPurchasedRateData && (
                    <InsightsTableRow
                      data={retailPurchasedRateData}
                      formatter={percentageFormatter}
                      topRowLabel="Retail"
                      label="Purchased Retail %"
                    />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
    </>
  );
};

export default KeyDriversTable;
